import { Component, OnInit } from '@angular/core';
import { GeneralService } from '../../Services/generalservice.service';
import { HttpClient, HttpHandler, HttpResponse, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';
@Component({
    selector: 'app-gallery',
    templateUrl: './gallery.component.html',
    styleUrls: ['./gallery.component.scss'],
})
export class GalleryComponent implements OnInit {
    Homeurl: any;
    dataResult: any = [];
    dataResult1: any;
    imgCollection: any = [];
    arr: any = [];
    Getallimages: any;
    Images: any;
    LoginDetails: any;

    constructor(public router: Router, public generalService: GeneralService, public http: HttpClient) {
        this.LoginDetails = JSON.parse(sessionStorage.getItem("LoginDetails"));
        //this.Homeurl = 'https://yklaboratories.com/webservices';
        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.Homeurl = data.Webservice;
        });
    }

    ngOnInit(): void {
        this.GetGallery();
    }

    GetGallery() {
        debugger
        this.arr = [];
        this.arr.push({
            UserID: 1,
            TokenID: '1A7FE1ED-28F2-4E71-9321-D376ADB9D75E',
        });

        var UploadFile = new FormData();
        UploadFile.append('Param', JSON.stringify(this.arr));
        UploadFile.append('Flag', '101');
        const url = "api/YKLabs/YK_NEW_Getting_YK_ImageGallery";
        this.generalService.PostData(url, UploadFile).then((data: any) => {
            console.log("GetImageGallery", data);
            this.Getallimages = data;
            this.Images = this.Getallimages[0].Images;
        },
            (err) => {
                alert("some thing went wrong")
            });
    }


}

import { Component, OnInit } from '@angular/core';
import { GeneralService } from '../../Services/generalservice.service';
import { debounce } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpRequest } from '@angular/common/http';

@Component({
  selector: 'app-rdnews',
  templateUrl: './rdnews.component.html',
  styleUrls: ['./rdnews.component.scss']
})
export class RdnewsComponent implements OnInit {
    RandDNew: any;
    Homeurl: any;
    Newsimage: any;
    blogdetails: any;
    Bolgdetails: any;
    Discription: any;
    Blogimage: any;
    selectedBlog: any;
    BlogName: any;

    constructor(public generalservice: GeneralService, public route: ActivatedRoute,
        public http: HttpClient) {
        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.Homeurl = data.Webservice;

        })
        
    }

    ngOnInit(): void {
        this.route.params.subscribe(params => {
            this.blogdetails = localStorage.getItem("BlogTitle");
            this.Blogimage = localStorage.getItem("Blogimage");
        });
       // this.blogdetails = this.route.snapshot.paramMap.get("blogTitle");
        this.GetBlog1();
    }
    GetBlog1() {
        debugger
        var uploadfile = new FormData();
        uploadfile.append('Param1', this.blogdetails);
        var url = "api/YKLabs/Get_BlogsDeatilsbased_ID";
        this.generalservice.PostData(url, uploadfile).then((data: any) => {
            debugger
            this.Bolgdetails = data;
            this.Discription = this.Bolgdetails[0].Discription
            this.Blogimage = this.Bolgdetails[0].BlogImage
            this.BlogName = this.Bolgdetails[0].BlogTitle

            // Highlight HTML tags
            this.Discription = this.highlightHtmlTags(this.Discription);

            //this.selectedBlog = val;

         


        },
            (err) => {
                console.error('Error fetching data from GetBlog:', err);
            });
    }
    highlightHtmlTags(htmlContent: string): string {
        // Define an array of HTML tags you want to highlight
        const tagsToHighlight = ['h6', 'p'];

        // Loop through each tag and add a class for styling
        tagsToHighlight.forEach(tag => {
            const regex = new RegExp(`<${tag}[^>]*>.*?<\\/${tag}>`, 'g');
            htmlContent = htmlContent.replace(regex, match => `<span class="${tag}-highlight">${match}</span>`);
        });

        return htmlContent;
    }
 


}

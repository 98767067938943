import { Component, OnInit,Input } from '@angular/core';
import { GeneralService } from '../../Services/generalservice.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRouteSnapshot, ActivatedRoute } from '@angular/router';
//import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.scss']
})
export class TestimonialsComponent implements OnInit {
    YoutubeVideo: any;
    YoutubeLink: any;
    @Input() isLoading: boolean = false;
    spinner: any;
    selectedYear: number = 2023; 
    manu: any;
    year: any;
    Sequence: any;
    RESOLVERDATA: any;
    WantedYoutubelink: any;


    constructor(public generalService: GeneralService, private route: ActivatedRoute, private domSanitizer: DomSanitizer) { }

    ngOnInit(): void {
        //this.RESOLVERDATA = this.route.snapshot.data['youtubeData'];
        //console.log("RESOLVERDATA", this.route.snapshot.data['youtubeData']);
        ////this.RESOLVERDATA = data;
        //this.WantedYoutubelink = this.RESOLVERDATA.YoutubeLink;
        //this.YoutubeVideo = this.route.snapshot.data['youtubeData'];
        //for (var i = 0; i < this.YoutubeVideo.length; i++) {
        //    this.YoutubeVideo[i].YoutubeLink = this.YoutubeVideo[i].YoutubeLink;
        //    this.YoutubeVideo[i].YoutubeLink = this.GetYoutubeUrl(this.YoutubeVideo[i].YoutubeLink)
        //}

        debugger
        // this.Getyoutube();
        this.Getyoutube12(2023);
        this.selectedYear = this.selectedYear;
    }

    clearrrr() {
        debugger
        this.manu = 5;
        this.selectedYear = null;
    }
    /* videos code start */
    calender(val) {
        debugger
      //  this.manu = 1;
        this.year = val;
        this.selectedYear = val;
        this.Getyoutube12(this.selectedYear)
    }

    Getyoutube12(val) {
        debugger
        var uploadfile = new FormData();
        uploadfile.append('Param1', val)
        var url = "api/YKLabs/Get_videoson_sequence";
        this.generalService.PostData(url, uploadfile).then((data: any) => {
            console.log("YouTube_INFO", data);
            this.YoutubeVideo = data;
            //  this.YoutubeLink = this.YoutubeVideo[0].YoutubeLink;
            for (var i = 0; i < this.YoutubeVideo.length; i++) {
                this.YoutubeVideo[i].YoutubeLink = this.YoutubeVideo[i].YoutubeLink;
                this.YoutubeVideo[i].YoutubeLink = this.GetYoutubeUrl(this.YoutubeVideo[i].YoutubeLink)

            }

        },
            (err) => {
              //  alert(" data is not getting")
            });

    }

    /* end code */


    GetYoutubeUrl(url) {
        debugger
        return this.domSanitizer.bypassSecurityTrustResourceUrl(url);

    }



}

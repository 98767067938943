import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeOneComponent } from './components/home-one/home-one.component';
import { HomeTwoComponent } from './components/home-two/home-two.component';
import { HomeThreeComponent } from './components/home-three/home-three.component';
import { HomeFourComponent } from './components/home-four/home-four.component';
import { HomeFiveComponent } from './components/home-five/home-five.component';
import { HomeSixComponent } from './components/home-six/home-six.component';
import { HomeSevenComponent } from './components/home-seven/home-seven.component';
import { HomeEightComponent } from './components/home-eight/home-eight.component';
import { HomeNineComponent } from './components/home-nine/home-nine.component';
import { HomeTenComponent } from './components/home-ten/home-ten.component';
import { HomeElevenComponent } from './components/home-eleven/home-eleven.component';
import { HomeTwelveComponent } from './components/home-twelve/home-twelve.component';
import { HomeThirteenComponent } from './components/home-thirteen/home-thirteen.component';
import { BlogDetailsComponent } from './components/blog-details/blog-details.component';
import { LoginComponent } from './login/login.component';
//import { PagelayoutComponent } from './pagelayout/pagelayout.component';
import { HomeComponent } from './pagelayout/home/home.component';
import { AboutUsComponent } from './pagelayout/about-us/about-us.component';
import { PageHeaderComponent } from './pagelayout/page-header/page-header.component';
import { PageFooterComponent } from './pagelayout/page-footer/page-footer.component';
import { ContactUsComponent } from './pagelayout/contact-us/contact-us.component';
import { AwardsComponent } from './pagelayout/awards/awards.component';
import { GalleryComponent } from './pagelayout/gallery/gallery.component';
import { ViewcartComponent } from './pagelayout/viewcart/viewcart.component';
import { ProductDetailsComponent } from './pagelayout/product-details/product-details.component';
import { TestimonialsComponent } from './pagelayout/testimonials/testimonials.component';
import { CropguidanceComponent } from './pagelayout/cropguidance/cropguidance.component';
import { RegistrattionComponent } from './pagelayout/registrattion/registrattion.component';
import { WebpspportcheckComponent } from './pagelayout/webpspportcheck/webpspportcheck.component';
import { RdchilliComponent } from './pagelayout/rdchilli/rdchilli.component';
import { RdnewsComponent } from './pagelayout/rdnews/rdnews.component';
import { RdpapayaComponent } from './pagelayout/rdpapaya/rdpapaya.component';
import { RdnewsnewComponent } from './pagelayout/rdnewsnew/rdnewsnew.component';
import { VidelResolverResolver } from './Services/videl-resolver.resolver';
import { OnlineenquiryComponent } from './pagelayout/onlineenquiry/onlineenquiry.component';
import { VyorganicgranulesComponent } from './components/vyorganicgranules/vyorganicgranules.component';


const routes: Routes = [
   
   
    { path: '', component: HomeTwoComponent },
    { path: 'login', component: LoginComponent },
    { path: 'Home', component: HomeComponent },
    { path: 'about-us', component: AboutUsComponent },
    { path: 'PageHeader', component: PageHeaderComponent },
    { path: 'PageFooter', component: PageFooterComponent },
    { path: 'testimonials', component: TestimonialsComponent, resolve: { youtubeData: VidelResolverResolver } },
    { path: 'Products/:CategoryName/:productname', component: ProductDetailsComponent },
    { path: 'Gallery', component: GalleryComponent }, 
    { path: 'awards', component: AwardsComponent }, 
    { path: 'viewcart', component: ViewcartComponent },
    { path: 'cropguidance/:CropName/:CropguidelinesId', component: CropguidanceComponent },
    { path: 'Registration', component: RegistrattionComponent }, 
    { path: 'webpspportcheck', component: WebpspportcheckComponent }, 
    { path: 'ContactUs', component: ContactUsComponent },
    {path: 'home-One', component: HomeOneComponent},
    {path: 'home-three', component: HomeThreeComponent},
    {path: 'home-four', component: HomeFourComponent},
    {path: 'home-five', component: HomeFiveComponent},
    {path: 'home-six', component: HomeSixComponent},
    {path: 'home-seven', component: HomeSevenComponent},
    {path: 'home-eight', component: HomeEightComponent},
    {path: 'home-nine', component: HomeNineComponent},
    {path: 'home-ten', component: HomeTenComponent},
    {path: 'home-eleven', component: HomeElevenComponent},
    {path: 'home-twelve', component: HomeTwelveComponent},
    {path: 'home-thirteen', component: HomeThirteenComponent},
    { path: 'blog-details', component: BlogDetailsComponent },
    { path: 'Rdchilli', component: RdchilliComponent },
    { path: 'Blog/:blogTitle ', component: RdnewsComponent  },
    { path: 'Rdpapaya', component: RdpapayaComponent },
    { path: 'RDnews', component: RdnewsnewComponent },
    { path: 'vyorganicgranules', component: VyorganicgranulesComponent },

     {
        path: 'cropguidance', component: CropguidanceComponent, resolve: {
            demo: VidelResolverResolver
        }
    },

    {
        path: 'ykvideos',
        loadChildren: () => import('./pagelayout/cropguidance/cropmodule/cropmodule.module').then(m => m.CropmoduleModule)
    },
    { path: 'mailEnquiry', component: OnlineenquiryComponent },
   
   
    { path: 'Videos', loadChildren: () => import('./Services/videos/videos.module').then(m => m.VideosModule) },

    { path: '**', component: HomeTwoComponent }, // This line will remain down from the whole pages component list

    {
        path: '',
        redirectTo: '',
        pathMatch: 'full'
    }

];

//@NgModule({
//    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
//    exports: [RouterModule],
 
//})
//export class AppRoutingModule { }

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            //preloadingStrategy: PreloadAllModules, // <- comment this line for activate lazy load
            //relativeLinkResolution: 'legacy',
            useHash: false
        })
    ],
    exports: [RouterModule]
}) 
export class AppRoutingModule { }

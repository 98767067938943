import { Component, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { Router, Routes } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
    CropName: any;

    constructor(private viewportScroller: ViewportScroller, public router: Router) { }

  public onClick(elementId: string): void { 
      this.viewportScroller.scrollToAnchor(elementId);
  }

    ngOnInit() {
     
  }


   
    gotocrop(val) {
        if (val == 5) {
            debugger
         ///   this.CropName = "Chiili,మిరప,मिर्च"
            this.router.navigate(['/cropguidance', { CropId: 5 }]).then(() => { window.location.reload(); });

        } else if (val == 3) {
            debugger
        //    this.CropName = "Capsicum,కాప్సికం,शिमला%20मिर्च%0D%0A"
            this.router.navigate(['/cropguidance', { CropId: 3 }]).then(() => { window.location.reload(); });

        } else if (val == 2) {
            debugger
         //   this.CropName = "Papaya,బొప్పాయి, पपीता"
            this.router.navigate(['/cropguidance', { CropId: 2 }]).then(() => { window.location.reload(); });


        } else if (val == 4) {
            debugger
       //     this.CropName = "Tomatoes,టమోటా,टमाटर"
            this.router.navigate(['/cropguidance', { CropId: 4 }]).then(() => { window.location.reload(); });

        } else {
            debugger
        //    this.CropName = "Paddy,వరి,धान"
            this.router.navigate(['/cropguidance', { CropId: 6 }]).then(() => { window.location.reload(); });

        }
     
    }

    gotocrop1(val: any) {
        debugger
        this.router.navigate(['/cropguidance', { CropId: val }]);
    }

   

    gotoproduc(val) {
        if (val == 1) {
            debugger
            this.router.navigate(['/product-details', { ProdID: 1 }]).then(() => { window.location.reload(); });

        } else if (val == 2) {
            debugger
            this.router.navigate(['/product-details', { ProdID: 2 }]).then(() => { window.location.reload(); });

        } else if (val == 101) {
            debugger
            this.router.navigate(['/product-details', { ProdID: 101 }]).then(() => { window.location.reload(); });

        }

        else if (val == 4) {
            debugger
            this.router.navigate(['/product-details', { ProdID: 4 }]).then(() => { window.location.reload(); });

        } else if (val == 21) {
            debugger
            this.router.navigate(['/product-details', { ProdID: 21 }]).then(() => { window.location.reload(); });

        }
        else {
            this.router.navigate(['/cropguidance', { CropId: 6, CropName: this.CropName }]).then(() => { window.location.reload(); });

        }



    }


    gotoproducts(value: any) {
        debugger
        this.router.navigate(['/product-details', { ProdID: value }]).then(() => { window.location.reload(); });
    }

}



import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ViewportScroller } from '@angular/common';
import { GeneralService } from '../../Services/generalservice.service';
import { Router } from '@angular/router';
import { HttpClient, HttpHandler, HttpResponse, HttpRequest } from '@angular/common/http';

@Component({
  selector: 'app-vyorganicgranules',
  templateUrl: './vyorganicgranules.component.html',
  styleUrls: ['./vyorganicgranules.component.scss']
})
export class VyorganicgranulesComponent {

    dataResult: any = [];
    arr: any;

    constructor(private viewportScroller: ViewportScroller, public router: Router, public generalService: GeneralService, public http: HttpClient) { }

    public onClick(elementId: string): void {
        this.viewportScroller.scrollToAnchor(elementId);
    }

    ngOnInit() {

    }

    banners(val) {
        debugger
        this.router.navigate(['/cropguidance', { CropId: val }]);
    }

    homeSlides: OwlOptions = {
        loop: true,
        nav: true,
        dots: false,
        autoplayHoverPause: true,
        items: 1,
        autoHeight: true,
        smartSpeed: 750,
        autoplay: true,
        navText: [
            "<i class='fa fa-angle-left'></i>",
            "<i class='fa fa-angle-right'></i>"
        ]
    }

}

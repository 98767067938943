<meta name="keywords" content="organic manure,organic fertilizer, BOTANICAL VIRUCIDE,BOTANICAL BACTERICIDE & FUNGICIDE,ORGANIC SUCKING PEST,YK Laboratories,yklabs,papaya,
    YK LABORATORIES SPECIAL,OIL FUMIGATION & DISINFECTANTFOGGING AND FUMIGATION,YKSILV Clean &. Disinfection,IMMUNO , FOST-ROOTEX ,HI-RICH ,NEEMOIL,WILT SPECIAL,Oxyveg - cotton special,
          MAXCROP,MAX SIZE,ENVIRO,CYTO PLUS, CUREAL,FUNGAL & BACTERIAL SPECIAL-FUNGAL & BACTERIAL SPECIAL,VAAYU,Plant-Crop Pest & Insect Control Vaayu Yantra,
          Vaayu Yantra Arogya,Vaayu Yantra Organic Granules-VAAYU & YANTRA,PAPPAYA SPECIAL,CHILLI SPECIAL,CHILLI SPECIAL,CAPSICUM SPECIAL,Vaayu Yantra Organic Granules-VAAYU & YANTRA,
          TOMATO, CHILLI, GOURDS SPECIAL-YK LABORATORIES SPECIAL,3% Hydrogen peroxide (Food Grade),Nano Silver 2000 ppm for Plant Protection & Disinfection,
          YKSILV-Ultra Mist Fumigation,AAYUSH Natural Fumigant,YKSILV-Ultra Mist Fumigation,AAYUSH Natural Fumigant,Aphids,Armyworms,Asparagus Beetle,Cabbage looper,Cabbage worms,Corn Earworm,
          Cucumber Beetle,Cutworms,Earwig,European Corn borer,flea beetle,grasshopper,leafhopper,leafminer,mexican bean beetle,potato beetle,psyllid,Root Maggoots,slug & nail,Showbug & Pillbug,
          spider Mites,Squash bugs,thrips,Tomato harnwarm,whiteflies,???????, ?????????????, ????????? ??????, ???????? ?????, ???????? ????????, ?????????? ???? ??????,
          ?????? ??????, ?????????????, ????????, ????????? ?????????? ?????? ??????, ???? ??????, ????????, ??????????, ??????????, ????????? ???? ??????, ????????? ??????, ??????, ???? ??????????, ????? & ??????, ????? & ????????
          ??????? ??????, ???????? ?????, ????????, ?????? ?????????????, ???????????, Cotton Pinkboll worm,pal borer,immuno Enviro,neem oil,Nano Silver Curcal,
Leaf Curl virus,Yellow Mosaic Virys, Gemini Virus,Ring Spot Virus,Tomato spotted wilt voy,Papaya Bunchy Top, Papaya Valloir Mosaïl, Sajuash Molail PEPPEY,Bacterial Blight,
Powdery Mildew,Early blight,Last light,Bacterial Disease,Tomato spotted wilt Virus, Porshey Mildews,pepper soft rot disease,Fast Rootex - Soil Health & Organic,Soil Organic Garbon,Soil Moisture,
 Water holding, ph Balance - Soil, Soil pH Balance, Soil Micro Organisom,Soil Fertility,Mineral uptaking,Vaayu Yantra ,Vaya yantra,Soil Nematodes,
 White Gurb, Soil Eggs & Pest,Paddy New Shoot,wilt Special , Root Rot nematoder, Fusarium wilt,Bacteria Wilt, Vertialium Wilt,plant dead, wilt,
 Plant wilt,Neemoil 7 Cold Pressed Neem oil. 3000 ppm.,Hirich :-7 Organic Monure,Organic Fertilize,Drganic NPI. " />
<div class="main-banner item-bg-one">
    <div class="d-table">
        <div class="breadcrumb">
            <div class="container">
                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li><a routerLink="/">product-details</a></li>
                </ul>
                <h2 style="  color: white">{{productname}}</h2>
            </div>

        </div>
    </div>
</div>
<br />
<div class="container">


    <div class="content">
        <!--<input type="radio" name="slider" id="home">-->
        <input type="radio" name="slider" id="blog">
        <input type="radio" name="slider" id="help">
        <input type="radio" name="slider" id="code">
        <input type="radio" name="slider" id="about">
        <input type="radio" name="slider" id="swa">
        <div class="list">
            <label for="blog" class="blog">
                <span><img src="../../../assets/img/NewImages/skincare.png" class="img_tab ms-1" />&nbsp;&nbsp;Product </span>
            </label>
            <label for="help" class="help">
                <span><img src="../../../assets/img/NewImages/benefit.png" class="img_tab  ms-1" />&nbsp;&nbsp;Benefits</span>
            </label>
            <label for="code" class="code">
                <span><img src="../../../assets/img/NewImages/wheat.png" class="img_tab  ms-1" />&nbsp;&nbsp;Crops</span>
            </label>
            <label for="about" class="about">
                <span><img src="../../../assets/img/NewImages/medicine.png" class="img_tab  ms-1" />&nbsp;&nbsp;Dosage</span>
            </label>
            <label for="swa" class="swa">
                <span><img src="../../../assets/img/NewImages/natural-ingredients.png" class="img_tab  ms-1" />&nbsp;&nbsp;Ingredients</span>
            </label>
            <div class="slider"></div>
        </div>

        <div class="text-content mobile1">
            <div class="home text">
                <div class="title"></div>
                <div class="row">
                    <div class="col-lg-3 col-xs-6 img_card">
                        <a href="{{ProductImages}}" target="_blank">
                            <img src="{{ProductImages}}" class="ProductImages" alt="Product image" />
                        </a>

                    </div>

                    <div class="col-lg-9 col-xs-6">
                        <div class="row">
                            <div class="col-lg-9">
                                <div class="b"> {{Title}}-{{CategoryName}}</div>
                                <div>
                                    <button *ngIf="Pack1" class="btn123" (click)="selectPackage(Pack1)" [ngStyle]="{'background-color': selectedPackage === Pack1 ? '#800080' : ''}">{{Pack1}}</button>
                                    <button *ngIf="Pack2" class="btn123" (click)="selectPackage(Pack2)" [ngStyle]="{'background-color': selectedPackage === Pack2 ? '#800080' : ''}">{{Pack2}}</button>
                                    <button *ngIf="Pack3" class="btn123" (click)="selectPackage(Pack3)" [ngStyle]="{'background-color': selectedPackage === Pack3 ? '#800080' : ''}">{{Pack3}}</button>
                                    <button *ngIf="Pack4" class="btn123" (click)="selectPackage(Pack4)" [ngStyle]="{'background-color': selectedPackage === Pack4 ? '#800080' : ''}">{{Pack4}}</button>
                                </div>

                                <div>
                                    {{Title}}
                                </div>
                                <div>
                                    <ng-container *ngFor="let infoPart of ProductInfo.split('#'); let i = index">
                                        <div>
                                            <span>*</span>   {{ infoPart }}
                                            <br *ngIf="i < ProductInfo.split('#').length - 1" />
                                        </div>
                                    </ng-container><br /><br />
                                    <iframe *ngIf="YoutubeLink" [src]="YoutubeLink" type="text/html" allowfullscreen width="100%" height="200" frameborder="0"></iframe>

                                </div>
                            </div>
                            <div class="col-lg-3">
                                <div class="enqui1 enquirr">
                                    <div (click)="GotoMail(Title)" style="cursor:pointer">
                                        Place Enquiry
                                    </div>
                                </div><br />
                                <div class="ammz butttn" *ngIf="AmazonURL">
                                    <a href="{{AmazonURL}}" target="_blank">
                                        <img src="../../../../assets/img/NewImages/amazon-logo.png" class="amazon" alt="yk labs" />
                                        Buy in Amazon
                                    </a>
                                </div>
                                <br />
                                <div class="qrcod ammz" *ngIf="QRCode">
                                    <a href="{{Homeurl+Pdf}}" target="_blank">
                                        <img src="{{Homeurl+QRCode}}" alt="Qrcode">
                                    </a>
                                </div><br />
                                <div class="ammz butttn" *ngIf="AmazonURL">
                                    <a href="{{ykmarturl}}" target="_blank">
                                        <img src="../../../assets/img/NewImages/YKMartNew.PNG" class="amazon" alt="yk labs" />
                                        Buy in ykmart
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />

            </div>
            <div class="blog text">
                <div class="title"></div>
                <div class="row">
                    <div class="col-lg-3 col-xs-6 img_card">
                        <a href="{{ProductImages}}" target="_blank">
                            <img src="{{ProductImages}}" class="ProductImages" alt="Product image" />
                        </a>

                    </div>
                    <div class="col-lg-9 col-xs-6">
                        <div class="row">
                            <div class="col-lg-9">
                                <div class="b"> {{Title}}-{{CategoryName}}</div>
                                <!--<div>
                                    <button *ngFor="let package of [Pack1, Pack2, Pack3, Pack4]" class="btn123 " (click)="selectPackage(package)" [ngStyle]="{'background-color': selectedPackage === package ? '#800080' : ''}">{{package}}</button>
                                </div>-->
                                <div>
                                    <button *ngIf="Pack1" class="btn123" (click)="selectPackage(Pack1)" [ngStyle]="{'background-color': selectedPackage === Pack1 ? '#800080' : '', 'color': selectedPackage === Pack1 ? 'white' : 'black'}">{{Pack1}}</button>
                                    <button *ngIf="Pack2" class="btn123" (click)="selectPackage(Pack2)" [ngStyle]="{'background-color': selectedPackage === Pack2 ? '#800080' : ''}">{{Pack2}}</button>
                                    <button *ngIf="Pack3" class="btn123" (click)="selectPackage(Pack3)" [ngStyle]="{'background-color': selectedPackage === Pack3 ? '#800080' : ''}">{{Pack3}}</button>
                                    <button *ngIf="Pack4" class="btn123" (click)="selectPackage(Pack4)" [ngStyle]="{'background-color': selectedPackage === Pack4 ? '#800080' : ''}">{{Pack4}}</button>
                                </div>

                                <div>
                                    {{Title}}
                                </div>
                                <div>
                                    <ng-container *ngFor="let infoPart of ProductInfo.split('#'); let i = index">
                                        <div >
                                            <span>*</span>   {{ infoPart }}
                                            <br *ngIf="i < ProductInfo.split('#').length - 1" />
                                        </div>
                                    </ng-container><br /><br />
                                    <iframe *ngIf="YoutubeLink" [src]="YoutubeLink" type="text/html" allowfullscreen width="100%" height="200" frameborder="0"></iframe>

                                </div>
                            </div>
                            <div class="col-lg-3">
                                <div class="enqui1 enquirr" style="cursor:pointer">
                                    <div (click)="GotoMail(Title)">
                                        Place Enquiry
                                    </div>
                                </div>
                                <br />
                                <div class="ammz butttn" *ngIf="AmazonURL">
                                    <a href="{{AmazonURL}}" target="_blank">
                                        <img src="../../../../assets/img/NewImages/amazon-logo.png" class="amazon" style="width: 91%;" alt="Amazon" />
                                        Buy in Amazon
                                    </a>
                                </div>
                                <br />
                                <div class="qrcod ammz" *ngIf="QRCode">
                                    <a href="{{Homeurl+Pdf}}" target="_blank">
                                        <img src="{{Homeurl+QRCode}}" alt="Qrcode">
                                    </a>
                                </div>
                                <br />
                                <button class="ammz" *ngIf="ykmarturl">
                                    <a href="{{ykmarturl}}" target="_blank">
                                        <img src="../../../assets/img/NewImages/YKMartNew.PNG" class="amazon" style="width: 91%;" alt="yk Mart" />
                                        Buy in ykmart
                                    </a>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="help text">
                <div class="title"></div>
                <div class="row">
                    <div class="col-lg-3 col-xs-6 img_card">
                        <a href="{{ProductImages}}" target="_blank">
                            <img src="{{ProductImages}}" class="ProductImages" alt="Product image" />
                        </a>

                    </div>
                    <div class="col-lg-9 col-xs-6">
                        <div class="row">
                            <div class="col-lg-9">
                                <b>  {{Title}}</b>
                                <div>
                                    <ng-container *ngFor="let infoPart1 of Benefits.split('#'); let i = index">
                                        <span>*</span>  {{ infoPart1 }}
                                        <br *ngIf="i < Benefits.split('#').length - 1" />
                                    </ng-container>
                                </div>
                            </div>
                            <div class="col-lg-3">
                                <button class="enqui1 enquirr">
                                    <div (click)="GotoMail(Title)">
                                        Place Enquiry
                                    </div>
                                </button>
                                &nbsp;
                                <div class="ammz butttn" *ngIf="AmazonURL">
                                    <a href="{{AmazonURL}}" target="_blank">
                                        <img src="../../../../assets/img/NewImages/amazon-logo.png" class="amazon" style="width: 91%;" alt="Amazon" />
                                        Buy in Amazon
                                    </a>
                                </div>&nbsp;
                                <div class="ammz" *ngIf="ykmarturl">
                                    <a href="{{ykmarturl}}" target="_blank">
                                        <img src="../../../assets/img/NewImages/YKMartNew.PNG" class="amazon" style="width: 91%;" alt="yk Mart" />
                                        Buy in ykmart
                                    </a>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
            <div class="code text">
                <div class="title"></div>
                <div class="row">
                    <div class="col-lg-3 col-xs-6 img_card">
                        <a href="{{ProductImages}}" target="_blank">
                            <img src="{{ProductImages}}" class="ProductImages" alt="Product image" />
                        </a>

                    </div>
                    <div class="col-lg-9 col-xs-6">
                        <div class="row">
                            <div class="col-lg-9">
                                <b>  {{Title}}</b>
                                <div>
                                    <ng-container *ngFor="let Recommended of RecommendedCrops.split('#'); let i = index">
                                        <span>*</span> {{ Recommended }}
                                        <br *ngIf="i < RecommendedCrops.split('#').length - 1" />
                                    </ng-container>
                                </div>
                            </div>
                            <div class="col-lg-3">
                                <button class="enqui1 enquirr">
                                    <div (click)="GotoMail(Title)">
                                        Place Enquiry
                                    </div>
                                </button>
                                &nbsp;
                                <div class="ammz" *ngIf="AmazonURL">
                                    <a href="{{AmazonURL}}" target="_blank">
                                        <img src="../../../../assets/img/NewImages/amazon-logo.png" class="amazon" style="width: 91%;" alt="Amazon" />
                                        Buy in Amazon
                                    </a>
                                </div>&nbsp;
                                <div class="ammz" *ngIf="ykmarturl">
                                    <a href="{{ykmarturl}}" target="_blank">
                                        <img src="../../../assets/img/NewImages/YKMartNew.PNG" class="amazon" style="width: 91%;" alt="yk Mart" />
                                        Buy in ykmart
                                    </a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="about text">
                <div class="title"></div>
                <div class="row">
                    <div class="col-lg-3 col-xs-6 img_card">
                        <a href="{{ProductImages}}" target="_blank">
                            <img src="{{ProductImages}}" class="ProductImages" alt="Product image" />
                        </a>

                    </div>
                    <div class="col-lg-9 col-xs-6">
                        <div class="row">
                            <div class="col-lg-9">
                                <b>  {{Title}}</b><br />
                                <div>Dosage</div>
                                <div>
                                    <ng-container *ngFor="let Dosage1 of Dosage.split('#'); let i = index">
                                        <span>*</span>  {{ Dosage1 }}
                                        <br *ngIf="i < Dosage.split('#').length - 1" />
                                    </ng-container>
                                </div>
                            </div>
                            <div class="col-lg-3">
                                <button class="enqui1 enquirr">
                                    <div (click)="GotoMail(Title)">
                                        Place Enquiry
                                    </div>
                                </button>
                                &nbsp;
                                <div class="ammz butttn" *ngIf="AmazonURL">
                                    <a href="{{AmazonURL}}" target="_blank">
                                        <img src="../../../../assets/img/NewImages/amazon-logo.png" class="amazon" style="width: 91%;" alt="Amazon" />
                                        Buy in Amazon
                                    </a>
                                </div>&nbsp;
                                <div class="ammz" *ngIf="ykmarturl">
                                    <a href="{{ykmarturl}}" target="_blank">
                                        <img src="../../../assets/img/NewImages/YKMartNew.PNG" class="amazon" style="width: 91%;" alt="yk Mart" />
                                        Buy in ykmart
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="swa text">
                <div class="title"></div>
                <div class="row">
                    <div class="col-lg-3 col-xs-6 img_card">
                        <a href="{{ProductImages}}" target="_blank">
                            <img src="{{ProductImages}}" class="ProductImages" alt="Product image" />
                        </a>

                    </div>
                    <div class="col-lg-9 col-xs-6">
                        <div class="row">
                            <div class="col-lg-9">
                                <b>  {{Title}}</b>
                                <div>
                                    <ng-container *ngFor="let Ingredient of Ingredients.split('#'); let i = index">
                                        <span>*</span>   {{ Ingredient }}
                                        <br *ngIf="i < Ingredients.split('#').length - 1" />
                                    </ng-container>
                                </div>
                            </div>
                            <div class="col-lg-3">
                                <div class="enqui1 enquirr" style="cursor:pointer">
                                    <div (click)="GotoMail(Title)">
                                        Place Enquiry
                                    </div>
                                </div>
                                &nbsp;
                                <div class="ammz butttn" *ngIf="AmazonURL">
                                    <a href="{{AmazonURL}}" target="_blank">
                                        <img src="../../../../assets/img/NewImages/amazon-logo.png" class="amazon" style="width: 91%;" alt="Amazon" />
                                        Buy in Amazon
                                    </a>
                                </div>&nbsp;
                                <div class="ammz" *ngIf="ykmarturl">
                                    <a href="{{ykmarturl}}" target="_blank">
                                        <img src="../../../assets/img/NewImages/YKMartNew.PNG" class="amazon" style="width: 91%;" alt="yk Mart" />
                                        Buy in ykmart
                                    </a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
            <br />

        </div>

    </div>



























    <div class="mobile_tab">
        <div class="warpper">
            <input class="radio" id="one" name="group" type="radio" checked>
            <input class="radio" id="two" name="group" type="radio">
            <input class="radio" id="three" name="group" type="radio">
            <input class="radio" id="four" name="group" type="radio">
            <input class="radio" id="five" name="group" type="radio">

            <div class="tabs">
                <label class="tab" id="one-tab" for="one">Products</label>
                <label class="tab" id="two-tab" for="two">Benefits</label>
                <label class="tab" id="three-tab" for="three">Crops</label>
                <label class="tab" id="four-tab" for="four">Dosage</label>
                <label class="tab" id="five-tab" for="five">Ingredients</label>
            </div><br />
            <div class="panels">
                <div class="panel" id="one-panel">
                    <div class="panel-title"></div>
                    <div class="row">
                        <div class="col-lg-3 img_card">
                            <img src="{{ProductImages}}" alt="hello" class="ProductImages" />
                        </div>
                        <div class="col-lg-9">
                            <div class="row">
                                <div class="col-lg-9">
                                    <div class="b"> {{Title}}-{{CategoryName}}</div>
                                    <div>
                                        <button *ngIf="Pack1" class="btn123" (click)="selectPackage(Pack1)" [ngStyle]="{'background-color': selectedPackage === Pack1 ? '#800080' : ''}">{{Pack1}}</button>
                                        <button *ngIf="Pack2" class="btn123" (click)="selectPackage(Pack2)" [ngStyle]="{'background-color': selectedPackage === Pack2 ? '#800080' : ''}">{{Pack2}}</button>
                                        <button *ngIf="Pack3" class="btn123" (click)="selectPackage(Pack3)" [ngStyle]="{'background-color': selectedPackage === Pack3 ? '#800080' : ''}">{{Pack3}}</button>
                                        <button *ngIf="Pack4" class="btn123" (click)="selectPackage(Pack4)" [ngStyle]="{'background-color': selectedPackage === Pack4 ? '#800080' : ''}">{{Pack4}}</button>
                                    </div>

                                    <div>
                                        {{Title}}
                                    </div>
                                    <div>
                                        <ng-container *ngFor="let infoPart of ProductInfo.split('#'); let i = index">
                                            <div>
                                                <span>*</span>   {{ infoPart }}
                                                <br *ngIf="i < ProductInfo.split('#').length - 1" />
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                                <div class="col-lg-3">
                                    <div class="enqui1 enquirr">
                                        <div (click)="GotoMail(Title)">
                                            Place Enquiry
                                        </div>
                                    </div><br />
                                    <div class="ammz butttn" *ngIf="AmazonURL">
                                        <a href="{{AmazonURL}}" target="_blank">
                                            <img src="../../../../assets/img/New Images/amazon-logo.png" class="amazon" />
                                            Buy in Amazon
                                        </a>
                                    </div>
                                    <br />
                                    <div class="qrcod" *ngIf="QRCode">
                                        <a href="{{Homeurl+Pdf}}" target="_blank">
                                            <img src="{{Homeurl+QRCode}}">
                                        </a>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="panel" id="two-panel">
                    <div class="panel-title"></div>
                    <div class="row">
                        <div class="col-lg-3 img_card">
                            <img src="{{ProductImages}}" alt="hello" class="ProductImages" />
                        </div>
                        <div class="col-lg-9">
                            <b>  {{Title}}</b>
                            <div>
                                <ng-container *ngFor="let infoPart1 of Benefits.split('#'); let i = index">
                                    <span>*</span>  {{ infoPart1 }}
                                    <br *ngIf="i < Benefits.split('#').length - 1" />
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="panel" id="three-panel">
                    <div class="panel-title"></div>
                    <div class="row">
                        <div class="col-lg-3 img_card">
                            <img src="{{ProductImages}}" alt="hello" class="ProductImages" />
                        </div>
                        <div class="col-lg-9">
                            <b>  {{Title}}</b>
                            <div>
                                <ng-container *ngFor="let Recommended of RecommendedCrops.split('#'); let i = index">
                                    <span>*</span> {{ Recommended }}
                                    <br *ngIf="i < RecommendedCrops.split('#').length - 1" />
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="panel" id="four-panel">
                    <div class="panel-title"></div>
                    <div class="row">
                        <div class="col-lg-3 img_card">
                            <img src="{{ProductImages}}" alt="hello" class="ProductImages" />
                        </div>
                        <div class="col-lg-9">
                            <b>  {{Title}}</b><br />
                            <div>Dosage</div>
                            <div>
                                <ng-container *ngFor="let Dosage1 of Dosage.split('#'); let i = index">
                                    <span>*</span>  {{ Dosage1 }}
                                    <br *ngIf="i < Dosage.split('#').length - 1" />
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="panel" id="five-panel">
                    <div class="panel-title"></div>
                    <div class="row">
                        <div class="col-lg-3 img_card">
                            <img src="{{ProductImages}}" alt="hello" class="ProductImages" />
                        </div>
                        <div class="col-lg-9">
                            <b>  {{Title}}</b>
                            <div>
                                <ng-container *ngFor="let Ingredient of Ingredients.split('#'); let i = index">
                                    <span>*</span>   {{ Ingredient }}
                                    <br *ngIf="i < Ingredients.split('#').length - 1" />
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

<div *ngIf="spinner" class="loading-indicator">
    <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
</div>
<br />

<div class="main-banner item-bg-one">
    <div class="d-table">
        <div class="breadcrumb">
            <div class="container">
                <ul>
                    <li><a href="/">Home</a></li>
                    <li><a href="/about-us">Awards</a></li>
                </ul>
                <h2 style="  color: white">Awards</h2>
            </div>

        </div>
    </div>

</div>

<br />

<section class="portfolio section-padding_ab1">
    <div class="container">
        <div class="gallery-grid">
            <div class="row">
                <div class="col-lg-3 col-xs-6">
                    <figure class="gallery-frame ">
                        <a href="../../../../assets/NewImages/certificate1.jpeg" target="_blank">
                            <img class="gallery-img imgcenter" src="../../../../assets/NewImages/certificate.jpg" alt="symbol image" title="symbol image">
                            <br />  <figcaption class="capcenter"> CSIR-CIMAP</figcaption><br />
                        </a>
                    </figure>
                </div>
                <div class="col-lg-3 col-xs-6">
                    <figure class="gallery-frame ">
                        <a href="../../../assets/PDF/019105.pdf" target="_blank">
                            <img class="gallery-img imgcenter" src="../../../assets/img/Awards/New/bio-certificate.webp" alt="symbol image" title="symbol image">
                            <br />  <figcaption class="capcenter"> SCOPE CERTIFICATE</figcaption><br />
                        </a>
                    </figure>
                </div>
                <div class="col-lg-3  col-xs-6">
                    <figure class="gallery-frame">
                        <a href="../../../assets/PDF/ITC_Report_Cyto_29.pdf" target="_blank">
                            <img class="gallery-img imgcenter" src="../../../assets/img/Awards/New/ITCLimited.webp" alt="symbol image" title="symbol image">
                            <br />    <figcaption class="capcenter">ITC REPORT CYTO</figcaption>
                        </a>
                    </figure>
                </div>
                <div class="col-lg-3 col-xs-6">
                    <figure class="gallery-frame ">
                        <a href="../../../assets/PDF/ITC_test_report _IMMUNO.. 2.pdf" target="_blank">
                            <img class="gallery-img imgcenter" src="../../../assets/img/Awards/New/ITCLimited.webp" alt="symbol image" title="symbol image">
                            <br />  <figcaption class="capcenter">ITC TEST REPORT IMMUNO</figcaption>
                        </a>
                    </figure>
                </div>
                <div class="col-lg-3  col-xs-6">
                    <figure class="gallery-frame">
                        <a href="../../../assets/PDF/ITC_PURCHASE_ORDER.pdf" target="_blank">
                            <img class="gallery-img imgcenter" src="../../../assets/img/Awards/New/ITCLimited.webp" alt="symbol image" title="symbol image">
                            <br />  <figcaption class="capcenter">ITC PURCHASE ORDER</figcaption>
                        </a>
                    </figure>
                </div>
                <div class="col-lg-3  col-xs-6">
                    <figure class="gallery-frame ">
                        <a href="../../../assets/PDF/IMMUNO.pdf" target="_blank">
                            <img class="gallery-img imgcenter" src="../../../assets/img/Awards/New/immuno-1.webp" alt="symbol image" title="symbol image">
                            <br />   <figcaption class="capcenter">EMMUNO</figcaption>
                        </a>
                    </figure>
                </div>
                <div class="col-lg-3  col-xs-6">
                    <figure class="gallery-frame">
                        <a href="../../../assets/PDF/IMPEL.pdf" target="_blank">
                            <img class="gallery-img imgcenter" src="../../../assets/img/Awards/New/impel-1.webp" alt="symbol image" title="symbol image">
                            <br />  <figcaption class="capcenter">IMPEL</figcaption>
                        </a>
                    </figure>
                </div>
                <div class="col-lg-3  col-xs-6">
                    <figure class="gallery-frame">
                        <a href="../../../assets/PDF/telangana-state-organic-certification-authority.pdf" target="_blank">
                            <img class="gallery-img imgcenter" src="../../../assets/img/Awards/New/tstelangana-state-organic-certification-authority.webp" alt="symbol image" title="symbol image">
                            <br />   <figcaption class="capcenter">T.S ORGANIC CERTIFICATE</figcaption>
                        </a>
                    </figure>
                </div>
                <div class="col-lg-3  col-xs-6">
                    <figure class="gallery-frame">
                        <a href="../../../assets/PDF/PHOTO-2019-02-19-19-41-41.pdf" target="_blank">
                            <img class="gallery-img imgcenter" src="../../../assets/img/Awards/New/ITCLimited.webp" alt="symbol image" title="symbol image">
                            <br />   <figcaption class="capcenter">ITC APPROVED</figcaption>
                        </a>
                    </figure>
                </div>
                <div class="col-lg-3  col-xs-6">
                    <figure class="gallery-frame">
                        <a href="../../../assets/PDF/Cyto-WA0018.pdf" target="_blank">
                            <img class="gallery-img imgcenter" src="../../../assets/img/Awards/New/cyto-1.webp" alt="symbol image" title="symbol image">
                            <br /> <figcaption class="capcenter">CYTO LABS</figcaption>
                        </a>
                    </figure>
                </div>
                <div class="col-lg-3  col-xs-6">
                    <figure class="gallery-frame">
                        <a href="../../../assets/PDF/ITC_test_report _IMMUNO.. 2.pdf" target="_blank">
                            <img class="gallery-img imgcenter" src="../../../assets/img/Awards/New/enviro-2.webp" alt="symbol image" title="symbol image">
                            <br /> <figcaption class="capcenter">CYTO LABS</figcaption>
                        </a>
                    </figure>
                </div>
                <div class="col-lg-3 col-xs-6">
                    <figure class="gallery-frame">
                        <a href="../../../assets/PDF/Fost-Rootex.pdf" target="_blank">
                            <img class="gallery-img imgcenter" src="../../../assets/img/Awards/New/fost-rootex.webp" alt="symbol image" title="symbol image">
                            <br /> <figcaption class="capcenter">CYTO LABS</figcaption>
                        </a>
                    </figure>
                </div>

                <div class="col-lg-3 col-xs-6">
                    <figure class="gallery-frame">
                        <a href="../../../assets/PDF/ITC_test_report_ENVIRO.. 2.pdf" target="_blank">
                            <img class="gallery-img imgcenter" src="../../../assets/img/Awards/New/ITCLimited.webp" alt="symbol image" title="symbol image">
                            <br />   <figcaption class="capcenter">ITC TEST REPORT ENVIRO</figcaption>
                        </a>
                    </figure>
                </div>

                <div class="col-lg-3 col-xs-6">
                    <figure class="gallery-frame">
                        <a href="../../../assets/PDF/YK Labarotaries(AOCA-NPOP)......pdf" target="_blank">
                            <img class="gallery-img imgcenter" src="../../../assets\img\Awards\New\AOCA LOGO_Approved Input_C2C.webp"
                                 alt="symbol image" title="symbol image" style="width:78%">
                            <br />   <figcaption class="capcenter"> APOF ORGANIC CERTIFICATE</figcaption>
                        </a>
                    </figure>
                </div>

                <div class="col-lg-3 col-xs-6">
                    <figure class="gallery-frame">
                        <a href="../../../assets/PDF/red chilli resudule ITC certfct_compressed.pdf" target="_blank">
                            <img class="gallery-img imgcenter" src="../../../assets/img/Awards/New/ITCLimited.webp"
                                 alt="symbol image" title="symbol image">
                            <br />   <figcaption class="capcenter fonttt"> RED CHILLI RESIDUAL TEST REPORT BY ITC UNDER ORGANIC</figcaption>
                        </a>
                    </figure>
                </div>
                <div class="col-lg-3 col-xs-6">
                    <figure class="gallery-frame">
                        <a href="https://yklabsadmin.breakingindiaapp.com/assets/PDF/Vaayuu-Yantra.pdf" target="_blank">
                            <img class="gallery-img1 imgcenter" src="../../../../assets/img/NewImages/vayulgo.png" alt="symbol image" title="symbol image">
                            <br />  <figcaption class="capcenter">NHRDF CERTIFICATE</figcaption>
                        </a>
                    </figure>
                </div>
            </div>

        </div>
    </div>




</section>


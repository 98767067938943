
<div class="main-banner item-bg-one">
    <div class="d-table">
        <div class="breadcrumb">
            <div class="container">
                <ul>
                    <li><a href="/">Home</a></li>
                    <li><a href="/">Testimonials</a></li>
                </ul>
                <h2 style="  color: white">Testimonials</h2>
            </div>

        </div>
    </div>

</div>
<br />

<div class="container">
    <div class="years">
        <button class="year" (click)="calender(2010)" [ngClass]="{ 'selected': selectedYear === 2010 }">2010</button>&nbsp;
        <button class="year" (click)="calender(2011)" [ngClass]="{ 'selected': selectedYear === 2011 }">2011</button>&nbsp;
        <button class="year" (click)="calender(2012)" [ngClass]="{ 'selected': selectedYear === 2012 }">2012</button>&nbsp;
        <button class="year" (click)="calender(2013)" [ngClass]="{ 'selected': selectedYear === 2013 }">2013</button>&nbsp;
        <button class="year" (click)="calender(2014)" [ngClass]="{ 'selected': selectedYear === 2014 }">2014</button>&nbsp;
        <button class="year" (click)="calender(2015)" [ngClass]="{ 'selected': selectedYear === 2015 }">2015</button>&nbsp;
        <button class="year" (click)="calender(2016)" [ngClass]="{ 'selected': selectedYear === 2016 }">2016</button>&nbsp;
        <button class="year" (click)="calender(2017)" [ngClass]="{ 'selected': selectedYear === 2017 }">2017</button>&nbsp;
        <button class="year" (click)="calender(2018)" [ngClass]="{ 'selected': selectedYear === 2018 }">2018</button>&nbsp;
        <button class="year" (click)="calender(2019)" [ngClass]="{ 'selected': selectedYear === 2019 }">2019</button>&nbsp;
        <button class="year" (click)="calender(2020)" [ngClass]="{ 'selected': selectedYear === 2020 }">2020</button>&nbsp;
        <button class="year" (click)="calender(2021)" [ngClass]="{ 'selected': selectedYear === 2021 }">2021</button>&nbsp;
        <button class="year" (click)="calender(2022)" [ngClass]="{ 'selected': selectedYear === 2022 }">2022</button>&nbsp;
        <button class="year" (click)="calender(2023)" [ngClass]="{ 'selected': selectedYear === 2023 }">2023</button>&nbsp;
    </div>
</div>

<br />
<div class="loader-container" *ngIf="isLoading">
    <div class="loader"></div>
</div>

<div class="container">
    <div class="row">
        <div class="col-lg-4 col-xs-6" *ngFor="let youtube of YoutubeVideo">
            <iframe [src]="youtube.YoutubeLink" type="text/html" allowfullscreen width="100%" height="200" frameborder="0"></iframe>
        </div>
    </div>
</div>
<div *ngIf="spinner" class="loading-indicator">
    <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
</div>
<br />

<style>

    .year.selected {
        background-color: #aqua; /* Change to your desired highlight color */
        color: #fff; /* Change to your desired text color */
    }

    calender(year: number) {
        this.selectedYear = year;
        // Your calender logic here
    }

    /*-------------Loader styles-------------*/
    .loading-indicator {
        position: fixed;
        z-index: 999;
        height: 2em;
        width: 2em;
        overflow: show;
        margin: auto;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }

        .loading-indicator:before {
            content: '';
            display: block;
            position: fixed;
            top: 0;
            left: 0;
            width: 200%;
            height: 100%;
            background-color: rgba(0,0,0,0.53);
        }
    /*-------------Loader styles-------------*/

</style>

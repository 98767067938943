<div class="container">
    <div class="section-title">
        <div class="row">
            <div class="col-lg-6"><h3> Enquiry  Mail For {{ProdName}}</h3></div>
            <div class="col-lg-1"><button class="buttn" (click)="navigate()">x</button></div>
            <div class="col-lg-5"></div>
            </div>

        </div>





    <div class="row">
        <div class="col-lg-12">
            <div class="contact-form">

                <form #contactForm="ngForm" (ngSubmit)="onSubmit(contactForm.value)">
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group mb-3">
                                <label>Name</label>
                                <input required minlength="3" maxlength="50" ngModel name="name" type="text" #name="ngModel" class="form-control" id="name">
                                <div class="alert alert-danger" *ngIf="name.touched && !name.valid">
                                    <div *ngIf="name.errors.required">Name is required.</div>
                                    <div *ngIf="name.errors.minlength">Name is minimum {{ name.errors.minlength.requiredLength }} character.</div>
                                    <div *ngIf="name.errors.maxlength">Name is maximum 50 character.</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group mb-3">
                                <label>Email</label>
                                <input required ngModel name="email" type="text" #email="ngModel" class="form-control" id="email">
                                <div class="alert alert-danger" *ngIf="email.touched && !email.valid">Email Address is required.</div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group mb-3">
                                <label>Subject</label>
                                <input required ngModel name="subject" type="text" #subject="ngModel" class="form-control" id="subject">
                                <div class="alert alert-danger" *ngIf="subject.touched && !subject.valid">Phone subject is required.</div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group mb-3">
                                <label>Phone Number</label>
                                <input required ngModel name="number" type="text" #number="ngModel" class="form-control" id="number" maxlength="10">
                                <div class="alert alert-danger" *ngIf="number.touched && !number.valid">Phone number is required.</div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group mb-3">
                                <label>city</label>
                                <input required ngModel name="city" type="text" #city="ngModel" class="form-control" id="city">
                                <div class="alert alert-danger" *ngIf="city.touched && !number.valid">City is required.</div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group mb-3">
                                <label>Address</label>
                                <input required ngModel name="Address" type="text" #Address="ngModel" class="form-control" id="Address">
                                <div class="alert alert-danger" *ngIf="Address.touched && !number.valid">Address is required.</div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group mb-3">
                                <label>Area</label>
                                <input required ngModel name="Area" type="text" #Area="ngModel" class="form-control" id="Area">
                                <div class="alert alert-danger" *ngIf="Area.touched && !subject.valid">Area is required.</div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group mb-3">
                                <label>Pincode</label>
                                <input required ngModel name="Pincode" type="text" #Pincode="ngModel" class="form-control" id="Pincode">
                                <div class="alert alert-danger" *ngIf="Pincode.touched && !subject.valid">Pincode is required.</div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="form-group mb-3">
                                <br />
                                <label>Message</label>
                                <textarea required ngModel [(ngModel)]="message" name="message" id="message" cols="30" rows="6" class="form-control"></textarea>
                                <div class="alert alert-danger" *ngIf="message.touched && !name.valid">Message is required.</div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <br />
                            <button type="submit" class="btn btn-primary" [class.disabled]="!contactForm.valid">Send Message</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>

    </div>
</div><br />

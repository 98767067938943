<meta name="keywords" content="organic manure,organic fertilizer, BOTANICAL VIRUCIDE,BOTANICAL BACTERICIDE & FUNGICIDE,ORGANIC SUCKING PEST,YK Laboratories,yklabs,papaya,
    YK LABORATORIES SPECIAL,OIL FUMIGATION & DISINFECTANTFOGGING AND FUMIGATION,YKSILV Clean &. Disinfection,Red Chilli,chilli,Capsicum,Tomatoes,Cotton,Bitter Gourd,Brinjal,
          Cabbage,Cauliflower,Chrysanthemum,Flower,Ginger,Marigold,Melons,Onions,Paddy,Pulses,Sugarcane,Aphids,Armyworms,Asparagus Beetle,Cabbage looper,Cabbage worms,Corn Earworm,
          Cucumber Beetle,Cutworms,Earwig,European Corn borer,flea beetle,grasshopper,leafhopper,leafminer,mexican bean beetle,potato beetle,psyllid,Root Maggoots,slug & nail,Showbug & Pillbug,
          spider Mites,Squash bugs,thrips,Tomato harnwarm,whiteflies,అఫిడ్స్, ఆర్మీవార్మ్స్, ఆస్పరాగస్ బీటిల్, క్యాబేజీ లూపర్, క్యాబేజీ పురుగులు, మొక్కజొన్న చెవి పురుగు,
          దోసకాయ బీటిల్, కట్‌వార్మ్‌లు, చెవిపోటు, యూరోపియన్ మొక్కజొన్న తొలుచు పురుగు, ఫ్లీ బీటిల్, గొల్లభామ, లీఫ్‌హాపర్, లీఫ్‌మైనర్, మెక్సికన్ బీన్ బీటిల్, బంగాళదుంప బీటిల్, సైలిడ్, రూట్ మాగ్గూట్స్, స్లగ్ & నెయిల్, షోబగ్ & పిల్‌బగ్
          స్పైడర్ మైట్స్, స్క్వాష్ బగ్స్, త్రిప్స్, టొమాటో హార్న్‌వార్మ్, వైట్‌ఫ్లైస్, Cotton Pinkboll worm,pal borer,immuno Enviro,neem oil,Nano Silver Curcal,
Leaf Curl virus,Yellow Mosaic Virys, Gemini Virus,Ring Spot Virus,Tomato spotted wilt voy,Papaya Bunchy Top, Papaya Valloir Mosaïl, Sajuash Molail PEPPEY,Bacterial Blight,
Powdery Mildew,Early blight,Last light,Bacterial Disease,Tomato spotted wilt Virus, Porshey Mildews,pepper soft rot disease,Fast Rootex - Soil Health & Organic,Soil Organic Garbon,Soil Moisture,
 Water holding, ph Balance - Soil, Soil pH Balance, Soil Micro Organisom,Soil Fertility,Mineral uptaking,Vaayu Yantra ,Vaya yantra,Soil Nematodes,
 White Gurb, Soil Eggs & Pest,Paddy New Shoot,wilt Special , Root Rot nematoder, Fusarium wilt,Bacteria Wilt, Vertialium Wilt,plant dead, wilt,
 Plant wilt,Neemoil 7 Cold Pressed Neem oil. 3000 ppm.,Hirich :-7 Organic Monure,Organic Fertilize,Drganic NPI. " />
<div class="main-banner item-bg-one">
    <div class="d-table">
        <div class="breadcrumb">
            <div class="container">
                <ul>
                    <li><a href="/">Home</a></li>
                    <li><a href="/cropguidance">cropguidance</a></li>
                </ul>
                <h6 style="  color: white; font-size: 25px;">{{CropNamebind}}</h6>
            </div>

        </div>
    </div>

</div>

<br />
<div class="container scroll content">
    <div class="tab col-lg-12 col-md-12">
        <input id="tab1" type="radio" name="tabs" checked>
        <label for="tab1">1. Crop Disease</label>
        <input id="tab2" type="radio" name="tabs">
        <label for="tab2">2. Solution</label>
        <input id="tab3" type="radio" name="tabs">
        <label for="tab3">3. Video Gallery</label>
        <input id="tab4" type="radio" name="tabs">
        <label for="tab4">4. Crop Basic Information</label>
        <input id="tab5" type="radio" name="tabs">
        <label for="tab5">5. Schedule</label>
        <input id="tab6" type="radio" name="tabs">
        <label for="tab6">6. QR Code</label>



        <!--  of section 1 Crop Desicses -->

        <section id="content1">
            <div class="col-lg-12">
                <div class="row image-grid">
                    <div class="image-container " *ngFor="let img of Getallimages">
                        <a href="{{Homeurl+img.Images}}" target="_blank"> <img src="{{Homeurl+img.Images}}" alt="" style="width:100%;height:100%;" /></a>
                    </div>
                </div>
            </div>
        </section>


        <!-- section Solution -->
        <section id="content2">
            <div class="col-lg-12">
                <div class="row image-grid">
                    <div class="image-container" *ngFor="let solution of SolutionData">

                        <a href="{{Homeurl+solution.SolutionImg}}" target="_blank"> <img src="{{Homeurl+solution.SolutionImg}}" alt="" style="width:100%;height:100%;"></a>
                    </div>

                </div>

            </div>

        </section>

        <!--  section Video Gallary -->
        <section id="content3">
            <div class="col-lg-12">
                <div class="row">
                    <div class="col-lg-4 col-xs-6" *ngFor="let youtube of YoutubeVideo">
                        <iframe [src]="youtube.YoutubeLink" type="text/html" allowfullscreen width="100%" height="360" frameborder="0"></iframe>

                    </div>

                </div>
                <button class="videos_crop"><a href="https://www.youtube.com/@YKLaboratories" style="color:white" target="_blank">More videos</a></button>

            </div>
        </section>
        <!--  of section Crop Basic Information -->
        <section id="content4">
            <div class="col-lg-12">
                <div class="row image-grid">
                    <div class="image-container" *ngFor="let Info of InfoData">

                        <a href="{{Homeurl+Info.InfoImg}}" target="_blank"> <img src="{{Homeurl+Info.InfoImg}}" alt="" style="width:100%;height:100%;" /></a>
                    </div>
                </div>
            </div>
        </section>
        <!-- section Schedule -->
        <section id="content5">
            <div class="col-lg-12">
                <div class="row image-grid">
                    <div class="image-container" *ngFor="let Schedule of SheduleData">

                        <a href="{{Homeurl+Schedule.ScheduleImg}}" target="_blank">  <img src="{{Homeurl+Schedule.ScheduleImg}}" alt="" style="width:100%;height:100%;" /></a>
                    </div>
                </div>
            </div>
        </section>
        <!-- section Schedule -->
        <section id="content6">
            <div class="col-lg-12">
                <div class="row image-grid">
                    <div class="image-container" *ngFor="let item of QRimages">
                        <a href="{{Homeurl+item.QRImage}}" traget="_blank">
                            <img src="{{Homeurl+item.QRImage}}" alt="" style="width:58%;height:99%;" />
                        </a>

                    </div>
                </div>
            </div>
        </section>


    </div>


</div>
 <!--mobile code--> 
<div class="container">
    <div class="mobile_tab">
        <div class="warpper">
            <input class="radio" id="one" name="group" type="radio" checked>
            <input class="radio" id="two" name="group" type="radio">
            <input class="radio" id="three" name="group" type="radio">
            <input class="radio" id="four" name="group" type="radio">
            <input class="radio" id="five" name="group" type="radio">
            <input class="radio" id="six" name="group" type="radio">

            <div class="tabs">
                <label class="tab" id="one-tab" for="one"> Crop Disease</label>
                <label class="tab" id="two-tab" for="two">Solution</label>
                <label class="tab" id="three-tab" for="three">Video Gallery</label>
                <label class="tab" id="four-tab" for="four">Crop Basic Information</label>
                <label class="tab" id="five-tab" for="five">Schedule</label>
                <label class="tab" id="six-tab" for="six"> QR Code</label>

            </div><br />
            <div class="panels">
                <div class="panel" id="one-panel">
                    <div class="panel-title"></div>
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="row image-grid">
                                <div class="image-container " *ngFor="let img of Getallimages">
                                    <a href="{{Homeurl+img.Images}}" target="_blank"> <img src="{{Homeurl+img.Images}}" alt="" style="width:100%;height:100%;" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="panel" id="two-panel">
                    <div class="panel-title"></div>
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="row image-grid">
                                <div class="image-container" *ngFor="let solution of SolutionData">

                                    <a href="{{Homeurl+solution.SolutionImg}}" target="_blank"> <img src="{{Homeurl+solution.SolutionImg}}" alt="" style="width:100%;height:100%;"></a>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <div class="panel" id="three-panel">
                    <div class="panel-title"></div>
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="row">
                                <div class="col-lg-4" *ngFor="let youtube of YoutubeVideo">
                                    <iframe [src]="youtube.YoutubeLink" type="text/html" allowfullscreen width="100%" height="360" frameborder="0"></iframe>

                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <div class="panel" id="four-panel">
                    <div class="panel-title"></div>
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="row image-grid">
                                <div class="image-container" *ngFor="let Info of InfoData">

                                    <a href="{{Homeurl+Info.InfoImg}}" target="_blank"> <img src="{{Homeurl+Info.InfoImg}}" alt="" style="width:100%;height:100%;" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="panel" id="five-panel">
                    <div class="panel-title"></div>
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="row image-grid">
                                <div class="image-container" *ngFor="let Schedule of SheduleData">

                                    <a href="{{Homeurl+Schedule.ScheduleImg}}" target="_blank">  <img src="{{Homeurl+Schedule.ScheduleImg}}" style="width:100%;height:100%;" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="panel" id="six-panel">
                    <div class="panel-title"></div>
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="row image-grid">
                                <div class="image-container" *ngFor="let item of QRimages">
                                    <a href="{{Homeurl+item.QRImage}}" target="_blank">
                                        <img src="{{Homeurl+item.QRImage}}" style="width:100%;height:100%;" />
                                    </a>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>

</div>

<div *ngIf="spinner" class="loading-indicator">
    <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
</div>
<br />

<style>
    /*-------------Loader styles-------------*/
    .loading-indicator {
        position: fixed;
        z-index: 999;
        height: 2em;
        width: 2em;
        overflow: show;
        margin: auto;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }

        .loading-indicator:before {
            content: '';
            display: block;
            position: fixed;
            top: 0;
            left: 0;
            width: 200%;
            height: 100%;
            background-color: rgba(0,0,0,0.53);
        }
    /*-------------Loader styles-------------*/

</style>


<div class="home-slides">
    <owl-carousel-o [options]="homeSlides">

        <ng-template carouselSlide>
            <div class="main-banner" (click)="banners(2)">
                <div style="cursor:pointer">
                    <img src="../../../assets/img/Banners/New/owner2.jpeg" class="banner_images" alt="yklabs" />
                    <div class="center1">

                    </div>
                </div>

            </div>
        </ng-template>

        <ng-template carouselSlide>
            <div class="main-banner " (click)="banners(5)">
                <div style="cursor:pointer">
                    <img src="../../../assets/img/Banners/New/vayu-_3_.webp" class="banner_images" alt="yklabs" />
                    <div class="center1">
                        <h1 class="witha">Our chilli plants are grown in a healthy, pesticide-free environment, so you can enjoy their full flavour and aroma.</h1>

                    </div>

                </div>
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="container">
                            <div class="main-banner-text">

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>



        <ng-template carouselSlide>
            <div class="main-banner " (click)="banners(26)">
                <div style="cursor:pointer">

                    <img src="../../../assets/img/Banners/s_slider_3.jpg" class="img-fluid" alt="yklabs" />


                </div>

            </div>
        </ng-template>

        <ng-template carouselSlide>
            <div class="main-banner ">
                <div>
                    <img src="../../../assets/img/Banners/New/facebook.webp" class="banner_images" alt="yklabs" />
                </div>

            </div>
        </ng-template>

        <ng-template carouselSlide>
            <div class="main-banner" (click)="banners(2)">
                <div style="cursor:pointer">
                    <img src="../../../assets/img/Banners/New/papaya(1).jpeg" class="banner_images" alt="yklabs" />
                    <div class="center1">

                    </div>
                </div>

            </div>
        </ng-template>

        <ng-template carouselSlide>
            <div class="main-banner " (click)="banners(26)">
                <div style="cursor:pointer">

                    <img src="../../../assets/img/Banners/cotton.webp" class="banner_images" alt="yklabs" />
                    <div class="center1">
                        <h1 class="withc fontt"> "OXYVEG cotton special" Effectivel in : Thrips, Mealy bug, Whitefly, Aphids, Jassids, Root Weevils, Plant hoppers, White Gurbs, Larvae and other sucking pest, explore our website for more "Cotton" farming solutions.</h1>
                    </div>
                </div>

            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="main-banner ">
                <div>

                    <img src="../../../assets/img/Banners/1.jpeg" class="banner_images" alt="yklabs" />
                    <div class="center1">
                        <div>
                            <p class="witha11">
                                We promise our organic Pesticides keep your crops safe from pests, fungus, and harmful bacteria.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="main-banner " (click)="banners(4)">
                <div style="cursor:pointer">
                    <img src="../../../assets/img/Banners/New/crop-protection-banner.webp" class="banner_images" alt="yklabs" />
                    <div class="center1">
                        <h6 class="witha"> Fost Rootex is a natural fertilizer that helps your tomatoes grow bigger, better, and more flavourful.</h6>
                    </div>
                </div>

            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="main-banner " (click)="banners(2)">
                <div style="cursor:pointer">
                    <img src="../../../assets/img/Banners/cap.jpg" class="banner_images" alt="yklabs" />
                    <div class="center1">
                        <h1 class="withb mb-5">"HI-RICH organic manure" Best Supplements and Alternative for N-P-K chemical Fertilizers, and wile "AROGYA Flower & Fruit Setting" arogya enables the plant will keep always very Greenish and fresh condition, explore our website for more "Capsicum" farming solutions.</h1>
                    </div>
                </div>

            </div>
        </ng-template>
        <ng-template carouselSlide>

            <div class="main-banner ">
                <div>

                    <img src="../../../assets/img/bannnn.PNG" class="banner_images" alt="yklabs" />
                    <div class="center1">
                        <h1 class="witha"> </h1>
                    </div>
                </div>

            </div>
        </ng-template>

        <ng-template carouselSlide>
            <div class="main-banner ">
                <div>
                    <img src="../../../assets/img/Banners/New/all.webp" class="banner_images" alt="yklabs" />
                    <div class="center1">
                        <h1 class="witha">YK Laboratories Certified Organic Inputs for Healthy, Sustainable Crops</h1>
                    </div>
                </div>
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="container">
                            <div class="main-banner-text">

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>

    </owl-carousel-o>
</div>


<!-- About Start -->
<div class="container-xxl py-5 ">
    <div class="container mpb mbt-15">

        <p class="f18 mb-4 pp">
            Welcome to the YK Laboratories blog! Under the expert leadership of CEO L.Venkata Ramana Rayudu, we are excited to introduce a revolutionary product designed to transform your agricultural practices: Organic YK Vaayu Yantra Granules. Crafted with precision and care, these granules offer a natural, effective solution for managing paddy stem borers, leaf rollers, and other unwanted pests.
        </p>

        <p class="section-title bg-white text-start colorr fs-3 pe-3">
            YK Vaayu Yantra Granules
        </p>
        <p class="colorlogo">
            Unlock the Power of Organic Pest Control with YK Vaayu Yantra Granules
        </p>
        <div class="row">
            <div class="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
                <a href=" https://amzn.in/d/56cd9qi" target="_blank"><img src="../../../assets/img/Banners/VYOrganicGranules.webp" alt="yklabs"></a>
            </div>
            <div class="col-lg-6 mt-5 wow fadeIn" data-wow-delay="0.5s">
                <p class="f18 mmt">
                    YK Vaayu Yantra Granules are an advanced, organic solution for pest control in paddy fields.<br />
                    Engineered to address the challenges posed by pests such as stem borers and leaf rollers, these granules deliver a unique approach to managing agricultural pests. <br>
                    Their formulation ensures a safe, eco-friendly alternative to chemical pesticides.
                </p>
                <a href=" https://amzn.in/d/56cd9qi" target="_blank"><img src="../../../../assets/img/NewImages/amazon-logo.png" alt="yklabs" class="amazon"></a>
                <p class="colorlogo">Buy in Amazon</p>
                <!-- HTML !-->
                <a href="tel:+9705129639" class="button-29" role="button"><i class="fa fa-phone "> 9705129639 </i> </a>
            </div>

        </div>
        <br />
        <div class="row g-5 align-items-center">

            <div class="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                <h3 class="mmt2">
                    How Do They Work?
                </h3>
                <p class="f18">
                    The innovative formula of YK Vaayu Yantra Granules sets them apart. These granules release natural repellent vapors slowly over time, creating a protective barrier against a wide range of pests, including moths and other flies. This gradual release mechanism ensures continuous protection, making it highly effective in controlling pests and maintaining crop health.

                </p>

            </div>
            <div class="col-lg-6 wow fadeIn imgcontainer" data-wow-delay="0.1s">
                <img class="img1" src="../../../assets/img/Banners/vy2.webp" alt="yklabs">
            </div>

        </div>
        <div class="row g-5 mt-3 align-items-center">

            <div class="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
                <img class="imgs" src="../../../assets/img/Banners/vy3.webp" alt="yklabs">
            </div>
            <div class="col-lg-6  wow fadeIn" data-wow-delay="0.5s">
                <h3>
                    Key Benefits
                </h3>
                <ul>
                    <li>
                        1. <b>*Effective Pest Control*: </b> YK Vaayu Yantra Granules target and repel paddy stem borers, leaf rollers, and other flies, significantly reducing pest damage and crop loss.
                    </li>
                    <li>
                        2. <b>*Promotes Healthy Growth*: </b> Beyond pest control, these granules encourage the emergence of new tillers, fostering robust plant development and vibrant green foliage.
                    </li>
                    <li>
                        3. <b>*Long-Lasting Protection*: </b> The granules provide sustained effectiveness, maintaining a protective barrier for up to 25 days, ensuring your crops remain healthy and well-protected.
                    </li>
                    <li>
                        4. <b>*Eco-Friendly Solution*: </b> As an organic product, YK Vaayu Yantra Granules are designed with environmental responsibility in mind. They offer a safe, natural alternative to synthetic chemicals, helping to preserve ecological balance.
                    </li>
                </ul>

            </div>

        </div>
        <div class="row mt-2 g-5 align-items-center">


            <div class="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                <!--<h1 class="display-5 mb-4">Under the expert leadership of </h1>-->
                <h3>Application Tips</h3>
                <p class="f18">To get the best results from YK Vaayu Yantra Granules, follow these application guidelines:</p>
                <ul>
                    <li>
                        1. <b>*Timing*:</b> Apply the granules at the start of the growing season or when pest activity is first noticed.
                    </li>
                    <li>
                        2. <b>*Distribution*:</b>   Spread the granules evenly across the soil to ensure consistent coverage and effective repellent action.
                    </li>
                    <li>
                        3. <b>*Frequency*:</b>   Reapply as needed based on pest pressure and growth stage, keeping in mind that the granules provide effective protection for up to 25 days.
                    </li>
                </ul>
            </div>
            <div class="col-lg-4 wow fadeIn" data-wow-delay="0.1s">
                <img class="img1" src="../../../assets/img/Banners/vy4.webp" alt="yklabs">
            </div>
        </div>


        <div class="hero">
            <div class="container ">

                <h1 class="mt-1 ceoo">Under the expert leadership of</h1>
                <p class="colorlogo f18">CEO L.Venkata Ramana Rayudu</p>
                <div class="row g-5 mt-1 align-items-center">
                    <div class="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
                        <div class="about-img position-relative overflow-hidden " style="margin-left: -13px;">
                            <img class="img-fluid w-100" src="../../../assets/img/Banners/New/owner2.webp" alt="ceo">
                        </div>
                    </div>
                    <div class="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                        <!--<h1 class="display-5 mb-4">Under the expert leadership of </h1>-->
                        <h3 class=" text-start">The Vision of Venkata Ramana Rayudu</h3>
                        <p class="f18 text-start">Under the stewardship of CEO Venkata Ramana Raidu, YK Laboratories has remained at the forefront of agricultural innovation. His commitment to combining organic principles with cutting-edge technology has resulted in products like the YK Vaayu Yantra Granules, which reflect a dedication to sustainability and effectiveness. Venkata Ramana Raidu’s vision is clear: to provide farmers with tools that support both their crops and the environment.</p>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- About End -->
<!-- Video Start -->
<div class="container-fluid ">
    <div class="container">
        <h3 class=" text-center mb-3">Our Video</h3>
        <!--<div class="  text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s">

        </div>-->
        <div class="row">
            <!--<div class="col-lg-12 col-md-12 wow fadeInUp" data-wow-delay="0.1s" >
                <iframe  src="https://www.youtube.com/embed/akxxH4NDG9c?autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>-->
            <div class="video-wrapper">
                <iframe src="https://www.youtube.com/embed/akxxH4NDG9c?autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
        </div>
    </div>
</div>


<div class="container mp">
    <p class="section-title  bg-white text-start colorr fs-3 mt-5 mb-2 ">
        NHRDF CERTIFICATE
    </p>
    <!--<div class="section-title">
        <h2>YK Laboratories <span>Awards</span></h2>
    </div>-->
    <div class="row">
        <div class="col-lg-3 col-xs-6">
            <div class="card_imgg">
                <div class="image-area">
                    <div class="img-wrapper">
                        <a href="https://yklabsadmin.breakingindiaapp.com/assets/PDF/Vaayuu-Yantra.pdf" target="_blank">
                            <img src="../../../../assets/img/NewImages/vayulgo.png" alt="certificate" />

                            <!--<img src="../../../../assets/img/Awards/New/ITCLimited.webp" alt="yklabs" />-->
                            <h2 style="cursor:pointer">NHRDF CERTIFICATE </h2>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-9 col-xs-6"></div>
        <div class="col-lg-3">
            <br />
            <div><a routerLink="/awards"><i class="fa fa-arrow-right" style="color:purple;float:right;font-size:20px;"></i></a></div>
        </div>
    </div>
    <br />
</div>

<!-- Video End -->
<!-- Blog Start -->
<!-- Blog End -->



<style>
    .blog-img-container {
        width: 100%;
        height: 200px; /* Adjust the height as needed */
        overflow: hidden; /* Ensures the image doesn’t overflow the container */
        position: relative;
    }

        .blog-img-container img {
            width: 100%;
            height: 100%;
            object-fit: cover; /* Ensures the image covers the container while maintaining its aspect ratio */
            object-position: center; /* Centers the image within the container */
        }

    .gallery-card {
        display: flex;
        flex-direction: column;
        border-radius: 8px; /* Optional: Rounded corners */
        overflow: hidden; /* Prevents content from overflowing */
        transition: border 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for border and shadow */
        border: 1px solid transparent; /* Initial border state is invisible */
        height: auto; /* Allow the card height to adjust based on content */
    }

        .gallery-card:hover {
            border: 1px solid #ddd; /* Border only appears on hover */
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Add shadow for a subtle effect */
        }

    .gallery-img-container {
        width: 100%;
        height: 200px; /* Set a fixed height for the image container */
        overflow: hidden; /* Hide any overflow */
    }

        .gallery-img-container img {
            width: 100%;
            height: 100%;
            object-fit: cover; /* Ensure the image covers the container */
        }

    .gallery-content {
        padding: 16px; /* Adjust padding as needed */
        box-sizing: border-box; /* Ensures padding is included in the height */
        text-align: center; /* Center the text */
        white-space: nowrap; /* Prevents text from wrapping to the next line */
        overflow: hidden; /* Hides any overflowing text */
        text-overflow: ellipsis; /* Adds "..." for overflowed text */
        display: block; /* Ensures the content is treated as a block element */
    }
</style>

    import { Component, OnInit } from '@angular/core';
import { Router, NavigationCancel, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { TranslateService } from '@ngx-translate/core'
import { filter } from 'rxjs/operators';
declare let $: any;
declare var fbq: Function; // Declare fbq function
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class AppComponent {
    location: any;
    routerSubscription: any;

    constructor(private router: Router, public translate: TranslateService) {

        translate.addLangs(['en', 'de', 'fr', 'ru', 'tr']);
        translate.setDefaultLang('en');
        translate.use('en');

        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                fbq('track', 'PageView'); // Track page view
            }
        });


    }

    ngOnInit(){
        this.recallJsFuntions();
    }

    recallJsFuntions() {
        this.routerSubscription = this.router.events
        .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
        .subscribe(event => {
            this.location = this.router.url;
            if (!(event instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0);
        });
    }



}


<div class="main-banner item-bg-one">
    <div class="d-table">
        <div class="breadcrumb">
            <div class="container">
                <ul>
                    <li><a href="/">Home</a></li>
                    <li><a href="/">Registration</a></li>
                </ul>
                <h2 style="  color: white">Registration</h2>
            </div>

        </div>
    </div>
</div>
<div class="container">
    <div class="register">
        <div class="row">
            <div class="col-lg-4 register-left">
                <h3 class="fontt">Welcome <br />To</h3>
                <div class="transition"><h2 style="color:white">YK-Laboratories</h2></div>
                <!--<input type="submit" name="" value="Login" routerLink="/login" class="reg_1" /><br />-->
            </div>

            <!--<div class="user_options-forms" id="user_options-forms">
                <div class="user_forms-login">
                    <h2 class="forms_title">Registration</h2>
                    <form class="forms_form">
                        <fieldset class="forms_fieldset">
                            <div class="forms_field">
                                <input type="text" placeholder="Full Name" class="forms_field-input" required />
                            </div>
                            <div class="forms_field">
                                <input type="email" placeholder="Mobile Number" class="forms_field-input" required />
                            </div>
                            <div class="forms_field">
                                <input type="password" placeholder="Password" class="forms_field-input" required />
                            </div>
                            <div class="forms_field">
                                <input type="password" placeholder="Confirm Password" class="forms_field-input" required />
                            </div>
                        </fieldset>
                        <div class="forms_buttons">
                            <input type="submit" value="Submit" class="forms_buttons-action">
                        </div>
                    </form>
                </div>
            </div>-->


            <div class="col-lg-8 register-right">
                <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                        <h3 class="register-heading fontt">Registration</h3>
                        <div class="row register-form">
                            <form [formGroup]="form" (ngSubmit)="onSubmit(form.value)">
                                <div class="col-lg-6">
                                    <div class="form-group">

                                        <input [formControl]="name" class="form-control validation-field"
                                               placeholder="Full Name" type="text">
                                        <small class="text-danger" *ngIf="form.get('name').touched">
                                            <span *ngIf="form.get('name').hasError('required')">Full Name is required</span>
                                            <span *ngIf="form.get('name').hasError('minlength')">Minimum of 3 characters</span>
                                        </small>
                                    </div>

                                    <div class="form-group">
                                        <input [formControl]="mobile" class="form-control validation-field"
                                               placeholder="Mobile number" type="text" maxlength="10">
                                        <small class="text-danger" *ngIf="form.get('name').touched">
                                            <span *ngIf="form.get('mobile').hasError('required')">mobile number is required</span>
                                            <span *ngIf="form.get('mobile').hasError('minlength')">please enter 10 digits mobile number</span>
                                        </small>
                                    </div>
                                   
                                    <div class="form-group">
                                        <input [formControl]="password" class="form-control validation-field"
                                               placeholder="Password" type="password" minlength="6">
                                        <small class="text-danger" *ngIf="form.get('password').touched">
                                            <span *ngIf="form.get('password').hasError('required')">Password is required</span>
                                            <span *ngIf="form.get('password').hasError('minlength')">Password isn't long enough, minimum of 6 characters</span>
                                        </small>
                                    </div>

                                    <div class="form-group">
                                        <input [formControl]="confirmPassword" class="form-control validation-field"
                                               placeholder="Confirm Password" type="password">
                                        <small class="text-danger" *ngIf="form.get('confirmPassword').touched">
                                            <span *ngIf="form.get('confirmPassword').hasError('required')">Confirm Password is required</span>
                                            <span *ngIf="form.get('confirmPassword').hasError('mismatchedPasswords')">Passwords do not match</span>
                                        </small>
                                    </div>

                                    <div class="form-group">

                                        <input [formControl]="Address" class="form-control validation-field"
                                               placeholder="Address is required" type="text">
                                        <small class="text-danger" *ngIf="form.get('Address').touched">
                                            <span *ngIf="form.get('Address').hasError('required')">Address is required</span>
                                            <span *ngIf="form.get('Address').hasError('minlength')">Minimum of 6 characters</span>
                                        </small>
                                    </div>


                                </div>
                                <div class="col-lg-6">
                                    <img src="../../../assets/img/New Images/logo_1.png" class="logo" alt=""/>
                                    <!--<img src="../../../assets/lawyersimages/logo-removebg-preview.png" style="margin-left: 380px; margin-top: -380px;" />-->
                                    <button class="btnRegister" type="submit"
                                            value="Register">
                                        Register
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    </div>
</div>

<style>


    .register {
        background: linear-gradient(283deg, rgb(161 47 151 / 58%) 0%, rgb(181 12 98) 100%);
        margin-top: 3%;
        padding: 3%;
        margin-bottom: 3%;
        overflow: hidden;
    }

    h3 {
        font-weight: 600;
        font-style: italic;
        color: #fdf8f6;
        word-break: break-word;
        font-size: 40px;
    }

    .register .register-form {
        padding: 10%;
        margin-top: 10%;
    }

    @media (max-width: 991px) {
        .register .register-form {
            margin-top: 16%;
        }
    }

    @media (max-width: 667px) {
        .register .register-form {
            margin-top: 20%;
        }
    }

    .register .nav-tabs {
        margin-top: 3%;
        border: none;
        background: #0062cc;
        border-radius: 1.5rem;
        width: 100%;
        float: right;
    }

    @media (max-width: 991px) {
        .register .nav-tabs {
            width: 100%;
            margin-top: 8%;
        }
    }

    .register .nav-tabs .nav-link {
        padding: 2%;
        height: 34px;
        font-weight: 600;
        color: #fff;
        border-top-right-radius: 1.5rem;
        border-bottom-right-radius: 1.5rem;
    }

        .register .nav-tabs .nav-link:hover {
            border: none;
        }

        .register .nav-tabs .nav-link.active {
            width: 100px;
            color: #0062cc;
            border: 2px solid #0062cc;
            border-top-left-radius: 1.5rem;
            border-bottom-left-radius: 1.5rem;
        }

    .register-left {
        text-align: center;
        color: #fff;
        margin-top: 11%;
    }

        .register-left input {
            border: none;
            border-radius: 0.5rem;
            padding: 2%;
            width: 87%;
            background: #f8f9fa;
            font-weight: bold;
            color: #383d41;
            margin-top: 30%;
            margin-bottom: 3%;
            cursor: pointer;
        }

        .register-left img {
            margin-top: 15%;
            margin-bottom: 5%;
            width: 40%;
            animation: mover 1s infinite alternate;
        }

        .register-left p {
            padding: 12%;
            margin-top: -9%;
            color: white;
            font-weight: unset;
        }

    .register-right {
        background: #f8f9fa;
        border-top-left-radius: 10% 50%;
        border-bottom-left-radius: 10% 50%;
    }

    @-webkit-keyframes mover {
        0% {
            transform: translateY(0);
        }

        100% {
            transform: translateY(-20px);
        }
    }

    @keyframes mover {
        0% {
            transform: translateY(0);
        }

        100% {
            transform: translateY(-20px);
        }
    }

    .logo {
        width: 64%;
        margin-left: 122%;
        margin-top: -77%;
    }

    .btnRegister {
        float: right;
        margin-top: 9%;
        border: none;
        border-radius: 0.5rem;
        padding: 2%;
        background: #ff3cc3;
        color: #fff;
        font-weight: 600;
        width: 66%;
        cursor: pointer;
    }

    .register-heading {
        text-align: center;
        margin-left: -250px;
        margin-top: 8%;
        margin-bottom: -15%;
        color: #495057;
    }

    @media (max-width: 991px) {
        .register-heading {
            font-size: 1.5rem;
            margin-left: 5%;
        }

        .reg_1 {
            margin-top: -12%;
        }

        .register-left input {
            border: none;
            border-radius: 0.5rem;
            padding: 2%;
            width: 87%;
            background: #f8f9fa;
            font-weight: bold;
            color: #383d41;
            margin-top: 1%;
            margin-bottom: 3%;
            cursor: pointer;
        }
    }
</style>
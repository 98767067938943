import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { GeneralService } from '../../Services/generalservice.service';
import { FormGroup, FormsModule } from '@angular/forms'
import { map, throttle, window } from 'rxjs/operators';
import { HttpClient, HttpHandler, HttpResponse, HttpRequest, HttpHeaders } from '@angular/common/http';
import Swal from 'sweetalert2';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
@Component({
    selector: 'app-product-details',
    templateUrl: './product-details.component.html',
    styleUrls: ['./product-details.component.scss']
})
export class ProductDetailsComponent implements OnInit {
    AmazonURL: any; Homeurl: any; productDetails: any; selectedPackage: any;
    colorchange: boolean = false;
    colorchange1: boolean = false;
    colorchange2: boolean = false;
    colorchange3: boolean = false;

    //  private apiUrl = 'http://localhost:54429/api/LawyerChoices/MakePaymentRequest'; // Replace with your actual API endpoint URL

    private xVerifyHeaderValue = '1495f070ba4deefbe9d5a8bcb0610050e2073d72ca847cde13eeb02dd576a4d7###1';
    Title: any;
    CategoryName: string;
    Price: any;
    LoginDetails: any;
    productid: any;
    Ingredients: any;
    ProductImages: any; Price4: any; Price3: any; Price2: any; Price1: any;
    Pack4: any; Pack3: any; Pack2: any; Pack1: any; AllPacks: any; Preventive: any;
    Usages: any; Curative: any; Composition: any; Benefits: any;
    Dripirrigation: any; Dosage: any; RecommendedCrops: any; ProductInfo: any;
    arr: any = []; pack: any; categorizedProducts: any = []; Products: any;
    info: any; Products123: any;
    SubProducts: any;
    SubCatProducts: any;
    ProductID: any; QRCode: any;
    spinner: any; Pdf: any;
    prodvideo: any; YoutubeLink: any; videourl: any;
    ProductName123: any; productname: string; ykmarturl: any;
    selectedTab: string = '';
    constructor(private http: HttpClient, public route: ActivatedRoute, public generalService: GeneralService
        , public router: Router, private domSanitizer: DomSanitizer) {

        this.LoginDetails = JSON.parse(sessionStorage.getItem("LoginDetails"));
        this.Homeurl = 'https://www.yklaboratories.com/webservices/';
        this.ProductID = this.route.snapshot.paramMap.get("ProductID")




    }
    ngOnInit(): void {
        debugger

        this.GetProducts();
        setInterval(() => {
            this.onRefresh(event);
            this.productname = this.route.snapshot.paramMap.get("productname");
            //this.CategoryName = this.route.snapshot.paramMap.get("CategoryName");
        }, 1000);

    }

    onRefresh(event) {

        setTimeout(() => {
            this.getProductInformation();
            if (event !== null) {
                event.target.complete();
            }
        }, 1000)
    }

    selectTab(tab: string) {
        this.selectedTab = tab;
    }

    AddtoCart(productDetails, Price) {


    }

    selectPackage(packageName: string) {
        debugger
        this.selectedPackage = packageName;
    }


    GotoMail(title: any) {
        if (!this.selectedPackage) {
            this.generalService.ShowAlert('Warning', 'Please select a package before placing enquiry', 'warning');
            return;
        }
        this.router.navigate(['/mailEnquiry', { ProdName: title, Package: this.selectedPackage }]);
    }
    CropguidelinesId
    getProductInformation() {
        debugger
        this.spinner = true;
        this.colorchange = false;
        this.colorchange1 = false;
        this.colorchange2 = false;
        this.colorchange3 = false;
        var UploadFile = new FormData();
        UploadFile.append("Param", this.productname);
        var url = "api/YKLabs/YK_NEW_GetAllCategoryWiseProducts";
        this.generalService.PostData(url, UploadFile).then((data: any) => {
            debugger
            console.log("API-RESPONSE", data);
            console.log("PRODUCTNAME", data.Products[0].productname);
            console.log("PRODUCT-IMAGE", data.ProductImages[0].Image);
            this.productDetails = data.Products[0];
            this.Title = this.productDetails.productname;
            this.CategoryName = this.productDetails.CategoryName;
            this.Price = this.productDetails.Price1;
            this.ProductInfo = this.productDetails.ProductInfo;
            this.RecommendedCrops = this.productDetails.RecommendedCrops;
            this.Dosage = this.productDetails.Dosage;
            this.Dripirrigation = this.productDetails.Dripirrigation;
            this.Benefits = this.productDetails.Benefits;
            this.Composition = this.productDetails.Composition;
            this.Curative = this.productDetails.Curative;
            this.Usages = this.productDetails.Usages;
            this.Preventive = this.productDetails.Preventive;
            this.colorchange = true;
            this.AllPacks = this.productDetails
            this.Pack1 = this.productDetails.Pack1;
            this.Pack2 = this.productDetails.Pack2;
            this.Pack3 = this.productDetails.Pack3;
            this.Pack4 = this.productDetails.Pack4;
            this.Price1 = this.productDetails.Price1;
            this.Price2 = this.productDetails.Price2;
            this.Price3 = this.productDetails.Price3;
            this.Price4 = this.productDetails.Price4;
            this.Ingredients = this.productDetails.Ingredients;
            this.AmazonURL = this.productDetails.AmazonURL;
            this.QRCode = this.productDetails.QRCode;
            this.Pdf = this.productDetails.Pdf;
            this.ProductImages = data.ProductImages[0].Image;
            this.ykmarturl = this.productDetails.YKMartURL;

            document.getElementById('nav-home-tab').click();
        });
        this.spinner = false;
    }

    GetSubProducts(val: any) {
        this.spinner = true;
        this.arr = [];
        this.arr.push({
            UserID: 1,
            Token: '1A7FE1ED-28F2-4E71-9321-D376ADB9D75E',
        });

        var UploadFile = new FormData();
        UploadFile.append('Param', JSON.stringify(this.arr));
        const subProductsURL = 'api/YKLabs/YK_NEW_GetAllCategoryProducts';
        this.generalService.PostData(subProductsURL, UploadFile).then(
            (data: any) => {
                this.SubProducts = data;
                this.SubCatProducts = this.SubProducts.filter((s: any) => s.CategoryId == val);

            },
            (err) => {
                alert(" Some thing went wrong")
            });
        this.spinner = false;
    }


    GetProducts() {
        this.spinner = true;
        if (!this.Products123) {
            this.arr = [];
            this.arr.push({
                UserID: 1,
                Token: '1A7FE1ED-28F2-4E71-9321-D376ADB9D75E',
            });
            var UploadFile = new FormData();
            UploadFile.append('Param', JSON.stringify(this.arr));
            const URL = 'api/YKLabs/YK_NEW_GetProductCategories';
            this.generalService.PostData(URL, UploadFile).then((data: any) => {
                //   debugger
                this.Products123 = [];
                this.Products123 = data;
            },
                (err) => {
                    alert(" data is not getting")
                }
            );
        }
        this.spinner = false;
    }

    GetProducVideo() {
        debugger
        var uploadfile = new FormData();
        uploadfile.append('Param1', this.ProductID);
        var url = 'api/YKLabs/YK_NEW_GetProductVideo';
        this.generalService.PostData(url, uploadfile).then((data: any) => {
            console.log("youtube data", data);
            debugger
            this.prodvideo = data;
            // this.YoutubeLink = this.prodvideo[0].YoutubeLink;
            this.YoutubeLink = this.GetYoutubeUrl(this.prodvideo[0].YoutubeLink)
            // this.GetYoutubeUrl(this.YoutubeLink);
        },
            (err) => {
                alert("data is not getting")
            });
    }


    GetYoutubeUrl(url) {
        debugger
        return this.domSanitizer.bypassSecurityTrustResourceUrl(url);

    }

    ChangePrice(pack) {
        //  debugger
        this.colorchange = false;
        this.colorchange1 = false;
        this.colorchange2 = false;
        this.colorchange3 = false;
        switch (pack) {
            case 1: {
                this.Price = this.Price1;
                this.pack = this.Pack1;
                this.colorchange = true;
                this.colorchange1 = false;
                this.colorchange2 = false;
                this.colorchange3 = false;
                break;
            }
            case 2: {
                this.Price = this.Price2;
                this.pack = this.Pack2;
                this.colorchange1 = true;
                this.colorchange = false;
                this.colorchange2 = false;
                this.colorchange3 = false;
                break;
            }
            case 3: {
                this.Price = this.Price3;
                this.pack = this.Pack3;
                this.colorchange2 = true;
                this.colorchange1 = false;
                this.colorchange = false;
                this.colorchange3 = false;
                break;
            }
            case 4: {
                this.Price = this.Price4;
                this.pack = this.Pack4;
                this.colorchange3 = true;
                this.colorchange1 = false;
                this.colorchange2 = false;
                this.colorchange = false;
                break;
            }
        }
    }

    filterProducts(Categoryid: any) {
        ////  debugger
        this.categorizedProducts = [];
        this.categorizedProducts = this.Products.filter(a => a.CategoryId == Categoryid);
    }

    saveData(value) {
        //  debugger
        this.info = '';

        //  = value;
    }


    makePayment() {
        const apiUrl = 'http://localhost:54429/api/LawyerChoices/MakePaymentRequest';
        const paymentRequest = {
            merchantId: 'SHIPPITONLINEUAT',
            merchantTransactionId: 'MT7850590068188104',
            merchantUserId: 'MUID123',
            amount: 5000000,
            redirectUrl: 'https://webhook.site/redirect-url',
            redirectMode: 'POST',
            callbackUrl: 'https://webhook.site/callback-url',
            mobileNumber: '9908202501',
            paymentInstrument: {
                type: 'PAY_PAGE'
            }
        };
        const saltKey = '11e44559-ed56-40c6-8da6-36597b574f46';
        const base64Payload = btoa(JSON.stringify(paymentRequest));
        const payload = base64Payload + '/pg/v1/pay' + saltKey;

        this.calculateSha256Hash(payload).then(res => {
            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'X-VERIFY': res,
                'accept': 'application/json'
            });

            this.http.post(apiUrl, paymentRequest, { headers }).subscribe(
                (response: any) => {
                    //  debugger
                    const redirectUrl = response.data.instrumentResponse.redirectInfo.url;
                    const token = this.extractTokenFromUrl(redirectUrl);
                    const dynamicUrl = `https://mercury-uat.phonepe.com/transact/simulator?token=${token}`;
                    //  window.location.href = dynamicUrl;

                    console.log('Payment successful');
                    console.log('Dynamic URL:', dynamicUrl);

                    // Handle the successful response
                },
                error => {
                    console.log('Payment failed');
                    // Handle the error response
                }
            );
        });
    }

    extractTokenFromUrl(url: string): string {
        const token = url.split('=')[1];
        return token;
    }

    calculateSha256Hash(payload) {
        const encoder = new TextEncoder();
        const data = encoder.encode(payload);
        return crypto.subtle.digest('SHA-256', data)
            .then(hashBuffer => {
                const hashArray = Array.from(new Uint8Array(hashBuffer));
                const hashHex = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');
                return hashHex + '###1';
            });
    }

    //ngAfterViewInit() {
    //    document.getElementById('preloader')?.classList.add('hide');
    //}




}

<div class="main-banner">
    <div class="d-table">
        <div class="breadcrumb">
            <div class="container">
                <ul>
                    <li><a href="/">Home</a></li>
                    <li><a href="/">Contact Us</a></li>
                </ul>
                <h2 style="  color: white">Contact Us</h2>
            </div>

        </div>
    </div>
</div>




<div id="contact" class="contact-area ptb-100 bg-F7F5F4">
    <div class="container">
        <div class="section-title">
            <h4> Just keep Get in Touch</h4>
        </div>
        <div class="row">
            <div class="col-lg-7 col-md-12">
                <div class="contact-form">


                    <form #contactForm="ngForm" (ngSubmit)="onSubmit(contactForm.value)">
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <label>Name</label>
                                    <input required minlength="3" maxlength="50" ngModel name="name" type="text" #name="ngModel" class="form-control" id="name">
                                    <div class="alert alert-danger" *ngIf="name.touched && !name.valid">
                                        <div *ngIf="name.errors.required">Name is required.</div>
                                        <div *ngIf="name.errors.minlength">Name is minimum {{ name.errors.minlength.requiredLength }} character.</div>
                                        <div *ngIf="name.errors.maxlength">Name is maximum 50 character.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <label>Email</label>
                                    <input required ngModel name="email" type="text" #email="ngModel" class="form-control" id="email">
                                    <div class="alert alert-danger" *ngIf="email.touched && !email.valid">Email Address is required.</div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <label>Subject</label>
                                    <input required ngModel name="subject" type="text" #subject="ngModel" class="form-control" id="subject">
                                    <div class="alert alert-danger" *ngIf="subject.touched && !subject.valid">Phone subject is required.</div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <label>Phone Number</label>
                                    <input required ngModel name="number" type="text" #number="ngModel" class="form-control" id="number">
                                    <div class="alert alert-danger" *ngIf="number.touched && !number.valid">Phone number is required.</div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3"><br />
                                    <label>Message</label>
                                    <textarea required ngModel #message="ngModel" name="message" id="message" cols="30" rows="6" class="form-control"></textarea>
                                    <div class="alert alert-danger" *ngIf="message.touched && !name.valid">Message is required.</div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12"><br />
                                <button type="submit" class="btn btn-primary" [class.disabled]="!contactForm.valid">Send Message</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-lg-5 col-md-12">
                <div class="contact-info">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <ul> 
                                <li>
    <B style="color:#212529">
    YK Laboratories manufacturing of certified organic inputs
</B> especially Botanical Virucide, Fungicide water base and Botanical Insecticide & Sucking pest oil base, with the aid of Medicinal plants and related alkaloids which controls and suppress the multiplication of Bacterial and Fungal growth.
</li>
                                <li>
                                    <a href="https://www.google.com/maps/search/+SY.NO.+255,+PLOT+NO.3,+EAST+PART,+KUNTLOOR+(V),+HAYATHNAGAR,+R.R.+DIST.+TELANGANA+-+501505.+INDIA./@17.3461177,78.6330206,17z/data=!3m1!4b1?entry=ttu"target="_blank">
                                        <i class="fas fa-map-marker-alt"></i>
                                        SY.NO. 255, PLOT NO.3, EAST PART, KUNTLOOR (V), HAYATHNAGAR, R.R. DIST. TELANGANA - 501505. INDIA.
                                    </a>
                                </li>

                                <li><i class="far fa-envelope"></i> 
                                    <a href="mailto:info@yklaboratories.com">
                                        <span>Email:</span>info@yklaboratories.com
                                    </a></li>
                                <li><i class="fas fa-phone-alt"></i>
                                    <a href="tel:+91 9705129639"><span>Call:</span>(+91) 970 512 9639  <br />(+91) 949 141 8181</a></li>
                                <!--<li><i class="fas fa-fax"></i> <a href="tel:61724160055"><span>Fax:</span>+617-241-60055</a></li>-->
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div> <br />
        <div class="row">
            <div class="col-md-12">

                <iframe src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d3808.3658975823073!2d78.63302057433985!3d17.34611770399435!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1s%20SY.NO.%20255%2C%20PLOT%20NO.3%2C%20EAST%20PART%2C%20KUNTLOOR%20(V)%2C%20HAYATHNAGAR%2C%20R.R.%20DIST.%20TELANGANA%20-%20501505.%20INDIA.!5e0!3m2!1sen!2sin!4v1689155092043!5m2!1sen!2sin" class="map" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </div>



    </div>
</div>

import { Component, OnInit } from '@angular/core';
import { GeneralService } from '../../Services/generalservice.service';
import { HttpClient, HttpHandler, HttpResponse, HttpRequest } from '@angular/common/http';

@Component({
  selector: 'app-rdnewsnew',
  templateUrl: './rdnewsnew.component.html',
  styleUrls: ['./rdnewsnew.component.scss']
})
export class RdnewsnewComponent implements OnInit {
    Homeurl: any;
    RandDNew: any;
    Newsimage: any;

    constructor(public generalservice: GeneralService, public http: HttpClient) {
      //  this.Homeurl = 'https://yklaboratories.com/webservices/';
        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.Homeurl = data.Webservice;
        });
    }

    ngOnInit(): void {

        this.GetRandDimages();

  }


    GetRandDimages() {
        debugger
        var uploadfile = new FormData();
        uploadfile.append('Param1', '1');
        var url = "api/YKLabs/Get_RDImages_based_On_Rollid";
        this.generalservice.PostData(url, uploadfile).then((data: any) => {
            this.RandDNew = data;
            this.Newsimage = this.RandDNew.ImagePaths
        },
            (err) => {

            });

    }

}

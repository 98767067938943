import { Component, OnInit } from '@angular/core';
import { GeneralService } from '../../Services/generalservice.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import Swal from 'sweetalert2';
import { from } from 'rxjs';

@Component({
  selector: 'app-onlineenquiry',
  templateUrl: './onlineenquiry.component.html',
  styleUrls: ['./onlineenquiry.component.scss']
})
export class OnlineenquiryComponent implements OnInit {
    EnquiryAry: any = [];
    formGroup: any;
    name: any;
    email: any;
    subject: any;
    number: any;
    message: any;
    city: any;
    Address: any;
    Area: any;
    Pincode: any;
    ProdName: any; Package: any;
    contactForm: any;
    admindata: any; customerName: any; productName: any;
    constructor(public generalService: GeneralService, public router: Router,
        public fb: FormBuilder, public route: ActivatedRoute) {
        debugger
        this.ProdName = this.route.snapshot.paramMap.get("ProdName");
        this.Package = this.route.snapshot.paramMap.get("Package");
        this.message = 'i want enquiry about this product ' + this.ProdName + this.Package
        if (this.Package) {
            this.message = 'I want enquiry about this product ' + this.ProdName + ' with package ' + this.Package;
        } else {
            this.message = 'I want enquiry about this product ' + this.ProdName;
        }
    }

    ngOnInit(): void {
        debugger
        this.GetAdminDetails();
    }


    GetAdminDetails() {
        debugger
        

        var UploadFile = new FormData();
        UploadFile.append('Param1', '3');

        const subProductsURL = 'api/Farmer/GetAdminDetailsForfarmer';
        this.generalService.PostData(subProductsURL, UploadFile).then((data: any) => {
           
            this.admindata = data;
            

        },
            (err) => {
                alert(" Some thing went wrong")
                //  $('#myModal2').modal('hide');

            }
        );
    }

    navigate() {
        
        this.router.navigate(['/'])
    }

    //start EnquiryMailTo_YKAdmin sending mail through db --manohar
    onSubmit(form: any) {
        debugger
        this.EnquiryAry = [];
        this.customerName = form.name;
        this.productName = this.ProdName + ' ' + this.Package
        this.EnquiryAry.push({
            FarmerName: form.name,
            Mobile: form.number,
            EmailID: form.email,
            Subject: form.subject,
            City: form.city,
            Address: form.Address,
            Area: form.Area,
            Pincode: form.Pincode,
            Message: form.message,
            Product: this.ProdName + ' ' + this.Package
        });

        const UploadFile = new FormData();
        UploadFile.append("Email", JSON.stringify(this.EnquiryAry));
        const url = "/api/YKLabs/ProductEnquiryMailTo_ykAdmin";

        this.generalService.PostData(url, UploadFile).then((data: any) => {
            Swal.fire(
                'SUCCESS',
                'Your Enquiry has been submitted successfully. Our Team will contact you soon.',
                'success'
            );
            this.clear();
            this.name = '';
            this.email = '';
            this.subject = '';
            this.number = '';
            this.message = '';
            window.location.reload();
       

            this.sendNotificationsToAdmins(this.customerName, this.productName); // Call the function to send notifications
        });
    }

    sendNotificationsToAdmins(customerName: any, productName: any) {
        debugger
        const message = customerName + ' ' + 'has initiated a new enquiry for' + ' ' + productName;
        const senderName = 'YK Labs';
        const path = " ";
        var Img = "";
         // Adjust the path if you have an image

        this.admindata.forEach(admin => {
            const deviceToken = admin.Token; // Adjust this according to your admin data structure

            const UploadFile = new FormData();
            UploadFile.append('deviceToken', deviceToken);
            UploadFile.append('message', message);
            UploadFile.append('senderName', senderName);
            UploadFile.append('path', '/tabs/tab1');
            UploadFile.append('Img', Img);

            const url = "api/Farmer/sendNotification";

            this.generalService.PostData(url, UploadFile).then((data: any) => {
                console.log(`Notification sent to ${admin.name}`); // Adjust this according to your admin data structure
            }).catch(err => {
                console.error(`Error sending notification to ${admin.name}:`, err); // Adjust this according to your admin data structure
            });
        });
    }
    // mail code end 

    clear() {
        debugger
        this.name = '';
        this.email = '';
        this.subject = '';
        this.number = '';
        this.city = '';
        this.Address = '';
        this.Area = '';
        this.Pincode = '';
        this.message = '';

    }

   
}

<div id="welcome" class="welcome-area pt-100 pb-70 wel_0">
    <div class="container">
        <div class="section-title">
            <h2>Our <span> Products</span></h2>
        </div><br />
        <!-- Carousel wrapper -->
        <div id="carouselMultiItemExample"
             class="carousel slide carousel-dark text-center"
             data-mdb-ride="carousel">

            <!-- Inner -->


            <div class="col-lg-12">
                <div class="row">

                    <div class="col-lg-2 col-md-6 col-sx-6" *ngFor="let product of SubProducts">
                        <div class="card12">
                            <a (click)="navigateToproduct(product.CategoryName,product.productname)">
                                <img src="{{Homeurl+product.Image}}" class="img_1" alt="" />
                                <div class="flow-name">{{product.productname}}</div>
                            </a><br />
                            <button class="enqui">
                                <a (click)="GotoMail(product.productname)">
                                    Place Enquiry
                                </a>
                            </button>
                        </div>
                    </div>
                </div>
            </div>


        </div>

        <br /><br />
        <div class="section-title">
            <h2>Our NHRDF<span> Reports</span></h2>
        </div>
        <div class="row">
            <div class="col-lg-3 col-xs-6">
                <div class="card ">
                    <img src="../../../../assets/img/Awards/New/Cureal-TestReport.webp" alt="YK Labs" />
                    <div class="text-content">
                        <a href="../../../../assets/img/Awards/curealtestreport.pdf" target="_blank">
                            <h2 class="title" style="cursor:pointer">Cureal-TestReport</h2>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-xs-6">
                <div class="card ">
                    <img src="../../../../assets/img/Awards/New/NanoSilver-TestReport.webp" alt="YK Labs" />

                    <div class="text-content">
                        <a href="../../../../assets/img/Awards/nanotestreport.pdf" target="_blank">
                            <h2 class="title" style="cursor:pointer">NanoSilver-TestReport</h2>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-xs-6">
                <div class="card ">
                    <img src="../../../../assets/img/Awards/New/Cyto-TestReport.webp" alt="YK Labs" />
                    <div class="text-content">
                        <a href="../../../../assets/img/Awards/cyto qrreport.pdf" target="_blank">
                            <h2 class="title" style="cursor:pointer">Cyto-TestReport</h2>
                        </a>

                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-xs-6">
                <div class="card ">
                    <img src="../../../../assets/img/Awards/New/Vaayu-TestReport.webp" alt="YK Labs" />

                    <div class="text-content">
                        <a href="../../../../assets/img/Awards/vautestreport.pdf" target="_blank">
                            <h2 class="title" style="cursor:pointer">Vaayu-TestReport</h2>
                        </a>
                    </div>
                </div>
            </div>
        </div><br />
              <div class="row">
                  <div class="col-lg-3 col-xs-6">
                      <div class="card ">
                          <img src="../../../../assets/NDRF_Rewports/Fost-Rootex.webp" alt="YK Labs" />
                          <div class="text-content">
                              <a href="../../../../assets/NDRF_Rewports/Foost-Rootex.pdf" target="_blank">
                                  <h2 class="title" style="cursor:pointer">Fost-TestReport</h2>
                              </a>
                          </div>
                      </div>
                  </div>
                  <div class="col-lg-3 col-xs-6">
                      <div class="card ">
                          <img src="../../../../assets/NDRF_Rewports/Oxvegqr.png" alt="YK Labs" />
                          <div class="text-content">
                              <a href="../../../../assets/NDRF_Rewports/Oxveg.pdf" target="_blank">
                                  <h2 class="title" style="cursor:pointer">Oxveg-TestReport</h2>
                              </a>
                          </div>
                      </div>
                  </div>
                  <div class="col-lg-3 col-xs-6">
                      <div class="card ">
                          <img src="../../../../assets/NDRF_Rewports/Yantraqr.png" alt="YK Labs" />
                          <div class="text-content">
                              <a href="../../../../assets/NDRF_Rewports/Yantra.pdf" target="_blank">
                                  <h2 class="title" style="cursor:pointer">Yantra-TestReport</h2>
                              </a>
                          </div>
                      </div>
                  </div>
                  <div class="col-lg-3 col-xs-6">
                      <div class="card ">
                          <img src="../../../../assets/NDRF_Rewports/YKArogyaTestReport.webp" alt="YK Labs" />
                          <div class="text-content">
                              <a href="../../../../assets/NDRF_Rewports/Arogya-TestReport.pdf" target="_blank">
                                  <h2 class="title" style="cursor:pointer">Arogya-TestReport</h2>
                              </a>
                          </div>
                      </div>
                  </div>
              </div><br />
                  <div class="row">
                      <div class="col-lg-3 col-xs-6">
                          <div class="card ">
                              <img src="../../../../assets/NDRF_Rewports/VYOrganiGranulesTestReport.webp" alt="YK Labs" />
                              <div class="text-content">
                                  <a href="../../../../assets/NDRF_Rewports/VaayuYantraOrganicGranules-TestReport.pdf" target="_blank">
                                      <h2 class="title" style="cursor:pointer">VaayuYantraOrganicGranules-TestReport</h2>
                                  </a>
                              </div>
                          </div>
                      </div>
                      <div class="col-lg-3 col-xs-6">
                          <div class="card ">
                              <img src="../../../../assets/NDRF_Rewports/YKStarTestReport.webp" alt="YK Labs" />
                              <div class="text-content">
                                  <a href="../../../../assets/NDRF_Rewports/YKStar-TestReport.pdf" target="_blank">
                                      <h2 class="title" style="cursor:pointer">YK-Star-TestReport</h2>
                                  </a>
                              </div>
                          </div>
                      </div>
                  </div>

            <br /> <br /> <br />
            <div class="section-title">
                <h2>YK Laboratories <span>Awards</span></h2>
            </div>
            <div class="row">
                <div class="col-lg-3 col-xs-6">
                    <div class="card_imgg">
                        <div class="image-area">
                            <div class="img-wrapper">
                                <a href="../../../../assets/NewImages/certificate1.jpeg" target="_blank">
                                    <img src="../../../../assets/NewImages/certificate.jpg" alt="certificate" />

                                    <!--<img src="../../../../assets/img/Awards/New/ITCLimited.webp" alt="yklabs" />-->
                                    <h2 style="cursor:pointer">CSIR-CIMAP </h2>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-xs-6">
                    <div class="card_imgg">
                        <div class="image-area">
                            <div class="img-wrapper">
                                <a href="../../../../assets/img/Awards/cytolabs1.pdf" target="_blank">
                                    <img src="../../../../assets/img/Awards/New/cyto-1.webp" alt="yklabs" />
                                    <h2 style="cursor:pointer">Cyto Labs</h2>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-xs-6">
                    <div class="card_imgg">
                        <div class="image-area ">
                            <div class="img-wrapper">
                                <a href="../../../../assets/img/Awards/bio.pdf" target="_blank">
                                    <img src="../../../../assets/img/Awards/New/bio-certificate.webp" alt="yklabs" />
                                    <h2 style="cursor:pointer">Bio-Certificate</h2>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-xs-6">
                    <div class="card_imgg">
                        <div class="image-area">
                            <div class="img-wrapper">
                                <a href="../../../../assets/img/Awards/Fost-Rootex.pdf" target="_blank">
                                    <img src="../../../../assets/img/Awards/New/fost-rootex.webp" alt="yklabs" />
                                    <h2 style="cursor:pointer">Cyto Labs</h2>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="col-lg-12">
                    <br />
                    <div><a routerLink="/awards"><i class="fa fa-arrow-right" style="color:purple;float:right;font-size:20px;"></i></a></div>
                </div>
            </div>



        </div>
</div>

<!--<div class="modal enter_0" id="myModal2" style="margin-top:9rem;">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header state modal-primary">
                <h4 style="text-align:left;" class="modal-title" id="modal-primary-label">Enter Mobile</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
            </div>
           
          
        </div>
    </div>
</div>-->

            <style>
                * {
                    box-sizing: border-box;
                }

                .img-zoom-container {
                    position: relative;
                }

                .img-zoom-lens {
                    position: absolute;
                    border: 1px solid #d4d4d4;
                    /*set the size of the lens:*/
                    width: 40px;
                    height: 40px;
                }

                .img-zoom-result {
                    border: 1px solid #d4d4d4;
                    /*set the size of the result div:*/
                    width: 300px;
                    height: 300px;
                }


                tr.break td {
                    height: 10px;
                }
            </style>



<section id="services" class="services-area  pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Our Products <span>schedule</span></h2>
        </div>
        <div class="row">
            <div class="col-lg-2 col-xs-6">
                <div class="card_a">
                    <article style="--i: 2; --cost: 29; --grad: #f7ea1f, #f87d2c" class="article">
                        <header>
                            <a href="../../../../assets/img/Schedule/PapayaSchedule2023 (1).pdf" target="_blank">
                                <img src="../../../../assets/img/Schedule/New/yklabspappayaschedule.webp" alt="YKLabs" style="width:100%;height: 250px;" />
                            </a>

                        </header><hr />
                        <section class="section">
                            <h3 class="h3">Papaya Schedule</h3>
                        </section>
                    </article>
                </div>
            </div>
            <div class="col-lg-2 col-xs-6">
                <div class="card_b">
                    <article style="--i: 1; --cost: 19; --grad: #f7256e, #cc0c48" class="article">
                        <header>
                            <a href="../../../../assets/img/Schedule/chilli schedule.pdf" target="_blank">
                                <img src="../../../../assets/img/Schedule/New/YklabsChillischedule.webp" alt="YKLabs" style="width:100%;height: 250px;" />
                            </a>
                        </header><hr />
                        <section class="section">
                            <h3 class="h3">Chilli Schedule</h3>
                        </section>
                    </article>
                </div>
            </div>
            <div class="col-lg-2 col-xs-6">
                <div class="card_c">
                    <article style="--i: 0; --cost: 9; --grad: #0fcf7b, #0c9f30" class="article">
                        <header class="">
                            <a href="../../../../assets/img/Schedule/Capsicum 2023 July.pdf" target="_blank">
                                <img src="../../../../assets/img/Schedule/New/YklabsCapsicumschedule.webp" alt="YKLabs" style="width:100%;height: 250px;" />
                            </a>

                        </header><hr />
                        <section class="section">
                            <h3 class="h3">Capsicum Schedule</h3>
                        </section>
                    </article>
                </div>
            </div>
            <div class="col-lg-2 col-xs-6">
                <div class="card_d">
                    <article style="--i: 2; --cost: 29; --grad: #f7ea1f, #f87d2c" class="article">
                        <header>
                            <a href="../../../../assets/img/Schedule/Tomato Schedule 2023.pdf" target="_blank">
                                <img src="../../../../assets/img/Schedule/YklabsTomatoschedule.jpeg" alt="YKLabs" style="width:100%;height: 250px;" />
                            </a>
                        </header><hr />
                        <section class="section">
                            <h3 class="h3">Tomato Schedule</h3>
                        </section>
                    </article>
                </div>
            </div>
            <div class="col-lg-2 col-xs-6">
                <div class="card_e">
                    <article style="--i: 2; --cost: 29; --grad: #f7ea1f, #f87d2c" class="article">
                        <header>
                            <a href="../../../../assets/img/Schedule/Cotton Schedule.pdf" target="_blank">
                                <img src="../../../../assets/img/Schedule/New/yklabscottonschedule.webp" alt="YKLabs" style="width:100%;height: 250px;" />
                            </a>
                        </header><hr />
                        <section class="section">
                            <h3 class="h3">Cotton Schedule</h3>
                        </section>
                    </article>
                </div>
            </div>
        </div>
    </div>
</section>


import { Component, OnInit } from '@angular/core';
import { GeneralService } from '../../Services/generalservice.service';
import { HttpClient, HttpHandler, HttpResponse, HttpRequest } from '@angular/common/http';

@Component({
  selector: 'app-rdpapaya',
  templateUrl: './rdpapaya.component.html',
  styleUrls: ['./rdpapaya.component.scss']
})
export class RdpapayaComponent implements OnInit {
    Homeurl: any;
   // RandDpapaya: any;
    papayaimage: any;
    RandDpapaya: any[] = [];
    currentIndex = 0;

    constructor(public generalservice: GeneralService, public http: HttpClient) {
      //  this.Homeurl = 'http://localhost:46812/';
        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.Homeurl = data.Webservice;
        });
    }

    ngOnInit(): void {
        this.GetRandDimages();
     
    }

    GetRandDimages() {
        debugger
        var uploadfile = new FormData();
        uploadfile.append('Param1', '3');
        var url = "api/YKLabs/Get_RDImages_based_On_Rollid";
        this.generalservice.PostData(url, uploadfile).then((data: any) => {
            debugger
            this.RandDpapaya = data;

            //this.papayaimage = [];
            //   // Loop through the data array and extract ImagePaths
            //for (let i = 0; i < this.RandDpapaya.length; i++) {
            //    this.papayaimage.push(this.RandDpapaya[i].ImagePaths);
            //}

        },
            (err) => {
                console.log('api error respones fro papaya',err)
            });

    }



}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { EmailValidators } from 'ngx-validators';
import { GeneralService } from '../../Services/generalservice.service';
import { CookieService } from 'ngx-cookie-service';
import { EncryptionService } from '../../Services/encryption.service';
declare var $

import Swal from 'sweetalert2';

@Component({
  selector: 'app-registrattion',
  templateUrl: './registrattion.component.html',
  styleUrls: ['./registrattion.component.scss']
})
export class RegistrattionComponent implements OnInit {
    public router: Router;
    public form: FormGroup;
    public name: AbstractControl;
    public mobile: AbstractControl;
    public email: AbstractControl;
    public password: AbstractControl;
    public confirmPassword: AbstractControl;
    public Address: AbstractControl;
    arr: any[];
    logindata: any;
    OTP: any;
    Mobile: any;
    randomnumber: any;
    Longitude: any;
    Lattitude: any;
    registrationForm: FormGroup; 


    constructor(router: Router, fb: FormBuilder, public generalService: GeneralService, private formBuilder: FormBuilder) {
        this.router = router;
        this.form = fb.group({
            name: ['', Validators.compose([Validators.required, Validators.minLength(3)])],
            mobile: ['', [Validators.required, Validators.minLength(10)]],
            password: ['', Validators.required],
            confirmPassword: ['', Validators.required],
            Address: ['', Validators.compose([Validators.required, Validators.minLength(6)])]
        }, { validator: matchingPasswords('password', 'confirmPassword') });

        this.name = this.form.controls['name'];
        this.mobile = this.form.controls['mobile'];
        this.password = this.form.controls['password'];
        this.confirmPassword = this.form.controls['confirmPassword'];
        this.Address = this.form.controls['Address'];
    } 

    ngOnInit(): void {

          }


    navigateToRoute() {
        debugger
        this.router.navigate(['/login']);
    }

    /* start registration */
     onSubmit(value: any) {
        debugger
        this.arr = []
        this.arr.push({
            //UserID: this.loginDet.UserID,
            //TokenID: this.loginDet.TokenID,
            FarmerName: this.name.value,
            MobileNo: this.mobile.value,
           /* EmailID: this.email.value,*/
            Password: this.password.value,
            confirmPassword: this.confirmPassword.value,
            Address: this.Address.value,
             Status: '1',
            LoginType: '4',
            Lattitude: 0,
            Longitude: 0
        })
        var UploadFile = new FormData();
        UploadFile.append("Param", JSON.stringify(this.arr));
         var url = "api/YKLabs/YK_NEW_Add_Farmer_Registration";
        this.generalService.PostData(url, UploadFile).then(data => {
            debugger
            if (data === "MOBILE EXIST") {
                this.generalService.ShowAlert("WARNING", "Your Mobile Number is Already Exist", "warning");
               // this.Login(this.mobile.value, this.password.value)
            }
            else if (data != "") {
                this.generalService.ShowAlert("SUCCESS", "Your Registration is Success Full With YKLabs", "Success");
              //  this.Login(this.mobile.value, this.password.value)
                this.router.navigate(['/']).then(() => {
                    window.location.reload();
                });
            }
           

        }, error => {
            this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error');

        });
    }
       
/*end region*/


}


export function emailValidator(control: FormControl): { [key: string]: any } {
    var emailRegexp = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/;
    if (control.value && !emailRegexp.test(control.value)) {
        return { invalidEmail: true };
    }
}

export function matchingPasswords(passwordKey: string, passwordConfirmationKey: string) {
    return (group: FormGroup) => {
        let password = group.controls[passwordKey];
        let passwordConfirmation = group.controls[passwordConfirmationKey];
        if (password.value !== passwordConfirmation.value) {
            return passwordConfirmation.setErrors({ mismatchedPasswords: true })
        }
    }
}
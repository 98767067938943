import { Component, OnInit } from '@angular/core';
import { GeneralService } from '../../Services/generalservice.service';
import { FormGroup, FormsModule } from '@angular/forms'
import { map, throttle, window } from 'rxjs/operators';
import { HttpClient, HttpHandler, HttpResponse, HttpRequest, HttpHeaders } from '@angular/common/http';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import Swal from 'sweetalert2';
import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-cropguidance',
  templateUrl: './cropguidance.component.html',
  styleUrls: ['./cropguidance.component.scss']
})
export class CropguidanceComponent implements OnInit {
    Crops123: any = [];
    arr: any = [];
    youtube: any;
    SolutionImage: any;
    Getallimages: any;
    CropID: any;
    Homeurl: any;
    Images: any;
    SolutionSchedul: any;
    InfoImg: any;
    ScheduleImg: any;
    SolutionImg: any;
    InfoData: any;
    SheduleData: any;
    SolutionData: any;
    YoutubeVideo: any;
    YoutubeLink: any;
    CropName: any;
    CropfullName: any;
    Productid: any;
    demo: any;
    QRimages: any;
    spinner: any;
    CropName123: any; CropNamebind: any;

    displayedYoutubeVideos: any[] = [];
    videosToShowInitially = 9;
    totalVideosToShow = this.videosToShowInitially;
    showLoadMoreButton = true;


    constructor(private http: HttpClient, public route: ActivatedRoute, public generalService: GeneralService,
        private domSanitizer: DomSanitizer,) {
        debugger
       
        this.Homeurl = 'https://yklaboratories.com/webservices/';
        this.CropName123 = this.route.snapshot.paramMap.get("CropName");
        this.CropID = this.route.snapshot.paramMap.get("CropguidelinesId");
    }

    ngOnInit(): void {

        this.route.params.subscribe(params => {
            this.CropID = localStorage.getItem("CropguidelinesId");
            this.CropNamebind = localStorage.getItem("CropName");
            this.CropName123 = params['CropName'];
            this.GetImages();
            this.GetSolutionSchedule();
            this.QRImages();
            this.GetCropGuidence();
            this.Getyoutube();
          

        });
        this.Productid = this.route.snapshot.paramMap.get('productId');
    }

    GetImages() {
        this.arr = [];
        this.arr.push({
            UserID: 1,
            TokenID: '1A7FE1ED-28F2-4E71-9321-D376ADB9D75E',
        });

        var UploadFile = new FormData();
        UploadFile.append('Param', JSON.stringify(this.arr));
        UploadFile.append('Flag', this.CropID);
        const url = "api/YKLabs/YK_NEW_Getting_YK_ImageGallery";
        this.generalService.PostData(url, UploadFile).then((data: any) => {
            console.log("GetImageGallery", data);
            this.Getallimages = data;
            this.Images = this.Getallimages[0].Images;     

        },
            (err) => {
               // alert("some thing went wrong")
            });
    }   

    GetSolutionSchedule() {
            var UploadFile = new FormData();
            UploadFile.append('FLagID', '1');
            UploadFile.append('CropID', this.CropID);
          const URL = 'api/YKLabs/YK_NEW_GettingSolution_Schedule_InfoImages';
            this.generalService.PostData(URL, UploadFile).then((data: any) => {
                console.log("Solution_And_Schedul", data);
                console.log("InfoImg", data.InfoList[0].InfoImg);
                console.log("ScheduleImg", data.ScheduleList[0].ScheduleImg);
                console.log("SolutionImg", data.SolutionList[0].SolutionImg);
                this.SolutionSchedul = [];
                this.SolutionSchedul = data;

                this.InfoData = data.InfoList;
                this.InfoImg = this.InfoData.InfoImg;

                this.SheduleData = data.ScheduleList;
                this.ScheduleImg = this.SheduleData.ScheduleImg;

                this.SolutionData = data.SolutionList;
                this.SolutionImg = this.SolutionData.SolutionImg;

              
            },
                (err) => {

                    // this.router.navigate(['/cropguidance']);
                    //alert(" Some thing went wrong")
                });
   
    }

    Getyoutube() {
        debugger
        var uploadfile = new FormData();
        uploadfile.append('Param1', this.CropID)
        var url = "api/YKLabs/Get_BasedONCrop_Youtubevideogallery";
        this.generalService.PostData(url, uploadfile).then((data: any) => {
            console.log("YouTube_INFO", data);
            this.YoutubeVideo = data;
            //  this.YoutubeLink = this.YoutubeVideo[0].YoutubeLink;
            for (var i = 0; i < this.YoutubeVideo.length; i++) {
                this.YoutubeVideo[i].YoutubeLink = this.YoutubeVideo[i].YoutubeLink;
                this.YoutubeVideo[i].YoutubeLink = this.GetYoutubeUrl(this.YoutubeVideo[i].YoutubeLink)

            }

        },
            (err) => {
                alert(" data is not getting")
            });

    }


    GetYoutubeUrl(url) {
        debugger
        return this.domSanitizer.bypassSecurityTrustResourceUrl(url);

    }

    //loadVideos() {
    //    this.displayedYoutubeVideos = this.YoutubeVideo.slice(0, this.totalVideosToShow);

    //    // Update YouTube links
    //    for (var i = 0; i < this.displayedYoutubeVideos.length; i++) {
    //        this.displayedYoutubeVideos[i].YoutubeLink = this.GetYoutubeUrl(this.displayedYoutubeVideos[i].YoutubeLink);
    //    }

    //    // Update load more button visibility
    //    this.showLoadMoreButton = this.YoutubeVideo.length > this.totalVideosToShow;
    //}

    //loadMoreVideos() {
    //    this.totalVideosToShow += this.videosToShowInitially;

    //    // Reset displayed videos array to prevent duplication
    //    this.displayedYoutubeVideos = [];
    //   // Load next set of videos
    //        this.loadVideos();
    //}

    //GetYoutubeUrl(url) {
    //    debugger
    //    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);

    //}

    GetCropGuidence() {
        //if (this.Crops123.length === 0) {
            this.arr = [];
            this.arr.push({
                UserID: 1,
                TokenID: '1A7FE1ED-28F2-4E71-9321-D376ADB9D75E',
            });
            var UploadFile = new FormData();
            UploadFile.append('Param', JSON.stringify(this.arr));
            UploadFile.append('Param1', this.Homeurl);
        const URL = 'api/YKLabs/YK_NEW_GetAll_Crop_GuidenceDetails';
            this.generalService.PostData(URL, UploadFile).then((data: any) => {
                this.Crops123 = [];
                this.Crops123 = data;
                this.CropName = this.Crops123.CropName;
            },
                (err) => {

                   // alert(" data is not getting")
                }
            );
        //}
    }

    QRImages() {
        debugger
        var uploadfile = new FormData();
        uploadfile.append('Param1', this.CropID)
        const url = "api/YKLabs/Get_QRImages";
        this.generalService.PostData(url, uploadfile).then((data: any) => {
            debugger
            console.log('QRImages', data);
            this.QRimages = data;
        },
            (err) => {
                //alert("some thing went wrong")
            });
    
     }




}

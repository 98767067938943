

<div class="main-banner item-bg-one">
    <div class="d-table">
        <div class="breadcrumb">
            <div class="container">
                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li><a href="/blog-details">Blog-details</a></li>
                </ul>
                <h2 style="  color: white">{{BlogName}}</h2>
            </div>

        </div>
    </div>
</div>

<br />
<div class="container cont ">
    <div>
        <div class="warpper">

            <div class="col-lg-12">
                <div class="row">
                    <div class="col-lg-2" *ngFor="let blog of Bolgdetails">

                        <div>

                            <!--<button class="bolgbtn" [ngClass]="{'selected': selectedBlog === blog.BlogTitle}">
                                {{blog.BlogTitle}}
                            </button>-->

                        </div>
                    </div>
                </div>
            </div>

            <div class="panels">
                <div class="panel">
                    <div class="panel-title"></div>
                    <div class="row d-flex justify-content-end">
                        <div class="col-lg-9">
                            <p [innerHTML]="Discription"></p>

                        </div>

                        <div class="col-lg-3">

                            <img src="../../../assets/img/NewImages/ceo.jpeg" style="border-radius:5px;" />
                            <h6 class="card-title  pr-1 pt-1" style="color: black; font-size: 14px;">L.Venkata Ramana Rayudu</h6>
                            <p class="card-text" style="font-size: 11px; color: black;">CEO & Founder of YK Laboratories</p>&nbsp;
                            <img [src]="Homeurl + Blogimage" alt="yklabs">
                            <!--<img [src]="Homeurl + Blogimage" alt="yklabs">-->

                        </div>
                    </div>

                </div>
            </div>

        </div>

    </div>

</div>



<style>

    @media (min-width: 1200px) {
        h2, .h2 {
            font-size: 20px;
        }
    }

    .bolgbtn {
        border-radius: 12px;
        background-color: white;
        color: #161515;
        width: auto;
        padding: 5px;
        border: 2px solid #c54895;
        margin: 0% 0% 6% 0%;
    }

    .selected {
        background-color: #c54895;
        color: white;
        /* Add any other styles you want for the highlighted state */
    }
</style>

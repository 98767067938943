 import { Component, OnInit } from '@angular/core';
import { GeneralService } from '../../../Services/generalservice.service';
//import { HttpClient, HttpHandler, HttpResponse, HttpRequest } from '@angular/common/http';
import { Router, Routes} from '@angular/router';
import { HttpClient, HttpHandler, HttpResponse, HttpRequest } from '@angular/common/http';
import { ViewportScroller } from '@angular/common';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
    searchprods: any;
    subCategoryItemsStyle: any;
    selectedProduct: any;
    searchedProducts: any;
    arr: any[];
    Products123: any;
    Crops123: any = [];;

    showDropdownContent = true;
    loginDet: any;
    SubProducts: any[] = [];
    subDropdownStyle: { [key: string]: string } = {};
    isMouseOverSubDropdown: boolean = false;
    categorizedProducts: any[];
    CurrentIndex: any;
    Products: any;
    SubCatProducts: any;
    DataToggle: boolean = false;
    Producttoggle: boolean = false;
    Croptoggle: boolean = false;
    Cropimages: any;
    Homeurl: any;

    currentCategoryId: any;
    SubCategoriesMap: Map<number, any[]> = new Map(); // Map to store subcategories for each category
    activeCategoryId: number | null = null;
    subCategoriesMap: Map<number, any[]> = new Map();
    isProductInfoVisible = false;
    activeProduct: any;
    product: any;
    spinner: any;
    urls: any;

    constructor(
        private viewportScroller: ViewportScroller,public Routes: Router,
        public generalService: GeneralService, public http: HttpClient, private router: Router) {
  
        this.loginDet = JSON.parse(localStorage.getItem("LoginDetails"));
      //  this.Homeurl = 'https://www.yklaboratories.com/webservices/';   
        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.Homeurl = data.Webservice;
        });

    }

    ngOnInit() {
        this.GetProducts(); 
       // this.GetCropGuidence();
    }
    logout() {
        localStorage.removeItem("LoginDetails");
        this.router.navigate(['/login']).then(() => { window.location.reload(); });
    }
    GetProducts() {
        debugger
        this.spinner = true;
        if (!this.Products123) {
            debugger
        this.arr = [];
        this.arr.push({
            UserID: 1,
            TokenID: '1A7FE1ED-28F2-4E71-9321-D376ADB9D75E',
        });
        var UploadFile = new FormData();
        UploadFile.append('Param', JSON.stringify(this.arr));
        UploadFile.append('Param1', '');
            const URL = 'api/YKLabs/YK_NEW_GetProductCategories';
        this.generalService.PostData(URL, UploadFile).then((data: any) => {
            this.Products123 = [];
            this.Products123 = data;

            this.spinner = false;
        },
            (err) => {
               // alert(" data is not getting")
            }
        );
        }
        this.spinner = false;
    }


    GetSubCategories(categoryId: number) {
        // Your existing code to fetch subcategories for the given categoryId
        this.SubCategoriesMap.set(categoryId, this.SubProducts); /* Fetch subcategories */
    }

    showSubCategories(categoryId: number) {
        this.activeCategoryId = categoryId;
        this.GetSubCategories(categoryId);
    }

    hideSubCategories() {
        this.activeCategoryId = null;
    }

    GetSubProducts(val: any) {
        debugger

        this.activeCategoryId = val;
        this.arr = [];
        this.arr.push({
            UserID: 1,
            TokenID: '1A7FE1ED-28F2-4E71-9321-D376ADB9D75E',
        });

        var UploadFile = new FormData();
        UploadFile.append('Param', JSON.stringify(this.arr));

        const subProductsURL = 'api/YKLabs/YK_NEW_GetAllCategoryProducts';
        this.generalService.PostData(subProductsURL, UploadFile).then(
            (data: any) => {
                this.SubProducts = data;
                this.showSubDropdown();
                this.SubCatProducts = this.SubProducts.filter((s: any) => s.CategoryId == val);

            },
            (err) => {
              //  alert(" Some thing went wrong")
            });

    }


    showSubDropdown() {
        this.subDropdownStyle = {
            display: 'block',
        };
    }

   

    hideSubDropdown() {
        if (!this.isMouseOverSubDropdown) {
            this.subDropdownStyle = {
                display: 'none',
            };
        }
    }


    GetCropGuidence() {
        this.spinner = true;
        if (this.Crops123.length === 0) {
        this.arr = [];
        this.arr.push({
            UserID: 1,
            TokenID: '1A7FE1ED-28F2-4E71-9321-D376ADB9D75E',
        });
        var UploadFile = new FormData();
            UploadFile.append('Param', JSON.stringify(this.arr));
            UploadFile.append('Param1', this.Homeurl);
            const URL = 'api/YKLabs/YK_NEW_GetAll_Crop_GuidenceDetails';
            this.generalService.PostData(URL, UploadFile).then((data: any) => {
          
            this.Crops123 = [];
            this.Crops123 = data;

        },
            (err) => {
          
              //  alert(" data is not getting")
            }
            );
        }
        this.spinner = false;
    }

    navigateToCropGuidance(CropguidelinesId, CropName) {
        debugger
        this.router.navigate(['/cropguidance',   CropguidelinesId,  CropName ]);
   
        localStorage.setItem("CropguidelinesId", CropguidelinesId);
        localStorage.setItem("CropName", CropName);
    }
    // navigateToCropGuidance(CropguidelinesId, CropName): void {
    //    debugger
    //    const url = `/cropguidance/${CropId: CropguidelinesId}/${CropName: CropName}`;
    //    this.router.navigate([url]);
    //}


    homeback() {
        this.router.navigate(['/']);
    }
    About(){
        this.router.navigate(['/about-us']);
    }
    RDNEWS() {
        this.router.navigate(['/RDnews']);
    }
    RDChilli() {
        this.router.navigate(['/Rdchilli']);
    }
    RDpapaya() {
        this.router.navigate(['/Rdpapaya']);
    }
    Awards() {
        this.router.navigate(['/awards']);
    }
    Gallary() {
        this.router.navigate(['/Gallery']);
    }
    Testmonials() {
        this.router.navigate(['/testimonials']);
    }
    contact() {
        this.router.navigate(['/ContactUs']);
    }

    Registration() {
        this.router.navigate(['/Registration']);
    }



    navigateToproduct(CategoryName: any, productname: any) {
        debugger
       
        localStorage.setItem("CategoryName", CategoryName);
        localStorage.setItem("productname", productname);
        this.router.navigate(['/Products/' + CategoryName , productname]);
        //this.router.navigate(['/product-details', { ProdID: Productid }]).then(() => { window.location.reload(); });
      //  this.router.navigate(['/product-details', { ProdID: Productid, ProductName: productname}]);
       
    }

    GetSearchurl() {
        debugger
        var uploadfile = new FormData;
        uploadfile.append('Param1', this.selectedProduct);
        const url = "api/YKLabs/Get_allproducts_public_url";
        this.generalService.PostData(url, uploadfile).then((data: any) => {
            this.urls = data;
        }, (err) => {
            console.log('error in getsearchurl', 'data');
        })
    }

    GotoDetails(Val: any) {
        debugger
        if (Val.producturl == "Products") {
            this.router.navigate(['/Products/' + Val.CategoryName, Val.ProductName]);
        }
        else if (Val.producturl == "Cropguidance") {         
            this.router.navigate(['/cropguidance', Val.CategoryName, Val.ProductName]).then(() => {
                window.location.reload();
            });
            localStorage.setItem("CropguidelinesId", Val.CategoryName);
            localStorage.setItem("CropName", Val.ProductName);

        }
        else if (Val.producturl == "Blog") {
            debugger
            this.router.navigate(['/Blog', Val.ProductName]).then(() => {window.location.reload();
            });
            localStorage.setItem("BlogTitle", Val.ProductName);
        }
    }


    dataToggle() {
        debugger
        this.DataToggle = !this.DataToggle
        this.Producttoggle = false
        this.Croptoggle = false;
        for (let j = 0; j < this.Crops123.length; j++) {
            this.Crops123[j].Showdetails = false
        }
    }

    CropToggle() {
        this.Croptoggle = !this.Croptoggle
    }

    updateSubDropdownPosition(event: MouseEvent) {
        // Adjust the sub-dropdown position based on mouse movement
        this.subDropdownStyle = {
            top: event.clientY + 'px',
            left: event.clientX + 'px',
            display: 'block',
        };
    }

    toggleDropdownContent() {
        this.showDropdownContent = !this.showDropdownContent;
    }



    onClick(elementId: string): void {
        this.viewportScroller.scrollToAnchor(elementId);
    }

   

    onSubDropdownMouseEnter() {
        this.isMouseOverSubDropdown = true;
    }

    onSubDropdownMouseLeave() {
        this.isMouseOverSubDropdown = false;
        this.hideSubDropdown();
    }
   

    navigatingToSearchedProduct(data) {
        debugger
        console.log(data);
        var prodName = ""; var prodarr = []
        prodarr.push(data)
        var str = data.ProductName.split(" ")//spliting the category name into string array where space occured
        for (let i = 0; i < str.length; i++) {
            prodName += "_" + str[i];
        }
        this.generalService.setProducts(prodarr)
        //this.Routes.navigate(['/product-details/', { ProdID: data.Productid }]).then(() => { window.location.reload(); });
        //-----------this.Routes.navigate(['/product-details/', { ProdID: data.Productid }]);
    }
 
    classApplied = false;
    toggleClass() {
        this.classApplied = !this.classApplied;
    }

    filterProducts(event) {
        this.spinner = true;
        let query = event.query;
        if (event.query.length > 2) {
            var UploadFile = new FormData()
            UploadFile.append("Param", event.query)
            var url = "api/YKLabs/YK_NEW_SearchProducts_cropdetails"
            this.generalService.PostData(url, UploadFile).then(data => {
                console.log("search_products123", data);
                //console.log("Crop", data);
                //console.log("Category", data);
                debugger
                this.searchprods = data

            }, err => {
                alert(" data is not getting")
            });
        }
        this.spinner = false;
    }




}








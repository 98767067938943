<footer class="footer-area">
    <div class="container">
        <div class="row">

            <div class="col-lg-4">
                <h3>Categories</h3>
                <div class="row">
                    <a (click)="gotocrop1(5)">➤ CHILLI SPL, చిల్లీ స్ప్ల్, चिली एसपीएल</a>
                    <a (click)="gotocrop1(3)">➤ CAPSICUM, కాప్సికం, शिमला मिर्च</a>
                    <a (click)="gotocrop1(2)">➤ PAPAYA SPL, పాపయ  స్ప్ల్, पपीता एसपीएल</a>
                    <a (click)="gotocrop1(4)">➤ Tomatoes, టమోటా, टमाटर</a>
                    <a  (click)="gotocrop1(6)">➤ Paddy, వరి, धान</a>
                </div>


            </div>


            <div class="col-lg-2">
                <h3>R & D</h3>
                <div class="row">
                    <a href="/RDnews">➤ R & D News</a>
                    <a href="/Rdchilli">➤ R & D Chilli</a>
                    <a href="/Rdpapaya">➤ R & D Papaya</a>
                    <a href="/blog-details">➤ Blog</a>
                    <a href="/vyorganicgranules">➤ VY Organic Granules</a>

                </div>
            </div>

            <div class="col-lg-4">
                <!--<div><a href="/" (click)="onClick('home')">Home</a></div>-->
                <h3 >Products</h3>
                <div class="row">


                    <label class="dropdown pointer">
                        <div>
                            ➤  Organic Manure
                        </div>

                        <input type="checkbox" class="dd-input" id="test">

                        <ul class="dd-menu" style="height: 38px;">
                            <li class="footmenu" (click)="gotoproducts(1)">IMMUNO®</li>
                            <li (click)="gotoproducts(2)">FOST-ROOTEX</li>
                            <!--<li (click)="gotoproducts(101)">HI-RICH</li>-->
 
                        </ul>
                    </label>

                    <label class="dropdown pointer">
                        <div>
                            ➤  Organic Fertilizer
                        </div>

                        <input type="checkbox" class="dd-input" id="test">

                        <ul class="dd-menu" style="height: 38px;">
                            <li class="footmenu" (click)="gotoproduc(4)">MAX SIZE</li>
                            <li (click)="gotoproduc(21)"> MAXCROP®</li>

                        </ul>
                    </label>

                    <label class="dropdown pointer">
                        <div>
                            ➤  Botanical Virucide
                        </div>

                        <input type="checkbox" class="dd-input" id="test">
                        <ul class="dd-menu" style="height: 38px;">
                            <li (click)="gotoproduc(3)">ENVIRO®</li>
                        </ul>
                    </label>

                    <label class="dropdown pointer">
                        <div>
                            ➤  YK Laboratories Special
                        </div>

                        <input type="checkbox" class="dd-input" id="test">
                        <ul class="dd-menu" style="height: 38px;">
                            <li class="footmenu" (click)="gotoproduc(3)"> PAPPAYA SPECIAL</li>
                            <li (click)="gotoproduc(3)"> CHILLI SPECIAL</li>
                            <!--<li><a href="/product-details" (click)="gotoproduc(3)"> </a>CAPSICUM SPECIAL</li>-->

                        </ul>
                    </label>

                    <label class="dropdown pointer">
                        <div>
                            <a href="https://ykmart.in/Home" target="_blank">
                                ➤ You Can Purchase Products in YK Mart
                            </a>
                        </div>
                    </label>

              </div>
            </div>

            <div class="col-lg-2">
                <a href="https://play.google.com/store/apps/details?id=com.gg.yklabs&hl=en"> 
                <img src="../../../../assets/img/NewImages/Google_Play (2).png" alt="YkLabs" class="googleplay" /></a>
              
                <a href="https://apps.apple.com/us/app/yk-laboratories/id1548465046">  
                <img src="../../../../assets/img/NewImages/ios.png" alt="YkLabs" class="ios" /></a>
               
            </div>
        </div>
      </div>
</footer>


<ngx-scrolltop></ngx-scrolltop>

import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { GeneralService } from '../Services/generalservice.service';
import { Router } from '@angular/router';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { ViewportScroller } from '@angular/common';
declare var $
import Swal from 'sweetalert2';


@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    MobileNo: any;
    password: any;
    passwordshow: any;
    OTP: any;
    Pwd: any;
    Confirmpassword: any;
    arr: any[];
    logindata: string;
    loginDet: any;

    mobile: any; 
    Remember: any;
    CheckBox: any;
    randomnumber: any;
    public form: FormGroup;
    Mobile: any;

    displayModal1: boolean = false; // Control modal visibility
    displayModal: boolean = false; // Control modal visibility
    displayModal2: boolean = false; // Control modal visibility
 
    togglePasswordVisibility() {
        this.passwordshow = !this.passwordshow;
    }

    constructor(private cdRef: ChangeDetectorRef,private router: Router, fb: FormBuilder, public generalService: GeneralService,
        private cookieService: CookieService, private viewportScroller: ViewportScroller,) {
        //   localStorage.removeItem("LoginDetails");
        debugger
        this.loginDet = JSON.parse(localStorage.getItem("LoginDetails"));

    }
    close() {
        debugger
        $('#myModal2').modal('hide');
    }
    hide() {
        $('#myModal4').modal('hide');
    }
    ngOnInit(): void {
       
    }
    onSubmit() {
        // Handle submit logic
        this.displayModal = false; // Close the modal
    }

    onClose() {
        this.displayModal = false; // Close the modal
    }
    /* login  start */
    yklabLogin(MobileNo, password) {
        debugger
        this.arr = []
        this.arr.push({
            UserID: 1,
            Token: '1A7FE1ED-28F2-4E71-9321-D376ADB9D75E',
            MobileNo: MobileNo,
            Password: password,
            LoginType:4,
        })
        var UploadFile = new FormData();
        UploadFile.append("Param", JSON.stringify(this.arr));
        var url = "api/YKLabs/YK_NEW_FarmerLogin";
        this.generalService.PostData(url, UploadFile).then(data => {
            debugger
          //  this.router.navigate(['/product-details'])
            if (data === 'Invalid User') {
               
                this.generalService.ShowAlert('WARNING', 'Please Enter Valid Credentials.', 'warning');
            }
            else {
               // this.viewportScroller.scrollToAnchor('home');
                this.logindata = JSON.stringify(data[0]);
                localStorage.setItem("LoginDetails", this.logindata);
                this.router.navigate(['/']).then(() => {
                window.location.reload(); // Perform a complete page reload
            });
            }
          //  this.router.navigate(['/'])
            //.then(() => {
            //    window.location.reload(); // Perform a complete page reload
            //});
        }, error => {
            this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error');
        });

    }

    /*end code*/
  

    /*forgot password*/
    ForgotPassword() {
        this.mobile = ''; // Reset the mobile number input field
        this.displayModal = true;
    }

    /*end code*/

    /*Check mobile there or not*/
    CheckMobile(MobileNo) {
        debugger;
        if (this.MobileNo == undefined || this.MobileNo == "" || this.MobileNo == null) {
            debugger;
            alert("Please Enter Mobile Number")
            // this.openToast();
        }
        else {
            var UploadFile = new FormData();
            UploadFile.append("Mobile", this.MobileNo);
            UploadFile.append("Flag", "1");
            var url = "api/YKLabs/YK_New_Check_Mobile_Email"
            debugger;
            this.generalService.PostData(url, UploadFile).then(data => {
                debugger;
                if (data == 'EXIST') {
                    this.OTP = ""
                    this.Mobile = this.MobileNo;
                    this.SendOtpForMobile();
                   
                    this.displayModal1 = true;
                }
                else {

                    alert("Please Enter Valid Mobile Number")
                }
            }, err => {
            });
        }

    }
    /*end code*/

    /* send otp for registration */
    SendOtpForMobile() {
        debugger
        this.randomnumber = Math.floor(1000 + Math.random() * 9000);
        var msg = "Your Customer OTP is:" + this.randomnumber + " LC ";
        var UploadFile = new FormData();
        UploadFile.append("Mobile", this.Mobile);
        UploadFile.append("SMS", msg);
        UploadFile.append("OTP", this.randomnumber);
        var url = 'api/YKLabs/SendOtpToMobile';
        this.generalService.PostData(url, UploadFile).then(data => {
            if (data == "SUCCESS") {

            }
            else {

            }
        });
    }
    /*end code*/

    /*verifyotp for forgotpassword*/
    VerifyOtp(otp) {
        debugger
        if (this.randomnumber = otp) {
            this.displayModal1 = false;
            this.displayModal2 = true;
        }
        else {
            alert("Invalid OTP");
        }
    }
    /*end region*/


    /*Change password*/
    ChangePassword(password, confirmpassword) {
        if (password == undefined || password == null || password == "" || confirmpassword == undefined || confirmpassword == null || confirmpassword == "") {
            alert("Please enter password");
        }
        else {
            if (password != confirmpassword) {
                alert("password and confirmpassword must be same");
            }
            else {
                var UploadFile = new FormData();
                UploadFile.append("Mobile", this.Mobile);
                UploadFile.append("Password", password);
                var url = "api/YKLabs/YK_NEW_Fotgot_Password"
                debugger;
                this.generalService.PostData(url, UploadFile).then(data => {
                    debugger;
                    if (data == 'SUCCESS') {
                        this.generalService.ShowAlert('SUCESS', 'Change Password Successfully.', 'success')
                        this.displayModal2 = false;
                    }
                }, err => {
                    this.displayModal2 = false;
                    this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
                });
            }
        }
    }
    /*endregion*/


    //Rememberb password
    RememberMe(ev) {
        debugger
        if (ev.target.checked == true) {
            this.Remember = true;
            this.cookieService.set('email', this.form.value.email);
            this.cookieService.set('password', this.form.value.password);
            this.cookieService.set('RememberMe', 'Checked');
            this.CheckBox = 'Checked'
        }
        else if (ev.target.checked == false) {
            // this.cookieService.set('RememberMe', 'UnChecked');
            this.CheckBox = ''
            this.Remember = false;
            this.cookieService.deleteAll();

        }
    }



}
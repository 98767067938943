import { Component, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { GeneralService } from '../../../Services/generalservice.service';
import { Router, Routes } from '@angular/router';
import { HttpClient } from '@angular/common/http';

declare var $:any;
import Swal from 'sweetalert2';
@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {
    EnquiryAry: any = [];
    formGroup: any;
    arr: any=[];
    SubProducts: any;
    Image: any;
    Homeurl: any;
    productname: any;
    loginDet: any;
    name: any;
    email: any;
    subject: any;
    number: any;
    message: any;
    router: any;

    constructor(private viewportScroller: ViewportScroller, public generalService: GeneralService,
        public route: Router, public http: HttpClient,) {
        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.Homeurl = data.Webservice;
        });
      //  this.Homeurl = 'https://www.yklaboratories.com/webservices/';
        this.loginDet = JSON.parse(localStorage.getItem("LoginDetails"));

    }
          
     
  public onClick(elementId: string): void { 
      this.viewportScroller.scrollToAnchor(elementId);
  }

  ngOnInit() {

      this.GetSubProducts();
     
    }

    Sendmail() {
        //dddd
        debugger
        $('#myModal2').modal('show');
    }

    GotoProducts(ProdID: any) {
        debugger
        this.route.navigate(['/product-details', { ProdID: ProdID }])
    }

    navigateToproduct(CategoryName: any, productname: any) {
        debugger

        localStorage.setItem("CategoryName", CategoryName);
        localStorage.setItem("productname", productname);
        this.route.navigate(['/Products/' + CategoryName, productname]);
        //this.router.navigate(['/product-details', { ProdID: Productid }]).then(() => { window.location.reload(); });
        //  this.router.navigate(['/product-details', { ProdID: Productid, ProductName: productname}]);

    }


    GetSubProducts() {
        debugger
        this.arr = [];
        this.arr.push({
            UserID: 1,
            TokenID: '1A7FE1ED-28F2-4E71-9321-D376ADB9D75E',
        });

        var UploadFile = new FormData();
        UploadFile.append('Param', JSON.stringify(this.arr));

        const subProductsURL = 'api/YKLabs/YK_NEW_GetAllCategoryProducts';
        this.generalService.PostData(subProductsURL, UploadFile).then((data: any) => {
                console.log("products", data);
                this.SubProducts = data;
            this.productname = this.SubProducts[0].productname
                this.Image = this.SubProducts[0].Image

            },
            (err) => {
                alert(" Some thing went wrong")
              //  $('#myModal2').modal('hide');
              
            }
        );
    }


    GotoMail(Product: any) {
        this.route.navigate(['/mailEnquiry', { ProdName: Product }]).then(() => { window.location.reload(); });
    }


  

}

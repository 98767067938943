import { Component, OnInit } from '@angular/core';
import { GeneralService } from '../../Services/generalservice.service';
import { HttpClient, HttpHandler, HttpResponse, HttpRequest } from '@angular/common/http';

@Component({
  selector: 'app-rdchilli',
  templateUrl: './rdchilli.component.html',
  styleUrls: ['./rdchilli.component.scss']
})
export class RdchilliComponent implements OnInit {
    Homeurl: any;
    RDChilli: any;
    Chilli: any;

    constructor(public generalservice: GeneralService, public http: HttpClient) {
       // this.Homeurl = 'https://yklaboratories.com/webservices/';
        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.Homeurl = data.Webservice;
        });
    }

    ngOnInit(): void {
        this.Get_RDChilli();
  }

    Get_RDChilli() {
        debugger
        var uploadfile = new FormData();
        uploadfile.append('Param1', '2');
        var url = "api/YKLabs/Get_RDImages_based_On_Rollid";
        this.generalservice.PostData(url, uploadfile).then((data: any) => {
            this.RDChilli = data;
            console.log("console", data);
         //   this.Chilli = this.RDChilli.ImagePaths;
        }, (err) => {
           
        })       

    }



}


<div *ngIf="spinner" class="loading-indicator">
    <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
</div>

<!--Web top nav bar start-->
<nav class="navbar navbar-expand-lg navbar-dark bg-dark purple_respone">
    <div class="container-fluid purple_respone" style="background-color: purple;">
        <div class="container">
            <div class="row logo_allmobile">
                <div class="col-md-4 col-xs-4 col-lg-4 col-sm-4">
                    <a class="navbar-brand" href="#" style="float: left;">
                        <i class="fas fa-phone"></i>
                        <span style="font-size: 15px;">&nbsp;(+91) 970 512 9639 / (+91) 949 141 8181</span>
                    </a>
                </div>
                <div class="col-md-1"></div>
                <div class="col-md-4 col-xs-4 col-lg-4 col-sm-4">
                    <a class="navbar-brand" href="mailto:info@yklaboratories.com" style=" float: right;">
                        <i class="fas fa-envelope"></i>
                        <span style="font-size: 15px;">&nbsp;info@yklaboratories.com</span>
                    </a>
                </div>
                <div class="col-md-3">
                    <div id="google_translate_element" style="float: right;"></div>
                </div>
            </div>
        </div>
    </div>
</nav>
<!--Web top nav bar End-->
<nav class="navbar navbar-expand-lg navbar-light bg-light mobile_brch">
    <div class="container-fluid">
        <div class="container">
            <div class="row logo_allmobile">
                <div class="col-md-12 col-lg-3">
                    <img src="../../../../assets/NewImages/logo.png" alt="YkLabs" style="    width: 50%;" class="flip_phn" />
                </div>
                <!--<div class="col-md-12 col-lg-2"></div>-->
                <div class="col-md-12 col-lg-8">
                    <div class="row">

                        <div class="col-6 col-md-4 col-lg-2 wid">
                            <img src="../../../../assets/img/NewImages/ioas_accredited.png" alt="YkLabs" class="wrapper-main" />
                        </div>
                        <div class="col-6 col-md-4 col-lg-2 wid">
                            <a href="https://yklabsadmin.breakingindiaapp.com/assets/PDF/Vaayuu-Yantra.pdf" target="_blank">
                                <img src="../../../../assets/img/NewImages/vayulgo.png" alt="Yk Labs" class="wrapper-main" />
                            </a>
                        </div>

                        <div class="col-6 col-md-4 col-lg-2 wid">
                            <a href="https://www.amazon.in/stores/page/27087748-9B7F-46C3-80E6-0E06E638FC8C" target="_blank">
                                <img src="../../../../assets/img/NewImages/amazon-logo.png" alt="YkLabs" class="wrapper-main" />
                                <p style="font-size: 8px; color: #e14795;">Buy on Amazon</p>
                            </a>
                        </div>
                        <div class="col-6 col-md-4 col-lg-2 wid">
                            <a href="./../../../assets/img/pdf/ChilliDisease&PestManagement.pdf" target="_blank">
                                <img src="../../../../assets/img/pdf/chilli1.png" alt="YkLabs" title="Chilli Disease & Pest Management" class="wrapper-main" />
                            </a>
                        </div>

                        <div class="col-6 col-md-4 col-lg-2 wid">
                            <a href="./../../../assets/PDF/YK Labarotaries(AOCA-NPOP)......pdf">
                                <img src="../../../../assets/apoff.PNG" alt="YkLabs" style="cursor:pointer;" class="wrapper-main aoca" />
                            </a>
                        </div>
                        <div class="col-6 col-md-4 col-lg-2 wid">
                            <a href="./../../../assets/img/pdf/Brochure%202023_1.pdf" target="_blank">
                                <img src="../../../../assets/img/NewImages/New/fb1.webp" alt="YkLabs" title="Brochure_2024-25" class="wrapper-main aoca" />
                            </a>
                        </div>
                        <div class="col-6 col-md-4 col-lg-2 wid">
                            <a href="./../../../assets/img/pdf/YK_Price_List_2024-25.pdf" target="_blank">
                                <!--<img src="../../../../assets/img/NewImages/New/fb1.webp" alt="YkLabs" title="Brochure" class="wrapper-main aoca" />-->
                                <img src="../../../../assets/img/NewImages/New/YK_Price.JPG" alt="YkLabs" title="YK_Price_2024-25" class="YKprice"/>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</nav>

<nav class="navbar navbar-expand-lg navbar-light bg-light " [class.active]="classApplied" ngStickyNav stickyClass="sticky-box-shadow">
    <div class="container-fluid contfluid">
        <a class="navbar-brand" href="/">
            <img src="../../../../assets/NewImages/logo.png" alt="Yk Labs" class="mobile_log" />

        </a>
        <button class="navbar-toggler" type="button" (click)="toggleClass()">
            <span class="burger-menu">
                <span class="top-bar"></span>
                <span class="middle-bar"></span>
                <span class="bottom-bar"></span>
            </span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mt-2 ml-auto ullist">

                <li class="nav-item">
                    <span class="nav-link">

                        <a (click)="homeback()" href="/home">Home</a>

                    </span>
                </li>

                <li class="nav-item">
                    <span class="nav-link">
                        <a (click)="About()" href="about-us">About</a>
                    </span>
                </li>

                <li>
                    <div class="dropdown" style="    margin-top: -9%;">
                        <button class="dropbtn" (mouseenter)="GetProducts()" style="color:black">Products &nbsp;<i class="fa fa-caret-down"></i></button>
                        <div class="dropdown-content">
                            <ng-container *ngFor="let link of Products123;">
                                <a (click)="GetSubProducts(link.CategoryId)">
                                    {{ link.CategoryName }}&nbsp;<i class="fa fa-caret-right"></i>
                                </a>
                                <div class="toggle subdrop_wrap"
                                     *ngIf="activeCategoryId == link.CategoryId"
                                     (mouseenter)="isMouseOverSubDropdown = true"
                                     (mouseleave)="isMouseOverSubDropdown = false;">

                                    <div class="card">
                                        <a *ngFor="let link1 of SubCatProducts"
                                           (click)="navigateToproduct(link.CategoryName,link1.productname)">
                                            <img [src]="Homeurl + link1.Image" alt="" style="width: 25px; height: 25px;" />
                                            {{ link1.productname }}
                                        </a>
                                    </div>

                                </div>
                            </ng-container>
                        </div>
                    </div>
                </li>


                <!--<li class="nav-item dropdown">
                    <div class="dropdown">
                        <a class="nav-link dropbtn" (mouseenter)="GetProducts()" href="placeholder-url">
                            Products &nbsp;<i class="fa fa-caret-down"></i>
                            <b class="caret" href="product-details"></b>
                        </a>

                        <div class="dropdown-content">
                            <ng-container *ngFor="let link of Products123;">
                                <a (mouseleave)="hideSubDropdown()"
                                   (mouseenter)="GetSubProducts(link.CategoryId)">
                                    {{ link.CategoryName }}
                                </a>
                                <div class="toggle subdrop_wrap"
                                     *ngIf="activeCategoryId == link.CategoryId"
                                     (mouseenter)="isMouseOverSubDropdown = true"
                                     (mouseleave)="isMouseOverSubDropdown = false;"
                                     (mousemove)="updateSubDropdownPosition($event)">

                                    <div class="card">
                                        <a *ngFor="let link1 of SubCatProducts"
                                           (click)="navigateToproduct(link.CategoryName,link1.productname)">
                                            <img [src]="Homeurl + link1.Image" alt="" style="width: 25px; height: 25px;" />
                                            {{ link1.productname }}
                                        </a>
                                    </div>

                                </div>
                            </ng-container>
                        </div>

                    </div>
                </li>-->

                <li class="nav-item dropdown">
                    <div class="dropdown">
                        <a class="nav-link dropbtn" (mouseenter)="GetCropGuidence()" href="cropguidance">
                            Crop Guidance&nbsp;<i class="fa fa-caret-down"></i>
                        </a>
                        <div class="dropdown-content" *ngIf="showDropdownContent">
                            <a *ngFor="let item of Crops123"
                               (click)="navigateToCropGuidance(item.Id,item.CropName)">
                                <span>{{ item.CropName }}</span>
                                <div class="sub-dropdown-content"
                                     [ngStyle]="subDropdownStyle"
                                     (mouseenter)="isMouseOverSubDropdown = true"
                                     (mouseleave)="isMouseOverSubDropdown = false; hideSubDropdown()"
                                     (mousemove)="updateSubDropdownPosition($event)" style="cursor:pointer">
                                </div>
                            </a>
                        </div>
                    </div>
                </li>


                <li class="nav-item dropdown">
                    <span class="nav-link dropbtn">
                        <a class="nav-item" href="">R & D&nbsp;<i class="fa fa-caret-down"></i></a>
                    </span><br />
                    <div class="dropdown-content" style="width: 250%;">
                        <span>
                            <a href="/RDnews">R & D NEWS</a>

                            <a href="/Rdchilli">R & D CHILLI</a>
                            <a href="/Rdpapaya">R & D PAPAYA</a>
                        </span>

                    </div>
                </li>

                <li class="nav-item">
                    <span class="nav-link"><a href="/awards" class="active">Awards</a></span>
                </li>
                <li class="nav-item">
                    <span class="nav-link"><a href="/Gallery" class="active">Gallery</a></span>
                </li>


                <li class="nav-item"><span class="nav-link"><a href="/testimonials">Testimonial</a></span></li>


                <li class="nav-item"><span class="nav-link"><a href="/ContactUs">Contact Us</a></span></li>
                <li>
                    <p-autoComplete class="sea" placeholder="Search your Product...!" [(ngModel)]="selectedProduct" [suggestions]="urls" (completeMethod)="GetSearchurl()" field="ProductName" [dropdown]="false" style="padding-left:8%">
                        <ng-template let-country pTemplate="item">
                            <div class="country-item" (click)="GotoDetails(country)">
                                <div>  &nbsp;&nbsp; <h6 class="searchdrop">{{country.ProductName}} &nbsp;<span style="color:black">|</span>&nbsp; {{country.producturl}}</h6><br /></div>
                            </div>
                        </ng-template>
                    </p-autoComplete>
                </li>&nbsp;

                <!--<li class="nav-item"><span class="nav-link"><a href="/login">login</a></span></li>-->

                <li class="nav-item dropdown" *ngIf="loginDet?.FarmerName">
                    <div class="nav-link dropbtn" style="font-size:12px;">

                        <div>
                            <a *ngIf="loginDet?.FarmerName">
                                <h2 class="tooltiptext" style="font-size: 15px;
                              margin-top: 3px;">
                                    {{ loginDet.FarmerName}}
                                </h2>
                                <span class="tooltiptext"></span>
                            </a>
                        </div>

                    </div>
                    <br />
                    <div class="dropdown-content" style="width: 200%;margin-top: -45px;margin-left: -41px;">
                        <a href="/" style="border-bottom:1px dashed purple"><i class="fa fa-cog" style="color:purple;padding-right: 18px;"></i>&nbsp;My Account</a>
                        <a href="/login" style="border-bottom:1px dashed purple"><i class="fa fa-arrow-right" style="color:purple;padding-right: 18px;"></i>&nbsp;My Wishlist</a>
                        <a (click)="Registration()" href="registrattion" style="border-bottom:1px dashed purple"><i class="fa fa-user" style="color:purple;padding-right: 18px;"></i>&nbsp;Register</a>
                        <a style="border-bottom:1px dashed purple" href="login" (click)="logout()"><i class="fa fa-user" style="color:purple;padding-right: 18px;"></i>Logout</a>

                    </div>
                </li>
            </ul>


            <!--Mobile Menu-->
            <ul class="navbar-nav ml-auto for-responsive">
                <li class="nav-item"><span class="nav-link"><a href="/" (click)="onClick('home')">Home</a></span></li>
                <li class="nav-item"><span class="nav-link"><a href="/about-us">About Us</a></span></li>


                <li class="dropdown">
                    <a class="nav-link dropbtn" (mouseenter)="GetProducts()" style="color:black">
                        Products &nbsp;<i class="fa fa-caret-down"></i><b class="caret" href="product-details"></b>
                    </a>

                    <div class="dropdown-content">
                        <ng-container *ngFor="let link of Products123;">
                            <a (mouseleave)="hideSubDropdown()"
                               (mouseenter)="GetSubProducts(link.CategoryId)" class="linux" style="cursor:pointer;  text-transform: lowercase;">
                                {{ link.CategoryName }}
                            </a>
                            <div class="toggle subdrop_wrap"
                                 *ngIf="activeCategoryId == link.CategoryId"
                                 (mouseenter)="isMouseOverSubDropdown = true"
                                 (mouseleave)="isMouseOverSubDropdown = false;"
                                 (mousemove)="updateSubDropdownPosition($event)">

                                <div class="card">
                                    <a *ngFor="let link1 of SubCatProducts" class="linux" style="cursor:pointer;
                     text-transform: lowercase;" (click)="navigateToproduct(link1.Productid,link1.productname)">
                                        <!--<img [src]="Homeurl + link1.Image" alt="Yk Labs Products" style="width: 25px; height: 25px;" />-->
                                        {{ link1.productname }}
                                    </a>
                                </div>

                            </div>
                        </ng-container>
                    </div>

                </li>

                <br />


                <li class="nav-item dropdown">
                    <div class="dropdown">
                        <span class="nav-link dropbtn" (mouseenter)="GetCropGuidence()">
                            Crop Guidance&nbsp;<i class="fa fa-caret-down"></i>
                        </span>
                        <div class="dropdown-content" *ngIf="showDropdownContent" style="width: 190%;padding:10px;">
                            <div *ngFor="let item of Crops123"
                                 (click)="navigateToCropGuidance(item.Id,item.CropName)" class="cropgu" style="padding:10px">
                                <span>{{ item.CropName }}</span>
                                <div class="sub-dropdown-content"
                                     [ngStyle]="subDropdownStyle"
                                     (mouseenter)="isMouseOverSubDropdown = true"
                                     (mouseleave)="isMouseOverSubDropdown = false; hideSubDropdown()"
                                     (mousemove)="updateSubDropdownPosition($event)">
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
                <br />
                <li class="nav-item dropdown">
                    <span class="nav-link dropbtn">
                        <span class="nav-item">R & D&nbsp;<i class="fa fa-caret-down"></i></span>
                    </span><br />
                    <div class="dropdown-content" style="width: 350%;">
                        <a href="/RDnews">R & D NEWS</a>
                        <a href="/Rdchilli">R & D CHILLI</a>
                        <a href="/Rdpapaya">R & D PAPAYA</a>

                    </div>
                </li>


                <li class="nav-item">
                    <span class="nav-link"><a href="/awards" class="active">Awards</a></span>
                </li>


                <li class="nav-item"><span class="nav-link"><a href="/Gallery">Gallery</a></span></li>
                <li class="nav-item"><span class="nav-link"><a href="/testimonials">Testimonial</a></span></li>

                <li class="nav-item"><span class="nav-link"><a href="/ContactUs">Contact Us</a></span></li>

                <!--<li class="nav-item">
                    <span class="nav-link">
                        <a href="/viewcart"><i class="fa fa-shopping-cart" style="color:purple;"></i>&nbsp;cart</a>
                    </span>
                </li>-->
                <li class="nav-item dropdown" *ngIf="loginDet?.FarmerName">
                    <div class="nav-link dropbtn" style="font-size:12px;">

                        <div>
                            <div *ngIf="loginDet?.FarmerName" class="nav-link circle-avatar">
                                <h2 class="tooltiptext" style="margin-top: -9px;background-color: purple;border-radius: 5%; width: auto; text-align: center; color: white;  font-size: 15px; padding: 10px;">
                                    {{ loginDet.FarmerName }}
                                </h2>
                                <span class="tooltiptext"></span>
                            </div>
                        </div>

                    </div>
                    <br />
                    <div class="dropdown-content" style="width: 126%;margin-top: -38px;  margin-left: -13px;">
                        <a href="/Registration" style="border-bottom:1px dashed purple"><i class="fa fa-user" style="color:purple;padding-right: 18px;"></i>&nbsp;Register</a>
                        <div style="border-bottom:1px dashed purple" (click)="logout()"><i class="fa fa-user" style="color:purple;padding-right: 18px;" (click)="logout()"></i>Logout</div>

                    </div>
                </li>
            </ul>
        </div>
    </div>


</nav>




<style>
    .dropdown {
        position: relative;
    }

    .dropdown-content {
        position: absolute;
        display: none;
        background-color: #f9f9f9;
        min-width: 200px; /* Adjust the width as needed */
        z-index: 1;
    }

    .subdrop_wrap {
        position: absolute;
        top: -100%; /* Adjust this value as needed */
        left: 0;
        display: none;
    }

    .dropdown:hover .subdrop_wrap {
        display: block;
    }

    .show-above {
        position: absolute;
        top: -100%; /* Adjust this value as needed */
        left: 0;
    }

    /* Style for the main navbar */
    .dropdown {
        position: relative;
        display: inline-block;
    }

    .dropbtn {
        background-color: white;
        color: black;
        padding: 10px;
        font-size: 16px;
        border: none;
        cursor: pointer;
    }

    .dropdown-content {
        display: none;
        position: absolute;
        background-color: #f9f9f9;
        min-width: 160px;
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
        z-index: 1;
    }

        .dropdown-content a {
            color: black;
            padding: 12px 16px;
            text-decoration: none;
            display: block;
        }

            .dropdown-content a:hover {
                background-color: purple;
                color: white;
            }

    .dropdown:hover .dropdown-content {
        display: block;
    }

    /* Style for the subnavbar */
    .subnavbar {
        display: none;
        position: absolute;
        background-color: #f9f9f9;
        min-width: 160px;
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
        z-index: 1;
    }

        .subnavbar a {
            color: black;
            padding: 12px 16px;
            text-decoration: none;
            display: block;
        }

            .subnavbar a:hover {
                background-color: #ddd;
            }

    .dropdown-content:hover .subnavbar {
        display: block;
    }

    /*-------------Loader styles-------------*/
    .loading-indicator {
        position: fixed;
        z-index: 999;
        height: 2em;
        width: 2em;
        overflow: show;
        margin: auto;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }

        .loading-indicator:before {
            content: '';
            display: block;
            position: fixed;
            top: 0;
            left: 0;
            width: 200%;
            height: 100%;
            background-color: rgba(0,0,0,0.53);
        }
    /*-------------Loader styles-------------*/
    .linux::first-letter {
        text-transform: capitalize;
    }
</style>



<div class="main-banner">
    <div class="d-table">
        <div class="breadcrumb">
            <div class="container">
                <ul>
                    <li><a href="/">Home</a></li>
                    <li><a href="/">Gallery</a></li>
                </ul>
                <h2 style="  color: white">Gallery</h2>
            </div>

        </div>
    </div>
</div>

<br />

<div class="container">

  
            <div class="row ">
                <div class="col-lg-3 col-xs-6" *ngFor="let img of Getallimages">

                    <a href="{{Homeurl+img.Images}}" target="_blank" class="img_g"> 
                    <img src="{{Homeurl+img.Images}}"alt="" class="img_g"/></a>
                </div>
            </div>
   </div>
<br />

    
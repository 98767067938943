

<div class="main-banner item-bg-one">
    <div class="d-table">
        <div class="breadcrumb">
            <div class="container">
                <ul>
                    <li><a href="/">Home</a></li>
                    <li><a href="/Rdnews">R & D NEWS</a></li>
                </ul>
                <h2 style="  color: white">RESEARCH & DEVELOPMENT</h2>
            </div>

        </div>
    </div>

</div>

<br />

<div class="container image-grid">
    <div class="row">
        <div class="col-lg-3 col-xs-6" *ngFor="let news of RandDNew">

        <a href="{{Homeurl+news.ImagePaths}}" target="_blank"><img src="{{Homeurl+news.ImagePaths}}" /></a> 

            </div>

    </div>

</div><br />
      <style>
          img {
              width: 100%;
              height: 250px;
              padding: 5px;
              margin-bottom: 6%;
              object-fit: cover;
          }
      </style>

<div class="main-banner item-bg-one">
    <div class="d-table">
        <div class="breadcrumb">
            <div class="container">
                <ul>
                    <li><a href="/">Home</a></li>
                    <li><a href="/Rdpapaya">R & D PAPAYA</a></li>
                </ul>
                <h2 style="  color: white">RESEARCH & DEVELOPMENT</h2>
            </div>

        </div>
    </div>

</div>

<br />



<div class="container ">

    <div class="row">

        <div class="col-lg-3 col-xs-6" *ngFor="let img of RandDpapaya">

        <a href="{{Homeurl+img.ImagePaths}}" target="_blank" class="fancylight popup-btn">
            <img src="{{Homeurl+img.ImagePaths}}" style="object-fit:cover"/>
        </a>
    </div>

    </div>

</div>



<br />

<style>
    img {
        width: 100%;
        height: 250px;
        padding: 5px;
        margin-bottom: 6%;
        object-fit: cover;
    }
</style>

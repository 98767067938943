import { Component, ElementRef, HostListener, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { GeneralService } from '../../Services/generalservice.service';
import { HttpClient, HttpHandler, HttpRequest } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-blog-details',
  templateUrl: './blog-details.component.html',
  styleUrls: ['./blog-details.component.scss']
})
export class BlogDetailsComponent implements OnInit {
    Bolgdetails: any;
    Discription: any;
    Homeurl: any;
    arr: any = [];
    Blogimage: any;
    blogs = [
        // Your blog data here or fetch it from a service
        // Each blog should have a `CreatedDate` property in a date-parsable format
    ]; BolgTitles: any;
  selectedBlog: number;
    selectedBlogTitle: string;
    
    filteredBlogs = [];
    filteredMonths: string[] = [];
    //years = [2010,2011,2012,2013,2014,2015,2016,2017,2018,2019,2020,2021,2022,2023,2024];
    years: number[] = [];
    yearsToShow = 7; // Number of years to initially display
    @ViewChild('yearSelect') yearSelect: ElementRef;
    months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    selectedYear: number;
    selectedMonth: string;
    currentYear: number = new Date().getFullYear();
    constructor(public generalservices: GeneralService, public http: HttpClient, private router: Router,
        private route: ActivatedRoute, public cdref: ChangeDetectorRef) {
        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.Homeurl = data.Webservice;

        })
        
    }
   
    ngOnInit() {
        const currentMonth = new Date().getMonth(); // Get current month index (0-11)
        this.selectedMonth = this.months[currentMonth];
        this.filterRecentBlogs();
       // this.populateYears();
        this.loadInitialYears();
        this.GetBlogsname();
        //this.route.params.subscribe(params => {
        //    const blogTitle = params['blogTitle'];
            // Use the blogTitle to fetch and display the corresponding blog details
       // });

    }
    filterRecentBlogs() {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth();

        // Calculate previous month
        const previousMonth = currentMonth === 0 ? 11 : currentMonth - 1;
        const previousYear = previousMonth === 11 ? currentYear - 1 : currentYear;

        // Filter blogs for current and previous month
        this.filteredBlogs = this.blogs.filter(blog => {
            const blogDate = new Date(blog.CreatedDate);
            const blogYear = blogDate.getFullYear();
            const blogMonth = blogDate.getMonth();
            return (
                (blogYear === currentYear && blogMonth === currentMonth) || // Current month
                (blogYear === previousYear && blogMonth === previousMonth) // Previous month
            );
        });

        // Filter months for the current year that have data
        this.filteredMonths = this.months.filter((month, index) => {
            return this.blogs.some(blog => {
                const blogDate = new Date(blog.CreatedDate);
                return blogDate.getFullYear() === currentYear && blogDate.getMonth() === index;
            });
        });

        // Set default selected year and month for dropdowns
        this.selectedYear = currentYear;
        this.selectedMonth = this.months[currentMonth];

        // Check if no records found for default months
        if (this.filteredBlogs.length === 0) {
           // this.generalservices.ShowAlert('Warning', 'No records found for the current and previous months.', 'warning');
        }
        this.cdref.detectChanges();
    }

    filterBlogsByDate() {
        if (this.selectedYear && this.selectedMonth) {
            const monthIndex = this.months.indexOf(this.selectedMonth);
            this.filteredBlogs = this.blogs.filter(blog => {
                const blogDate = new Date(blog.CreatedDate);
                return blogDate.getFullYear() === this.selectedYear && blogDate.getMonth() === monthIndex;
            });

            // Filter months for the selected year that have data
            this.filteredMonths = this.months.filter((month, index) => {
                return this.blogs.some(blog => {
                    const blogDate = new Date(blog.CreatedDate);
                    return blogDate.getFullYear() === this.selectedYear && blogDate.getMonth() === index;
                });
            });

            // Check if no records found for selected month and year
            if (this.filteredBlogs.length === 0) {
                this.generalservices.ShowAlert('Warning', `No records found for ${this.selectedMonth} ${this.selectedYear}.`, 'warning');
            }
        } else {
            // Reset filteredBlogs if no year or month is selected
            this.filteredBlogs = [];
            this.filteredMonths = [];
        }
        this.cdref.detectChanges();
    }

    //populateYears() {
    //    const currentYear = new Date().getFullYear();
    //    for (let year = currentYear; year >= 2000; year--) {
    //        this.years.push(year);
    //    }
    //}

    loadInitialYears() {
        for (let i = 0; i < this.yearsToShow; i++) {
            this.years.push(this.currentYear - i);
        }
    }

    loadMoreYears() {
        const lastYear = this.years[this.years.length - 1];
        for (let i = 1; i <= 5; i++) { // Example: Load 5 more years
            this.years.push(lastYear + i);
        }
    }

    @HostListener('scroll', ['$event.target'])
    onScroll() {
        const element = this.yearSelect.nativeElement;
        if (element.scrollTop + element.clientHeight >= element.scrollHeight) {
            this.loadMoreYears();
        }
    }

    onYearChange(year: number) {
        debugger
        this.selectedYear = year;
        this.filterMonthsByYear();
        this.filterBlogsByDate();
        if (this.filteredBlogs.length === 0) {
            this.generalservices.ShowAlert('Warning', 'No blogs found for this year.', 'warning');
        }
        this.updateView();
    }

    onMonthChange(month: string) {
        this.selectedMonth = month;
        this.filterBlogsByDate();
        if (this.filteredBlogs.length === 0) {
            this.generalservices.ShowAlert('Warning', 'No blogs found for this year.', 'warning');
        }
        this.updateView();
    }

    filterMonthsByYear() {
        const availableMonths = new Set(this.blogs
            .filter(blog => new Date(blog.CreatedDate).getFullYear() === this.selectedYear)
            .map(blog => this.months[new Date(blog.CreatedDate).getMonth()]));

        this.filteredMonths = this.months.filter(month => availableMonths.has(month));

        // If selectedMonth is not in filteredMonths, reset it to the first available month
        if (!this.filteredMonths.includes(this.selectedMonth)) {
            this.selectedMonth = this.filteredMonths.length > 0 ? this.filteredMonths[0] : '';
        }
    }

   


    GetBlog(BlogTitle: string, BlogImage: string) {
        debugger;
        localStorage.setItem("BlogTitle", BlogTitle);
        localStorage.setItem("Blogimage", BlogImage);
        this.router.navigate(['/Blog', BlogTitle]);
    }

    GetBlog1(val) {
        debugger
        var uploadfile = new FormData();
        uploadfile.append('Param1', val);
        var url = "api/YKLabs/Get_BlogsDeatilsbased_ID";
        this.generalservices.PostData(url, uploadfile).then((data: any) => {
            debugger
            this.Bolgdetails = data;
            this.Discription = this.Bolgdetails[0].Discription
            this.Blogimage = this.Bolgdetails[0].BlogImage
         //   this.Blogimage = this.Bolgdetails[0].BlogImage

            // Highlight HTML tags
            this.Discription = this.highlightHtmlTags(this.Discription);

            this.selectedBlog = val;

            },
            (err) => {
                console.error('Error fetching data from GetBlog:', err);
            });
    }

    highlightHtmlTags(htmlContent: string): string {
        // Define an array of HTML tags you want to highlight
        const tagsToHighlight = ['h6', 'p'];

        // Loop through each tag and add a class for styling
        tagsToHighlight.forEach(tag => {
            const regex = new RegExp(`<${tag}[^>]*>.*?<\\/${tag}>`, 'g');
            htmlContent = htmlContent.replace(regex, match => `<span class="${tag}-highlight">${match}</span>`);
        });

        return htmlContent;
    }


    GetBlogsname() {
        debugger;
        var arr = []; // Assuming this is used to pass parameters to API, adjust if needed
        this.arr.push({});

        var uploadfile = new FormData();
        uploadfile.append('Param', JSON.stringify(this.arr));
        uploadfile.append('Flag', '4');
        const url = "api/YKLabs/Blog_CRUD";

        this.generalservices.PostData(url, uploadfile).then((data: any) => {
            if (!data || data.length === 0) {
                // Handle case where no data is returned
                this.generalservices.ShowAlert('Warning', 'No blogs found for this month', 'warning');
            }
            this.blogs = data;
            this.filterRecentBlogs();
        }).catch(err => {
            console.error('Error fetching data from GetBlogsname:', err);
        });
    }

    filterBlogsByDefaultMonths() {
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth();
        const currentYear = currentDate.getFullYear();

        // Calculate previous month
        const previousMonth = currentMonth === 0 ? 11 : currentMonth - 1;
        const previousYear = previousMonth === 11 ? currentYear - 1 : currentYear;

        // Filter blogs for current and previous month by default
        this.filteredBlogs = this.blogs.filter(blog => {
            const blogDate = new Date(blog.CreatedDate);
            const blogMonth = blogDate.getMonth();
            const blogYear = blogDate.getFullYear();
            return (
                (blogMonth === currentMonth && blogYear === currentYear) ||
                (blogMonth === previousMonth && blogYear === previousYear)
            );
        });

        // Set default selected month and year
        this.selectedMonth = this.months[currentMonth];
        this.selectedYear = currentYear;

        // Check if no records found for default months
        if (this.filteredBlogs.length === 0) {
           // this.generalservices.ShowAlert('Warning', 'No records found for the current and previous months.', 'warning');
        }
        this.updateView();
    }

    filterBlogsBySelectedMonth() {
        const selectedMonthIndex = this.months.indexOf(this.selectedMonth);
        const selectedYear = this.selectedYear;

        this.filteredBlogs = this.blogs.filter(blog => {
            const blogDate = new Date(blog.CreatedDate);
            const blogMonth = blogDate.getMonth();
            const blogYear = blogDate.getFullYear();
            return blogMonth === selectedMonthIndex && blogYear === selectedYear;
        });

        // Check if no records found for selected month and year
        if (this.filteredBlogs.length === 0) {
            this.generalservices.ShowAlert('Warning', `No records found for ${this.selectedMonth} ${this.selectedYear}.`, 'warning');
        }
        this.updateView();
    }

    updateView() {
        // This method forces Angular to update the view
        this.cdref.detectChanges();
    }








}

<div class="main-banner item-bg-one">
    <div class="d-table">
        <div class="breadcrumb">
            <div class="container">
                <ul>
                    <li><a href="/">Home</a></li>
                    <li><a href="/login">Login</a></li>
                </ul>
                <h2 style="  color: white">Login</h2>
            </div>

        </div>
    </div>
</div>


<br />
<div class="container">
    <div class="container1">

        <div class="forms-container1">
            <div class="signin-signup">
                <form action="login.php" method="POST">
                    <h2 class="title fontt">Login Here!..</h2>
                    <div class="input-field">
                        <i class="fas fa-user"></i>
                        <input type="text" [(ngModel)]="MobileNo" id="MobileNo" name="MobileNo" placeholder="Mobile" required>
                    </div>
                    <div class="input-field">
                        <i class="fas fa-lock"></i>
                        <input type="{{ passwordshow ? 'text' : 'password' }}" name="contrase�a" autocomplete="current-password" id="id_password" placeholder="Password" [(ngModel)]="password">
                        <span class="toggle-password" (click)="togglePasswordVisibility()">
                            <i class="fa" [ngClass]="{ 'fa-eye': passwordshow, 'fa-eye-slash': !passwordshow }"></i>
                        </span>
                    </div>
                    <div>
                        <span (click)="ForgotPassword()" class="transition pull-right" style="cursor:pointer;">Forgot password?</span>
                        <input type="submit" (click)="yklabLogin(MobileNo,password)" class="btn solid">
                    </div>
                </form>
            </div>
        </div>
        <div class="panels-container">
            <div class="panel left-panel">
                <div class="content">
                    <h3 class="fontt">You don't have an account?</h3>
                    <p style="color:white;">Create your account right now to follow people and like publications</p>
                    <button class="btn transparent" id="sign-up-btn" routerLink="/Registration">Register</button>
                </div>
            </div>

            <div class="panel right-panel">
                <div class="content">
                    <h3>Already have an account?</h3>
                    <p>Login to see your notifications and post your favorite photos</p>
                    <button class="btn transparent" id="sign-in-btn">Sign in</button>
                </div>
                <img src="../../../../assets/img/New Images/logo_1.png" class="image" alt="">
            </div>
        </div>
    </div>

  
        <p-dialog [(visible)]="displayModal" [modal]="true">
            <div class="card" style="padding: 10px;">
                <div class="p-fluid">
                    <div>
                        <label class="modal-title" id="modal-primary-label">
                            Enter Your Mobile Number<b style="color:red">*</b>
                        </label>
                    </div><br />
                    <div class="modal-header state modal-primary">
                        <input [(ngModel)]="mobile" class="form-control Caps" placeholder="Enter Mobile" type="text">

                    </div>
                </div><br />
                <div class="p-mt-3">
                    <button type="Submit" label="Submit" (click)="CheckMobile(MobileNo)" class="btn solid"> Submit</button>&nbsp;
                    <button pButton type="button" label="Close" (click)="onClose()" class="btn solid" style="background-color:red">Cancel</button>
                </div>
            </div>
        </p-dialog>
   
    <!-- #endregion -->
    <!-- #region check otp popup for forgot password-->



    <p-dialog [(visible)]="displayModal1" [modal]="true"style= "overflow-y: clip;">
        <div class="card" style="padding:10px;    width: 98%;">
            <div class="p-fluid">
                <div class="modal-body">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <label>OTP<span style="color:red">*</span></label>
                        <input [(ngModel)]="OTP" class="form-control Caps" placeholder="Enter Otp" type="text">
                    </div>
                    <!--<div class="row" style="float:right;cursor:pointer;margin-top:10px">
                        <a class="resend" (click)="SendOtpForMobile()">Resend OTP</a>
                    </div>-->
                </div>
            <br />
                <div class="modal-footer">
                    <div class="row">
                        <div class="col-lg-6 col-xs-6">
                            <span class="resend" (click)="SendOtpForMobile()">Resend OTP</span>

                            <!--<button pButton type="button" label="Close" (click)="onClose()">Cancel</button>-->
                        </div>
                        <div class="col-lg-6 col-xs-6">
                            <button type="submit" class="btn " (click)="VerifyOtp(OTP)">Submit</button>
                        </div>

                    </div>
                </div>
            </div><br />

        </div>
    </p-dialog>
    <!-- #endregion -->
    <!-- #region update customer and vendor password-->
  


    <p-dialog [(visible)]="displayModal2" [modal]="true">
        <div class="card" style=" padding:10px;">
            <div class="p-fluid">
                <div class="modal-content">
                    <div class="modal-header state modal-primary">
                        <h4 style="text-align:left;" class="modal-title" id="modal-primary-label">Change Password</h4>
                        <!--<button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">X</span></button>-->
                    </div>
                    <div class="modal-body">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <label>Password<span style="color:red">*</span></label>
                            <input [(ngModel)]="Pwd" class="form-control Caps" placeholder="Enter password" type="text">
                            <br>
                            <label>Confirm Password<span style="color:red">*</span></label>
                            <input [(ngModel)]="Confirmpassword" class="form-control Caps" placeholder="Enter confirm password" type="text">
                        </div>
                    </div>
                    <br />
                    <div class="modal-footer">
                        <div class="row">
                            <div class="col-lg-6 col-xs-6">
                                <button type="submit" class="btn " (click)="ChangePassword(Pwd,Confirmpassword)">Submit</button>
                            </div>
                            <div class="col-lg-6 col-xs-6">
                                <button id="closemodal" type="button" class="btn " data-dismiss="modal">Close</button>
                            </div>
                        </div>

                    </div>
                </div>
               
            </div><br />

        </div>
    </p-dialog>
</div>

<style>
    .card border-0 box-shadow rounded-0 {
        width: 600px;
        padding: 100px;
    }

    .modal-dialog {
        max-width: 31% !important;
        margin: 1.75rem auto;
    }
    .p-dialog .p-dialog-header-icon {
        align-items: center;
        display: flex;
        justify-content: center;
        overflow: hidden;
        position: relative;
        color: white;
    }
</style>



















import { Injectable } from '@angular/core';
import { Router, Resolve,Route,
    RouterStateSnapshot,ActivatedRouteSnapshot, ActivatedRoute} from '@angular/router';
import { Observable, of } from 'rxjs';
import { GeneralService } from './generalservice.service';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class VidelResolverResolver implements Resolve<any> {
    CropID: any;
    constructor(private generalService: GeneralService, private http: HttpClient,
        private route: ActivatedRoute, ) {
        this.CropID = this.route.snapshot.paramMap.get("CropId");
    }
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) : any {

        var res = this.http.get(`https://yklaboratories.com/webservices/api/YKLabs/YK_NEW_GetAllVideoGalleryYoutube`);
        console.log(res);
        return res;
       // https://yklaboratories.com/webservices/api/YKLabs/YK_NEW_GetAllVideoGalleryYoutube


    }
}


<div class="home-slides">
    <owl-carousel-o [options]="homeSlides">

        <ng-template carouselSlide>
            <div class="main-banner" (click)="banners(2)">
                <div style="cursor:pointer">
                    <img src="../../../assets/img/Banners/New/owner2.jpeg" class="banner_images" alt="yklabs" />
                    <div class="center1">

                    </div>
                </div>

            </div>
        </ng-template>

        <ng-template carouselSlide>
            <div class="main-banner">
                <div style="cursor:pointer">
                    <img src="../../../assets/img/Banners/Mirchi25-11-2024.jpeg" class="banner_images" alt="yklabs" />
                    <div class="center1">

                    </div>
                </div>

            </div>
        </ng-template>

        <ng-template carouselSlide>
            <div class="main-banner " (click)="banners(5)">
                <div style="cursor:pointer">
                    <img src="../../../assets/img/Banners/New/vayu-_3_.webp" class="banner_images" alt="yklabs" />
                    <div class="center1">
                        <h1 class="witha">Our chilli plants are grown in a healthy, pesticide-free environment, so you can enjoy their full flavour and aroma.</h1>

                    </div>

                </div>
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="container">
                            <div class="main-banner-text">

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
        <!--<ng-template carouselSlide>

        <div class="main-banner position-relative">
            <img src="../../../assets/img/Banners/s_slider_3.jpg" class="img-fluid" alt="yklabs" />
            <div class="center1b" id="video-button">
                <a href="">
                    <i class="fas fa-play-circle fa-2x  videoo1"></i><span class="videoo1 " style="font-size:26px;">&nbsp;Watch Video</span>
                </a>
            </div>
        </div>

    </ng-template>-->


        <ng-template carouselSlide>
            <div class="main-banner " (click)="banners(26)">
                <div style="cursor:pointer">

                    <img src="../../../assets/img/Banners/s_slider_3.jpg" class="img-fluid" alt="yklabs" />

                    <!--<img src="../../../assets/img/Banners/02_veggieday_banner_1300x.webp" />
                <div class="center1_p">
                    <div class="witha_p">
                        <a href="">
                            <i class="fas fa-play-circle fa-2x"style="color:white"></i><span  style="color:white">&nbsp;Watch Video</span>
                        </a>
                    </div>
                </div>-->
                </div>

            </div>
        </ng-template>

        <ng-template carouselSlide>
            <div class="main-banner ">
                <div>
                    <img src="../../../assets/img/Banners/New/facebook.webp" class="banner_images" alt="yklabs" />
                </div>

            </div>
        </ng-template>

        <ng-template carouselSlide>
            <div class="main-banner" (click)="banners(2)">
                <div style="cursor:pointer">
                    <img src="../../../assets/img/Banners/New/papaya(1).jpeg" class="banner_images" alt="yklabs" />
                    <div class="center1">

                    </div>
                </div>

            </div>
        </ng-template>
        <!--<ng-template carouselSlide>
        <div class="main-banner ">
            <div>

                <img src="../../../assets/img/Banners/bg.webp" class="banner_images" alt="yklabs" />
                <div class="center1">

                    <h1>"Enviro" protects tomatoes from Spotted Wilt Virus, while "Immuno" strengthens tomato immunity for better harvests, explore our website for more tomato farming solutions.</h1>
                </div>
            </div>
        </div>
    </ng-template>-->
        <ng-template carouselSlide>
            <div class="main-banner " (click)="banners(26)">
                <div style="cursor:pointer">

                    <img src="../../../assets/img/Banners/cotton.webp" class="banner_images" alt="yklabs" />
                    <div class="center1">
                        <h1 class="withc fontt"> "OXYVEG cotton special" Effectivel in : Thrips, Mealy bug, Whitefly, Aphids, Jassids, Root Weevils, Plant hoppers, White Gurbs, Larvae and other sucking pest, explore our website for more "Cotton" farming solutions.</h1>
                    </div>
                </div>

            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="main-banner ">
                <div>

                    <img src="../../../assets/img/Banners/1.jpeg" class="banner_images" alt="yklabs" />
                    <div class="center1">
                        <div>
                            <p class="witha11">
                                We promise our organic Pesticides keep your crops safe from pests, fungus, and harmful bacteria.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="main-banner " (click)="banners(4)">
                <div style="cursor:pointer">
                    <img src="../../../assets/img/Banners/New/crop-protection-banner.webp" class="banner_images" alt="yklabs" />
                    <div class="center1">
                        <h6 class="witha"> Fost Rootex is a natural fertilizer that helps your tomatoes grow bigger, better, and more flavourful.</h6>
                    </div>
                </div>

            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="main-banner " (click)="banners(2)">
                <div style="cursor:pointer">
                    <img src="../../../assets/img/Banners/cap.jpg" class="banner_images" alt="yklabs" />
                    <div class="center1">
                        <h1 class="withb mb-5">"HI-RICH organic manure" Best Supplements and Alternative for N-P-K chemical Fertilizers, and wile "AROGYA Flower & Fruit Setting" arogya enables the plant will keep always very Greenish and fresh condition, explore our website for more "Capsicum" farming solutions.</h1>
                    </div>
                </div>

            </div>
        </ng-template>
        <ng-template carouselSlide>

            <div class="main-banner ">
                <div>

                    <img src="../../../assets/img/bannnn.PNG" class="banner_images" alt="yklabs" />
                    <div class="center1">
                        <h1 class="witha"> </h1>
                    </div>
                </div>

            </div>
        </ng-template>

        <ng-template carouselSlide>
            <div class="main-banner ">
                <div>
                    <img src="../../../assets/img/Banners/New/all.webp" class="banner_images" alt="yklabs" />
                    <div class="center1">
                        <h1 class="witha">YK Laboratories Certified Organic Inputs for Healthy, Sustainable Crops</h1>
                    </div>
                </div>
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="container">
                            <div class="main-banner-text">

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>

    </owl-carousel-o>
</div>
<!--<div class="container mt-5">
    <div class="row news_2">

        <div class="col-lg-12 news">
            <div class="d-flex justify-content-between align-items-center breaking-news bg-white">
                <div class="row news_1">

                    <b> News</b>
                </div>
                <marquee class="news-scroll marq" behavior="scroll" direction="left" onmouseover="this.stop();"
                         onmouseout="this.start();">


                    <span *ngFor="let item of dataResult; let i=index">
                        <span class="dot_1"></span> <a routerLink="/NewsAndEvents">{{item.NewsTitle}} </a>&nbsp;

                    </span>

                </marquee>
            </div>
        </div>
    </div>
</div>-->

<app-about></app-about>

<app-strategy></app-strategy>

<app-welcome></app-welcome>

<app-who-we-are></app-who-we-are>

<!--<app-cta></app-cta>

<app-skill></app-skill>

<app-funfacts></app-funfacts>-->

<app-how-we-work></app-how-we-work>

<app-services-two></app-services-two>

<app-team></app-team>

<!--<app-why-we-different></app-why-we-different>

<app-work></app-work>

<app-pricing></app-pricing>

<app-faq></app-faq>

<app-feedback></app-feedback>

<app-blog></app-blog>

<app-partner></app-partner>

<app-subscribe></app-subscribe>

<app-contact></app-contact>-->













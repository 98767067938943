<div class="main-banner item-bg-one">
    <div class="d-table">
        <div class="breadcrumb">
            <div class="container">
                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li><a href="/blog-details">Blog-details</a></li>
                </ul>
                <h2 style="  color: white">Blog</h2>
            </div>

        </div>
    </div>
</div>

<br />
<div class="container cont ">
    <div>
        <div class="warpper">

            <div class="row">
                <div class="col-lg-8">
                    <div class="blog-buttons">
                        <div *ngFor="let blog of filteredBlogs" class="blog-entry">
                            <div class="blog-content">
                                <img [src]="Homeurl + blog.BlogImage" alt="yklabs" class="blog-image">
                                <button class="blog-btn" (click)="GetBlog(blog.BlogTitle, blog.BlogImage)" [ngClass]="{'selected': selectedBlog === blog.BlogTitle}">
                                    {{blog.BlogTitle}}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4">
                    <img src="../../../assets/img/NewImages/ceo.jpeg" style="border-radius:5px;" />
                    <h6 class="card-title pr-1 pt-1" style="color: black; font-size: 14px;">L.Venkata Ramana Rayudu</h6>
                    <p class="card-text" style="font-size: 11px; color: black;">CEO & Founder of YK Laboratories</p>
                    <div style="overflow-x: auto; white-space: nowrap; max-height: 150px;">
                        <label>Select Year:</label>
                        <div class="chip-container">
                            <span *ngFor="let year of years"
                                  class="chip"
                                  [class.selected]="year === selectedYear"
                                  (click)="onYearChange(year)">
                                {{ year }}
                            </span>
                        </div>
                    </div>

                    <br />

                    <div style="overflow-x: auto; white-space: nowrap;">
                        <label>Select Month:</label>
                        <div class="chip-container">
                            <span *ngFor="let month of filteredMonths"
                                  class="chip"
                                  [class.selected]="month === selectedMonth"
                                  (click)="onMonthChange(month)">
                                {{ month }}
                            </span>
                        </div>
                    </div>


                    <br />
                </div>
            </div>


            <div class="panels">
                <div class="panel">
                    <div class="panel-title"></div>
                    <div class="row d-flex justify-content-end">
                        <div class="col-lg-9">
                            <p [innerHTML]="Discription"></p>

                        </div>

                        <div class="col-lg-3"></div>
                    </div>

                </div>
            </div>

        </div>

    </div>

</div>




<style>
    .ripple-effect {
        position: absolute;
        border-radius: 50%;
        transform: scale(0);
        background: rgba(255, 255, 255, 0.6);
        animation: ripple 0.6s linear;
    }

    @keyframes ripple {
        to {
            transform: scale(4);
            opacity: 0;
        }
    }

    .blog-buttons {
        display: flex;
        flex-wrap: wrap;
    }

    .blog-button {
        flex-grow: 1;
        margin-right: 10px; /* Optional: Add some spacing between buttons */
    }

    .blog-btn {
        width: 100%;
        border: 2px solid purple;
        color: black;
        background-color: white;
        padding: 10px;
        margin: 1% 0% 3% 0%;
        border-radius: 5px;
        /* Add other button styling as needed */
    }

    @media (min-width: 1200px) {
        h2, .h2 {
            font-size: 20px;
        }

        /* Add this to your styles.css or styles.scss file */

        .bolgbtn {
            display: block;
            width: 107%; /* Adjust the width as needed */
            margin-bottom: 10px; /* Adjust the margin as needed */
            border: 1px solid purple;
            padding: 10px;
            border-radius: 5px;
            background-color: white;
        }

        .col-lg-2 {
            padding: 10px;
            /* Optionally, you can add styles for the column */
            /* For example, you can set a maximum width for the column */
            max-width: 27.66667%; /* 100% / 6 columns = 16.66667% for each column */
        }


        .selected {
            background-color: #c54895;
            color: white;
            /* Add any other styles you want for the highlighted state */
        }
</style>

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-webpspportcheck',
  templateUrl: './webpspportcheck.component.html',
  styleUrls: ['./webpspportcheck.component.scss']
})
export class WebpspportcheckComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

  }

}
